import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Stack } from "@mui/system";
import { Box } from "@mui/material";

import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import { decryptPayload } from "../helpers/utils";
import logo_blk from "../../assets/logo/ep_new.svg";
import forbes from "../../assets/leadpage/forbes.WebP";
import msnbc_logo from "../../assets/leadpage/msnbc.png";
import usa_today from "../../assets/leadpage/usa_today.WebP";

import "./TitleProtectionForRealEstateInvestors.scss";

const CommonFirstSection = ({ topHeader, topPara, bgImg, bgImg_Mobile }) => {
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <section className="first-section">
      <div className="">
        <Container fluid className="content___speacing position-relative">
          <Row className="align-items-center section-height" spacing={3}>
            <Col xs={12} sm={12} md={12} lg={6} xl={5}>
              <Row className="mt-5 justify-content-center justify-content-lg-start text-start">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  xl={12}
                  className="top-content-spacing"
                >
                  <Link
                    to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
                    className="TTP_EP_logo"
                  >
                    <LazyLoadImage
                      src={logo_blk}
                      width="176px"
                      height="48px"
                      alt="logo"
                    />
                  </Link>
                  <h1 className="top_heading">{topHeader}</h1>
                  <p className="top_para mt-2">{topPara}</p>
                  <Stack
                    display={"flex"}
                    justifyContent={{
                      sm: "center",
                      md: "center",
                      lg: "left",
                    }}
                    marginTop={"30px"}
                    alignItems={"center"}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                  >
                    <Link
                      className="text-white text-decoration-none"
                      to="/create-account"
                    >
                      <button className="ttp_buttons signup_btn">
                        SIGNUP NOW
                      </button>
                    </Link>
                    <Link
                      className="text-white text-decoration-none"
                      to="/property-risk-management"
                    >
                      <button className=" ttp_buttons victim_button">
                        FREE RISK ASSESSMENT
                      </button>
                    </Link>
                  </Stack>
                </Col>
              </Row>

              <div
                className={`custom-mt-120 d-flex justify-content-evenly align-content-center pb-3 d-none d-lg-flex`}
              >
                <Box className="homepage_logos_v2 d-flex d-none d-md-flex">
                  <LazyLoadImage src={nbc} alt="nbc" className="px-2" />
                  <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
                  <LazyLoadImage src={fox} alt="fox" className="px-2" />
                  <LazyLoadImage src={cbs} alt="cbs" className="px-2" />
                  <LazyLoadImage
                    src={msnbc_logo}
                    alt="msnbc_logo"
                    className="px-2"
                  />
                  <LazyLoadImage
                    src={usa_today}
                    alt="usa_today"
                    className="px-2"
                  />
                  <LazyLoadImage src={cnn} alt="cnn" className="px-2" />
                </Box>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="text-center">
              <div className="ttp_bg_img">
                <img loading="lazy" fetchpriority="high" src={bgImg} alt={""} />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="ttp_bg_img-mobile">
          <img
            loading="lazy"
            fetchpriority="high"
            src={bgImg_Mobile}
            alt={""}
          />
        </div>
        <div className="mt-5 d-flex justify-content-center pb-3 d-xs-flex d-lg-none outer-div">
          <Box className="homepage_logos_v2 d-flex">
            <LazyLoadImage src={nbc} alt="nbc" className="px-2" />
            <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
            <LazyLoadImage src={fox} alt="fox" className="px-2" />
            <LazyLoadImage src={cbs} alt="cbs" className="px-2" />
            <LazyLoadImage src={msnbc_logo} alt="msnbc_logo" className="px-2" />
            <LazyLoadImage src={usa_today} alt="usa_today" className="px-2" />
            <LazyLoadImage src={cnn} alt="cnn" className="px-2" />
          </Box>
        </div>
      </div>
    </section>
  );
};

export default CommonFirstSection;
