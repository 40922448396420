import React, { useEffect, useRef, useState } from "react";

import { Box, Dialog, DialogTitle, Typography } from "@mui/material";

import assesment_files from "../../assets/icons/assesment_files.webp";

import "./Style.scss";

const messages = [
  "Scanning Public Records",
  "Calculating Loan to Value",
  "Scanning the Dark Web",
  "Scanning Occupancy Type",
  "Verifying property against known address",
  "Calculating the Risk Score",
];

const RiskAssesmentLoader = ({ open }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const messageIntervalRef = useRef(null);
  const dotBlinkIntervalRef = useRef(null);

  useEffect(() => {
    // Set interval to update the message every 5 seconds
    setMessageIndex(0);

    messageIntervalRef.current = setInterval(() => {
      setMessageIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex < messages.length ? nextIndex : prevIndex;
      });
    }, 4000);

    // Cleanup message interval on unmount or when modal is closed
    return () => clearInterval(messageIntervalRef.current);
  }, [open]);

  // Blinking dot effect - toggle visibility every second
  useEffect(() => {
    // Dot blinking interval (every 1 second)
    dotBlinkIntervalRef.current = setInterval(() => {
      // This interval does not update state but uses CSS for blinking
    }, 1000);

    // Cleanup blinking interval on unmount or when modal is closed
    return () => clearInterval(dotBlinkIntervalRef.current);
  }, [open]);

  return (
    <>
      <Dialog
        maxWidth={"sm"}
        sx={{ zIndex: "13000001", textAlign: "center" }}
        fullWidth={true}
        open={open}
        onClose={false}
      >
        <DialogTitle id="alert-dialog-title" className="my-2">
          <Box className="loader_modal">
            <Box className="loader_conatiner">
              <Typography align="center" variant="h3" className="header_texts">
                We’re getting your results
              </Typography>
              <Typography className="para_texts" align="center" variant="body1">
                We’re scanning multiple data points, and it might take a couple
                of minutes
              </Typography>
              <div className="circular-motion-container">
                <div className="static-center">
                  <img src={assesment_files} alt="Static Icon" />
                </div>
                <div className="rotating-circle"></div>
                <div class="outer-circle-1"></div>
                <div class="outer-circle-2"></div>
                <div class="outer-circle-3"></div>
              </div>
              <Typography className="footer_texts" align="center" variant="h3">
                {messages[messageIndex]}.<span className="blinking-dot">.</span>
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
      </Dialog>
    </>
  );
};

export default RiskAssesmentLoader;
