import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";

function RiskoMeterChart({ chart_data = {}, chart_status = "" }) {
  // Creates the chart, this code only runs one time
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 180,
        endAngle: 360,
      })
    );

    // Create axis and its renderer
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Axes
    var axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -30,
      strokeOpacity: 0.1,
      //   strokeWeight: 50,
    });

    axisRenderer.labels.template.set("forceHidden", true);
    axisRenderer.grid.template.set("forceHidden", true);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 1,
        strictMinMax: true,
        renderer: axisRenderer,
      })
    );

    // Add "Low", "Neutral", and "Medium" labels
    var highDataItem = xAxis.makeDataItem({});
    highDataItem.set("value", 0);
    highDataItem.set("endValue", 0.2);
    xAxis.createAxisRange(highDataItem);
    highDataItem.get("label").setAll({ text: "High", forceHidden: false });
    highDataItem.get("axisFill").setAll({
      visible: true,
      fillOpacity: 1,
      fill: "#BC2970",
    });

    var mediumHighDataItem = xAxis.makeDataItem({});
    mediumHighDataItem.set("value", 0.2);
    mediumHighDataItem.set("endValue", 0.4);
    xAxis.createAxisRange(mediumHighDataItem);
    mediumHighDataItem
      .get("label")
      .setAll({ text: "Medium-High", forceHidden: false });
    mediumHighDataItem.get("axisFill").setAll({
      visible: true,
      fillOpacity: 1,
      fill: "#FF5C65",
    });

    var mediumDataItem = xAxis.makeDataItem({});
    mediumDataItem.set("value", 0.4);
    mediumDataItem.set("endValue", 0.6);
    xAxis.createAxisRange(mediumDataItem);
    mediumDataItem.get("label").setAll({ text: "Medium", forceHidden: false });
    mediumDataItem.get("axisFill").setAll({
      visible: true,
      fillOpacity: 1,
      fill: "#FD9982",
    });

    var lowMediumDataItem = xAxis.makeDataItem({});
    lowMediumDataItem.set("value", 0.6);
    lowMediumDataItem.set("endValue", 0.8);
    xAxis.createAxisRange(lowMediumDataItem);
    lowMediumDataItem
      .get("label")
      .setAll({ text: "Medium-Low ", forceHidden: false });
    lowMediumDataItem.get("axisFill").setAll({
      visible: true,
      fillOpacity: 1,
      fill: "#F8C268", // Change color as desired
    });

    var lowDataItem = xAxis.makeDataItem({});
    lowDataItem.set("value", 0.8);
    lowDataItem.set("endValue", 1);
    xAxis.createAxisRange(lowDataItem);
    lowDataItem.get("label").setAll({ text: "Low", forceHidden: false });
    lowDataItem.get("axisFill").setAll({
      visible: true,
      fillOpacity: 1,
      fill: "#6CCE81",
    });

    // Add clock hand
    // https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Clock_hands
    var axisDataItem = xAxis.makeDataItem({});
    let chart_val = 0;
    if ((chart_data?.status || chart_status) === "Low") {
      chart_val = 0.9;
    } else if ((chart_data?.status || chart_status) === "Medium-Low") {
      chart_val = 0.7;
    } else if ((chart_data?.status || chart_status) === "Medium") {
      chart_val = 0.5;
    } else if ((chart_data?.status || chart_status) === "Medium-High") {
      chart_val = 0.3;
    } else if ((chart_data?.status || chart_status) === "High") {
      chart_val = 0.1;
    } else {
      chart_val = 0.0;
    }
    axisDataItem.set("value", chart_val);

    var label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(50),
        fontSize: "1.5em",
      })
    );

    // axisDataItem.set("value", 50);

    var bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: am5radar.ClockHand.new(root, {
          radius: am5.percent(99),
        }),
      })
    );

    // to set the caption in chart
    // bullet.get("sprite").on("rotation", function () {
    //   var value = axisDataItem.get("value");
    //   label.set(
    //     "html",
    //     `<div><h1>${chart_data?.status || "High"}</h1><b></b></div>`
    //   );
    // });
    xAxis.createAxisRange(axisDataItem);

    axisDataItem.get("grid").set("visible", false);

    // Make stuff animate on load
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chart_data?.status, chart_status]);

  return <div id="chartdiv" className="riskometer"></div>;
}

export default RiskoMeterChart;
