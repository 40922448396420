import React from "react";

import { Link, useLocation } from "react-router-dom";

import { Button } from "@mui/material";
import { clearLocalStoarge, decryptPayload } from "../components/helpers/utils";
import { ROLES } from "../components/helpers/constants";

function PageNotFound() {
  const location = useLocation();
  const goToHomeHandler = () => {
    const valueToCheck = decryptPayload(localStorage.getItem("roles"));
    // console.log("Roles==>", valueToCheck);
    const valuesArray = Object.values(ROLES);

    if (location?.state?.removeToken) {
      if (valuesArray.includes(valueToCheck)) {
        // console.log(`${valueToCheck} is found in the Roles.`);
      } else {
        clearLocalStoarge();
        // console.log(`${valueToCheck} is not found in the Roles.`);
      }
    }
  };
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  return (
    <div style={{ marginTop: "100px", textAlign: "center" }}>
      <h1>404</h1>
      <div>
        <h2>The page you requested was not found.</h2>
        {/* <Link to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}>
          {is_scrp_hp ? null : (
            <Button
              style={{ marginTop: "10px" }}
              variant="contained"
              onClick={goToHomeHandler}
            >
              go to home
            </Button>
          )}
        </Link> */}
      </div>
    </div>
  );
}

export default PageNotFound;
