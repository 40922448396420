import React from "react";
import attention from "../../assets/properties/attention.png";
import { Col, Container, Row } from "react-bootstrap";
import "./PropertyRiskReport.scss";

const CommonReasonsSection = () => {
  return (
    <>
      <Container fluid className="high-risk-reason-container ">
        <div>
          <h3 className="hrr_title ">
            Below are some common reasons why a property might be flagged as{" "}
            {/* <span className="text-lowercase">{proeprtyStatus}</span> */}
            HIGH risk:
          </h3>
        </div>

        <Row className="mt-4 mb-5 text-start hrr_points">
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={3}
            className="content-spacing "
          >
            <img
              className="mb-3"
              src={attention}
              alt="No Mortgages on the Property"
            />
            <p className="reasons_title">No Mortgages on the Property</p>
            <p className="reasons_desc pe-0 pe-lg-5">
              Properties without active mortgages can be more vulnerable to
              fraudulent activities, as there are fewer financial institutions
              involved in monitoring the property.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={3} className="content-spacing">
            <img className="mb-3" src={attention} alt="Vacant Land" />
            <p className="reasons_title">Vacant Land</p>
            <p className="reasons_desc pe-0 pe-lg-5">
              Vacant properties are often targeted for fraudulent transactions
              because they are less likely to be under regular surveillance by
              the owner.
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={3} className="content-spacing">
            <img className="mb-3" src={attention} alt="Non-Owner Occupied" />
            <p className="reasons_title">Non-Owner Occupied</p>
            <p className="reasons_desc pe-0 pe-lg-5">
              Properties that are not occupied by the owner can be at a higher
              risk for fraud, especially if they are rented out or left
              unattended for long
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={3} className="content-spacing">
            <img className="mb-3" src={attention} alt="call today" />
            <p className="reasons_title">Call us today</p>
            <p className="reasons_desc pe-0 pe-lg-5">
              Call us today as there are many other reasons such as having your
              property in a trust or LLC. Having a mailing address differing
              from the site address.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CommonReasonsSection;
