import React from "react";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import { ROLES } from "../../../helpers/constants";
import tip from "../../../../assets/icons/trip.svg";
import cycle from "../../../../assets/icons/cycle.svg";
import wifiHome from "../../../../assets/icons/wifi_home.svg";
import personAdd from "../../../../assets/icons/user_add.svg";
import subUsers from "../../../../assets/icons/sub_users.svg";
import autoPause from "../../../../assets/icons/autopause.svg";
import sendMoney from "../../../../assets/icons/send_money.svg";
import uccPending from "../../../../assets/icons/ucc_pending.svg";
import attachMoney from "../../../../assets/icons/attach_money.svg";
import syncProblem from "../../../../assets/icons/sync_problem.svg";
import personCheck from "../../../../assets/icons/person_check.svg";
import newProperty from "../../../../assets/icons/new_property.svg";
import syncDisabled from "../../../../assets/icons/sync_disabled.svg";
import personCancel from "../../../../assets/icons/person_cancel.svg";
import locationHome from "../../../../assets/icons/location_home.svg";
import locationAway from "../../../../assets/icons/location_away.svg";
import pendingUsers from "../../../../assets/icons/pending_users.svg";
import assignmentInd from "../../../../assets/icons/assignment_ind.svg";
import freeMonitoring from "../../../../assets/icons/free_monitoring.svg";
import activeProperties from "../../../../assets/icons/active_property.svg";
import totalProperties from "../../../../assets/icons/total_properties.svg";
import currencyExchange from "../../../../assets/icons/currency_exchange.svg";
import addressVerification from "../../../../assets/icons/address_verification.svg";
import subscriptionReminder from "../../../../assets/icons/notifications_active.svg";
import terminatedProperties from "../../../../assets/icons/terminated_properties.svg";
import securityQuestionSkipped from "../../../../assets/icons/security_que_skipped.svg";
import { adminDashboardAction } from "../../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";
import {
  currencyFormatter,
  decryptPayload,
  encryptPayload,
  getDateInMMDDYYYYFormat,
  getDateInYYYYMMDDFormat,
} from "../../../helpers/utils";

import "./UserStates.scss";

const UserStates = ({ statistics, dateRange, dateRangeFormat }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let roles = decryptPayload(localStorage.getItem("roles"));

  const adminPropertiesURL = `/admin-properties?fromDashboard=subscription`;
  const adminPropertyMonitoringURL = `/admin-property-monitoring?fromDashboard=free-monitoring`;
  const adminUsersURL = `/admin-users?fromDashboard=users`;
  const toDate = new Date();
  const currentDate =
    toDate?.getFullYear() +
    "-" +
    (toDate?.getMonth() + 1) +
    "-" +
    toDate?.getDate();

  const findPropertySubscriptionStatus = (event, action, input = "url") => {
    let tempFilterData = {
      subscription: action,
      type: "subscription",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };
    const today = new Date();
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(thisWeekStart.getDate() + 7);
    if (action === "upcoming_subscription_renewals") {
      tempFilterData = {
        action: action,
        type: "all",
        upcoming_subscription_renewals_from_date: getDateInYYYYMMDDFormat(
          new Date(today)
        ),
        upcoming_subscription_renewals_to_date:
          getDateInYYYYMMDDFormat(thisWeekStart),
        from_date: undefined,
        to_date: undefined,
      };
    }

    //for open new tab link for subscription status cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminPropertiesURL}&&${filterData}`;

    if (input === "url") {
      return URL;
    }

    dispatch(
      adminDashboardAction.getSubscriptionModuleFilterData(tempFilterData)
    );

    localStorage.setItem(
      "tempPropertyFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );

    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminPropertiesURL);
    }
  };

  const findPropertiesData = (event, action, input = "url") => {
    let tempFilterData = {
      status: action,
      type: "status",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };

    if (action === "newProperties") {
      tempFilterData = {
        action: action,
        type: "all",
        from_date: dateRange ? dateRange?.from_date : currentDate,
        to_date: dateRange ? dateRange?.to_date : currentDate,
      };
    }
    //for open new tab link for property status cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminPropertiesURL}&&${filterData}`;
    if (input === "url") {
      return URL;
    }

    dispatch(
      adminDashboardAction.getSubscriptionModuleFilterData(tempFilterData)
    );
    localStorage.setItem(
      "tempPropertyFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );
    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminPropertiesURL);
    }
  };

  const findPendingUCCPropertiesData = (event, action, input = "url") => {
    const tempFilterData = {
      ucc_status: action,
      type: "ucc_status",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };

    //for open new tab link for UCC status cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminPropertiesURL}&&${filterData}`;

    if (input === "url") {
      return URL;
    }
    dispatch(
      adminDashboardAction.getSubscriptionModuleFilterData(tempFilterData)
    );

    localStorage.setItem(
      "tempPropertyFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );

    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminPropertiesURL);
    }
  };

  const findUsersStatus = (event, action, input = "url") => {
    let tempFilterData = {
      status: action,
      type: "status",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };
    if (action === "newUsers") {
      tempFilterData = {
        action: action,
        type: "all",
        from_date: dateRange ? dateRange?.from_date : currentDate,
        to_date: dateRange ? dateRange?.to_date : currentDate,
      };
    }

    //for open new tab link for Users status cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminUsersURL}&&${filterData}`;

    if (input === "url") {
      return URL;
    }

    dispatch(adminDashboardAction.getUsersModuleFilterData(tempFilterData));

    localStorage.setItem(
      "tempUsersFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );

    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminUsersURL);
    }
  };

  const findUsersRoleType = (event, role, input = "url") => {
    const tempFilterData = {
      user_type: role,
      type: "type",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };

    //for open new tab link for users role cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminUsersURL}&&${filterData}`;

    if (input === "url") {
      return URL;
    }

    dispatch(adminDashboardAction.getUsersModuleFilterData(tempFilterData));

    localStorage.setItem(
      "tempUsersFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );

    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminUsersURL);
    }
  };

  const findMonitoringProperties = (event, status, input = "url") => {
    const tempFilterData = {
      monitoring_status: status,
      type: "free_subscription",
      from_date: dateRange ? dateRange?.from_date : undefined,
      to_date: dateRange ? dateRange?.to_date : undefined,
    };

    //for open new tab link for Free property monitoring cards
    const filterData = encryptPayload(JSON.stringify(tempFilterData));
    const URL = `${adminPropertyMonitoringURL}&&${filterData}`;

    if (input === "url") {
      return URL;
    }

    dispatch(
      adminDashboardAction.getMonitoringPropertiesFilterData(tempFilterData)
    );

    localStorage.setItem(
      "tempMonitoringFilterData",
      encryptPayload(JSON.stringify(tempFilterData))
    );

    if (event.button === 0 && !event.ctrlKey) {
      // Middle-click (Open in new tab)
      navigate(adminPropertyMonitoringURL);
    }
  };

  return (
    <>
      <div className="state_block">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className="text-end px-3">
              <span className="asOnDate">As on date:</span>{" "}
              <b>
                {dateRangeFormat
                  ? `${
                      dateRangeFormat?.from_date +
                      ` to ` +
                      dateRangeFormat?.to_date
                    }`
                  : getDateInMMDDYYYYFormat(new Date(), "/")}
              </b>
            </p>
          </Grid>
          {roles?.includes(ROLES?.admin) && (
            <>
              {/* Total Revenue */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card className="revenue-cards">
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="numbers_fonts"
                          style={{ marginRight: "10px" }}
                        >
                          {currencyFormatter(
                            statistics?.total_revenue,
                            "$0.00"
                          )}
                        </Typography>
                        {statistics?.revenueChange && (
                          <Typography className="number_percentage revenue_numbers">
                            <TrendingUpIcon fontSize="small" />{" "}
                            <span>+{statistics?.revenueChange}%</span>
                          </Typography>
                        )}
                      </div>
                      <Typography variant="body2">Total Revenue</Typography>
                    </div>
                    <img src={attachMoney} alt="attachMoney" />
                  </CardContent>
                </Card>
              </Grid>

              {/* Refund Amount */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card className="revenue-cards">
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="numbers_fonts"
                          style={{ marginRight: "10px" }}
                        >
                          {currencyFormatter(
                            statistics?.total_refunds,
                            "$0.00"
                          )}
                        </Typography>
                        {statistics?.refundChange && (
                          <Typography className="number_percentage refund_numbers">
                            <TrendingDownIcon fontSize="small" />{" "}
                            <span>-{statistics?.refundChange}%</span>
                          </Typography>
                        )}
                      </div>
                      <Typography variant="body2">Refund Amount</Typography>
                    </div>

                    <img src={sendMoney} alt="sendMoney" fontSize="large" />
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}

          {/* Properties */}
          <Grid className="px-1 my-3" md={12}>
            <Accordion defaultExpanded className="accodion_cards">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accodion_summary"
              >
                <Divider textAlign="left">
                  <Typography className="category_header">
                    Properties
                  </Typography>
                </Divider>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className="px-1 mt-3 d-flex align-items-center" container>
                  {/* Total Properties */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Card className="state_cards total-properties-bgclr">
                      <CardContent>
                        <Typography className="numbers_fonts">
                          {statistics?.total_properties || 0}
                        </Typography>
                        <Typography className="caption_fonts">
                          Total Properties
                        </Typography>
                      </CardContent>
                      <img
                        src={totalProperties}
                        alt="wifiHome"
                        className="icons"
                      />
                      <CardActions>{null}</CardActions>
                    </Card>
                  </Grid>
                  {/* New Properties */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    {" "}
                    <Link
                      onMouseDown={(event) =>
                        findPropertiesData(event, "newProperties", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findPropertiesData(null, "newProperties", "url")}`}
                    >
                      <Card className="state_cards new-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.new_properties || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            New Properties
                          </Typography>
                        </CardContent>
                        <img
                          src={newProperty}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                              <Tooltip
                                title="Always shows today's data, unaffected by date filter."
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  {/* Active Properties */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertiesData(event, "Active", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findPropertiesData(null, "Active", "url")}`}
                    >
                      <Card className="state_cards active-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.active_properties || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Active Properties
                          </Typography>
                        </CardContent>
                        <img
                          src={activeProperties}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  {/* Address Verification Pending */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertiesData(event, "Pending", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findPropertiesData(null, "Pending", "url")}`}
                    >
                      <Card className="state_cards pending-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.address_verification_pending || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Address Verification Pending
                          </Typography>
                        </CardContent>
                        <img
                          src={addressVerification}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  {/* Ucc Pending*/}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPendingUCCPropertiesData(
                          event,
                          "pending",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPendingUCCPropertiesData(
                        null,
                        "pending",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards renewals-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.ucc_pending || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            UCC Pending
                          </Typography>
                        </CardContent>
                        <img
                          src={uccPending}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Free Property Monitoring */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findMonitoringProperties(event, "1", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findMonitoringProperties(null, "1", "url")}`}
                    >
                      <Card className="state_cards active-properties-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.free_property_monitoring || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Free Property Monitoring
                          </Typography>
                        </CardContent>
                        <img
                          src={freeMonitoring}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  {/* Security Questions Skipped */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertiesData(
                          event,
                          "Security Questions Skipped",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertiesData(
                        null,
                        "Security Questions Skipped",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards expired-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.security_questions_skipped || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Security Questions Skipped
                          </Typography>
                        </CardContent>
                        <img
                          src={securityQuestionSkipped}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Terminated Properties */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertiesData(event, "Terminated", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findPropertiesData(null, "Terminated", "url")}`}
                    >
                      <Card className="state_cards cancelled-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.terminated_properties || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Terminated Properties
                          </Typography>
                        </CardContent>
                        <img
                          src={terminatedProperties}
                          alt="wifiHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Subscriptions */}
          <Grid className="px-1 my-3" container>
            <Accordion defaultExpanded className="accodion_cards">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accodion_summary"
              >
                {" "}
                <Divider textAlign="left">
                  <Typography className="category_header">
                    Subscriptions
                  </Typography>
                </Divider>
              </AccordionSummary>

              <AccordionDetails>
                <Grid className="px-1 mt-3 d-flex align-items-center" container>
                  {/* Active Subscriptions */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertySubscriptionStatus(
                          event,
                          "active",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertySubscriptionStatus(
                        null,
                        "active",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards active-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.active_subscriptions || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Active Subscriptions
                          </Typography>
                        </CardContent>
                        <img
                          src={currencyExchange}
                          alt="currencyExchange"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Pending Subscriptions */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertySubscriptionStatus(
                          event,
                          "not_applicable",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertySubscriptionStatus(
                        null,
                        "not_applicable",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards pending-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.pending_subscriptions || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Pending Subscriptions
                          </Typography>
                        </CardContent>
                        <img
                          src={autoPause}
                          alt="autoPause"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Renewal Subscriptions */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Card className="state_cards renewals-subscription-bgclr">
                      <CardContent>
                        <Typography className="numbers_fonts">
                          {statistics?.subscription_renewal || 0}
                        </Typography>
                        <Typography className="caption_fonts">
                          Subscription Renewals
                        </Typography>
                      </CardContent>
                      <img src={cycle} alt="cycle" className="icons" />
                      <CardActions>{null}</CardActions>
                    </Card>
                  </Grid>

                  {/* Subscription Expiry Reminders */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertySubscriptionStatus(
                          event,
                          "upcoming_subscription_renewals",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertySubscriptionStatus(
                        null,
                        "upcoming_subscription_renewals",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards reminder-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.subscription_expiry_reminder || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Upcoming Subscription Renewals
                          </Typography>
                        </CardContent>
                        <img
                          src={subscriptionReminder}
                          alt="syncProblem"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                              <Tooltip
                                title="Always shows next 7 days upcoming subscription, unaffected by date filter."
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Expired Subscriptions */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertySubscriptionStatus(
                          event,
                          "expired",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertySubscriptionStatus(
                        null,
                        "expired",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards expired-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.expired_subscriptions || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Expired Subscriptions
                          </Typography>
                        </CardContent>
                        <img
                          src={syncProblem}
                          alt="syncDisabled"
                          className="icons"
                        />
                        <CardActions>
                          <Box className="more_info_section">
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Cancelled Subscriptions */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findPropertySubscriptionStatus(
                          event,
                          "cancelled",
                          "filterData"
                        )
                      }
                      className="text-decoration-none"
                      to={`${findPropertySubscriptionStatus(
                        null,
                        "cancelled",
                        "url"
                      )}`}
                    >
                      <Card className="state_cards cancelled-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.cancelled_subscriptions || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Cancelled Subscriptions
                          </Typography>
                        </CardContent>
                        <img
                          src={syncDisabled}
                          alt="personCheck"
                          className="icons"
                        />
                        <CardActions>
                          <Box className="more_info_section">
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Users */}
          <Grid className="px-1 my-3" md={12}>
            <Accordion defaultExpanded className="accodion_cards">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accodion_summary"
              >
                <Divider textAlign="left">
                  <Typography className="category_header">Users</Typography>
                </Divider>
              </AccordionSummary>

              <AccordionDetails>
                <Grid className="px-1 my-3 d-flex align-items-center" container>
                  {/* Active Users */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findUsersStatus(event, "active", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findUsersStatus(null, "active", "url")}`}
                    >
                      <Card className="state_cards active-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.active_users || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Active Users
                          </Typography>
                        </CardContent>
                        <img
                          src={personCheck}
                          alt="personCheck"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* New Users */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //  xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findUsersStatus(event, "newUsers", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findUsersStatus(null, "newUsers", "url")}`}
                    >
                      <Card className="state_cards new-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.new_users || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            New Users
                          </Typography>
                        </CardContent>
                        <img
                          src={personAdd}
                          alt="personAdd"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                              <Tooltip
                                title="Always shows today's data, unaffected by date filter."
                                TransitionComponent={Zoom}
                                placement="top"
                                arrow
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Inactive Users */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //    xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findUsersStatus(event, "inactive", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findUsersStatus(null, "inactive", "url")}`}
                    >
                      <Card className="state_cards inactive-users-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.inactive_users || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Inactive Users
                          </Typography>
                        </CardContent>
                        <img
                          src={personCancel}
                          alt="personCancel"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Pending Users */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //    xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findUsersStatus(event, "pending", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findUsersStatus(null, "pending", "url")}`}
                    >
                      <Card className="state_cards pending-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.pending_users || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Pending Users
                          </Typography>
                        </CardContent>
                        <img
                          src={pendingUsers}
                          alt="personAdd"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  {/* Sub Users */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    //    xl={2}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      onMouseDown={(event) =>
                        findUsersRoleType(event, "sub_user", "filterData")
                      }
                      className="text-decoration-none"
                      to={`${findUsersRoleType(null, "sub_user", "url")}`}
                    >
                      <Card className="state_cards expired-subscription-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.sub_users || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Sub Users
                          </Typography>
                        </CardContent>
                        <img src={subUsers} alt="personAdd" className="icons" />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                  {/* Professionals */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      className="text-decoration-none"
                      to={"/admin-professionals"}
                    >
                      <Card className="state_cards properties-review-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.professionals || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Professionals
                          </Typography>
                        </CardContent>
                        <img
                          src={assignmentInd}
                          alt="assignmentInd"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* User types */}
          {/* <Grid className="px-1 mt-3 mb-4" md={12}>
            <Accordion defaultExpanded className="accodion_cards">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accodion_summary"
              >
                <Divider textAlign="left">
                  <Typography className="category_header">
                    User types
                  </Typography>
                </Divider>
              </AccordionSummary>
              <AccordionDetails>
                <Grid className="px-1 mt-3 d-flex align-items-center" container>
                  Internal admins
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link className="text-decoration-none" to={"/admins"}>
                      <Card className="state_cards total-properties-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.internal_admins || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Internal Admins
                          </Typography>
                        </CardContent>
                        <img
                          src={locationHome}
                          alt="locationHome"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  County Admins
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link className="text-decoration-none" to={"/admin-county"}>
                      <Card className="state_cards inactive-properties-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.county_admins || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            County admins
                          </Typography>
                        </CardContent>
                        <img
                          src={locationAway}
                          alt="locationAway"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  Professionals
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      className="text-decoration-none"
                      to={"/admin-professionals"}
                    >
                      <Card className="state_cards properties-review-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.professionals || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Professionals
                          </Typography>
                        </CardContent>
                        <img
                          src={assignmentInd}
                          alt="assignmentInd"
                          className="icons"
                        />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  Service Providers
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      className="text-decoration-none"
                      to={"/service-providers"}
                    >
                      <Card className="state_cards new-properties-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.service_providers || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Service Providers
                          </Typography>
                        </CardContent>
                        <img src={wifiHome} alt="wifiHome" className="icons" />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>

                  Providers
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    paddingX={"10px"}
                    paddingY={"15px"}
                  >
                    <Link
                      className="text-decoration-none"
                      to={"/admin-providers"}
                    >
                      <Card className="state_cards active-properties-bgclr">
                        <CardContent>
                          <Typography className="numbers_fonts">
                            {statistics?.providers || 0}
                          </Typography>
                          <Typography className="caption_fonts">
                            Providers
                          </Typography>
                        </CardContent>
                        <img src={tip} alt="tip" className="icons" />
                        <CardActions>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className="more_info_section"
                            padding="10px"
                            style={{ width: "100%" }}
                          >
                            <Typography
                              variant="body2"
                              className="more_info_text"
                            >
                              More Info
                            </Typography>
                            <ArrowForwardIcon className="arrow_icon" />
                          </Box>
                        </CardActions>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid> */}
        </Grid>
      </div>
    </>
  );
};

export default UserStates;
