import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import AlertDialog from "../common/AlertDialog";
import SettingsIconSVG from "../common/SettingsIconSVG";
import FreeMonitoringUserForm from "./FreeMonitoringUserForm";
import { getObjLength, riskLevelCount } from "../helpers/utils";
import TableHeaderForSorting from "../common/TableHeaderForSorting";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

const InvestorPropertiesListTable = ({
  selectedProperties,
  setSelectedProperties = () => {},
  setSelectedProeprtiesID = () => {},
  isActionButton = true,
  rows = [],
  columns = [],
  owner_name = "",
  selectedProeprtiesID = [],
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isFreeMonitoringModal, setIsFreeMonitoringModal] = useState(false);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [isAddUseDetails, setIsAddUseDetails] = useState(false);
  const [clickToSelectAll, setClickToSelectAll] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { investorReportResultData, investorMonitoringData, optionType } =
    useSelector((state) => state?.propertyRiskManagement);

  const investorEmail = investorReportResultData?.data?.email;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const freeMonitoringInfoModalClose = (action) => {
    if (action === "add_details" && !investorReportResultData?.data?.email) {
      setIsAddUseDetails(true);
    } else {
      let URL = `investor/enable-property-monitoring/${investorReportResultData?.data?.id}/enable`;
      if (selectedRow?.free_subscription) {
        URL = `county-subscription/${selectedRow?.risk_report?.id}/0`;
      }

      dispatch(
        propertyRiskManagementAction.getInvestorMonitoringData({
          url: URL,
          data: { property_ids: [selectedRow?.id] },
        })
      );
      setIsFreeMonitoringModal(false);
      setIsAddUseDetails(false);
    }
  };
  console.log("selectedRow==>", selectedRow);

  const premiumSubscriptionInfoModalClose = () => {
    if (!investorReportResultData?.data?.email) {
      setIsAddUseDetails(true);
    } else {
      let URL = `investor/update-property/${investorReportResultData?.data?.id}`;

      dispatch(
        propertyRiskManagementAction.getInvestorMonitoringData({
          url: URL,
          data: { property_ids: [selectedRow?.id], hold_for_signup: 1 },
        })
      );
      setIsFreeMonitoringModal(false);
      setIsAddUseDetails(false);
    }
  };

  const premiumSubcriptionOption = () => {
    dispatch(propertyRiskManagementAction.setOptionType("premium_monitoring"));
    dispatch(
      propertyRiskManagementAction.selectedPropertiesForPremiumMonitoring(
        // !selectedRow?.hold_for_signup ? [selectedRow] : []
        [selectedRow]
      )
    );
    setIsSubscriptionModal(true);
    // if (selectedRow?.hold_for_signup && investorReportResultData?.data?.user) {
    //   setIsSubscriptionModal(true);
    // } else {
    //   setIsSubscriptionModal(false);
    //   dispatch(propertyRiskManagementAction.setOptionType(null));
    //   if (
    //     selectedRow?.hold_for_signup &&
    //     !investorReportResultData?.data?.user
    //   ) {
    //     navigate(`/create-account`);
    //   } else {
    //     //navigate(`/login`);
    //   }
    // }
  };

  const handleClose = () => {
    setIsFreeMonitoringModal(false);
    setIsAddUseDetails(false);
    setIsSubscriptionModal(false);
    if (selectedRow?.hold_for_signup && optionType === "premium_monitoring") {
      if (investorReportResultData?.data?.user) {
        navigate(`/login`);
      } else {
        navigate(`/create-account`);
      }
      dispatch(propertyRiskManagementAction.setOptionType(null));
      dispatch(propertyRiskManagementAction.fetchInvestorMonitoringData({}));
      dispatch(propertyRiskManagementAction.resetInvestorFormDetails({}));
    }
  };

  const handleMenuClick = (event, data) => {
    setSelectedRow(data);
    setAnchorEl(event.currentTarget);
  };

  const selectProperties = (event, row) => {
    if (event.target.checked) {
      // Add the row to the selected rows (full row data)
      setSelectedProperties((prevSelectedProperties) => [
        ...prevSelectedProperties,
        row,
      ]);
    } else {
      // Remove the row from the selected rows
      setSelectedProperties((prevSelectedProperties) =>
        prevSelectedProperties.filter(
          (selectedRow) => selectedRow?.id !== row?.id
        )
      );
    }
  };
  console.log("investoraData==>", investorReportResultData?.data);

  const isSelected = (id) => {
    // Check if the row is selected based on its id
    return selectedProperties?.some((selectedRow) => selectedRow?.id === id);
  };

  //for select proeprties
  const onSelectAllClick = (event, action) => {
    setClickToSelectAll(!clickToSelectAll); // to select/deselct all for mobile devices
    console.log("onSelectAllClick--<", event.target.checked);

    if (event.target.checked || action) {
      const newSelected = rows?.map((n) => n.id);
      if (newSelected?.length === 1) {
        setSelectedRow(rows?.[0]);
      }
      setSelectedProperties(rows);
      setSelectedProeprtiesID(newSelected);
      return;
    }
    setSelectedProperties([]);
    setSelectedProeprtiesID([]);
  };

  useEffect(() => {
    if (getObjLength(investorMonitoringData)) {
      handleClose();
    }
  }, [investorMonitoringData]);

  return (
    <>
      {/* Desktop Table */}
      <TableContainer component={Paper} className="d-none d-sm-block">
        <Table aria-label="property table">
          <TableHeaderForSorting
            columns={columns}
            hideSelectAllCheckbox={rows?.length > 1 ? true : false}
            onSelectAllClick={onSelectAllClick}
            numSelected={selectedProeprtiesID?.length}
            rowCount={rows?.length}
            checkBoxColor={"success"}
          />
          <TableBody>
            {rows?.map((row, index) => {
              const isItemSelected = isSelected(row?.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      !isActionButton &&
                      ((optionType === "free_monitoring" &&
                        row?.free_subscription) ||
                        (optionType === "premium_monitoring" &&
                          row?.hold_for_signup))
                        ? "rgb(0 0 0 / 7%)"
                        : "",
                  }}
                >
                  {isActionButton && (
                    <TableCell>
                      <Checkbox
                        color="success"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        key={row?.id}
                        onChange={(e) => {
                          selectProperties(e, row);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell>
                  )}
                  <TableCell align="left">{row?.site_address}</TableCell>
                  <TableCell align="left">{row?.site_city}</TableCell>
                  <TableCell align="left">{row?.site_state}</TableCell>
                  <TableCell align="left">{row?.site_zipcode}</TableCell>
                  <TableCell align="left">{owner_name || "-"}</TableCell>
                  <TableCell align="left">{row?.apn_number}</TableCell>
                  <TableCell align="left">
                    {row?.free_subscription ? "Enable" : "Disable"}
                  </TableCell>
                  <TableCell align="left">
                    <div className="d-flex justify-content-start align-items-center">
                      {isActionButton && (
                        <>
                          <div
                            class={` color-box ${
                              riskLevelCount(row?.risk_level) > 0 &&
                              " color-low"
                            } `}
                          ></div>
                          <div
                            class={` color-box ${
                              riskLevelCount(row?.risk_level) > 1 &&
                              " color-medium-low"
                            } `}
                          ></div>
                          <div
                            class={` color-box ${
                              riskLevelCount(row?.risk_level) > 2 &&
                              " color-medium"
                            } `}
                          ></div>
                          <div
                            class={` color-box ${
                              riskLevelCount(row?.risk_level) > 3 &&
                              " color-medium-high"
                            } `}
                          ></div>
                          <div
                            class={` color-box ${
                              riskLevelCount(row?.risk_level) > 4 &&
                              " color-high"
                            } `}
                          ></div>
                        </>
                      )}
                      <span className="ps-1">{row?.risk_level}</span>
                    </div>
                  </TableCell>
                  {isActionButton && (
                    <TableCell align="left" className="">
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleMenuClick(event, row);
                        }}
                        size="small"
                        className="settings-button"
                        aria-haspopup="true"
                      >
                        <SettingsIconSVG color={"#15BE53"} />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows && !rows?.length > 0 && (
          <Typography className="p-5 text-center" variant="h6">
            No Data Found.
          </Typography>
        )}
      </TableContainer>
      {/* Mobile Table */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 pt-4 ">
        {rows?.length > 1 && (
          <Box className="text-end mx-2">
            <Button
              className=" text-end other_popup_btn"
              onClick={(e) => onSelectAllClick(e, !clickToSelectAll)}
            >
              Select All
            </Button>
          </Box>
        )}
        {rows?.map((row, index) => {
          const isItemSelected = isSelected(row?.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent={"space-between"}
                spacing={0}
                p={1.4}
                pt={2}
                mb={2}
                sx={{
                  backgroundColor:
                    !isActionButton && row?.free_subscription
                      ? "rgb(0 0 0 / 7%)"
                      : "",
                }}
              >
                <Stack direction="row" alignItems="baseline">
                  {isActionButton && (
                    <div>
                      <Checkbox
                        color="success"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        key={row?.id}
                        onChange={(e) => {
                          selectProperties(e, row);
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  )}
                  <div>
                    <Stack direction="column" spacing={1} px={1}>
                      <Typography
                        className="key fw-500"
                        sx={{ fontSize: "15px !important" }}
                      >
                        Address
                      </Typography>
                      <Stack direction="column" spacing={0.5}>
                        <Typography className="value mt-1">
                          {row?.site_address || "-"}
                        </Typography>
                        <Typography className="value mt-2">
                          {row?.site_city || "-"} {row?.site_state || "-"}{" "}
                          {row?.site_zipcode || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={4} className="my-3">
                        <>
                          <Stack direction="column" spacing={0.5}>
                            <Typography className="key">Owner</Typography>
                            <Typography className="value">
                              {owner_name}
                            </Typography>
                          </Stack>
                        </>
                        <>
                          <Stack direction="column" spacing={0.5}>
                            <Typography className="key">APN</Typography>
                            <Typography className="value">
                              {row?.apn_number}
                            </Typography>
                          </Stack>
                        </>
                      </Stack>
                      <Stack direction="row" spacing={4} className="my-3">
                        <Stack direction="column" spacing={0.5}>
                          <Typography className="key">
                            Free Monitoring
                          </Typography>
                          <Typography className="value">
                            {row?.free_subscription ? "Enable" : "Disable"}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="column"
                          spacing={0.5}
                          className="mb-3"
                        >
                          <Typography className="key">Risk Score</Typography>
                          <Typography className="value">
                            <div className="d-flex justify-content-start align-items-center">
                              {isActionButton && (
                                <>
                                  <div
                                    class={` color-box ${
                                      riskLevelCount(row?.risk_level) > 0 &&
                                      " color-low"
                                    } `}
                                  ></div>
                                  <div
                                    class={` color-box ${
                                      riskLevelCount(row?.risk_level) > 1 &&
                                      " color-medium-low"
                                    } `}
                                  ></div>
                                  <div
                                    class={` color-box ${
                                      riskLevelCount(row?.risk_level) > 2 &&
                                      " color-medium"
                                    } `}
                                  ></div>
                                  <div
                                    class={` color-box ${
                                      riskLevelCount(row?.risk_level) > 3 &&
                                      " color-medium-high"
                                    } `}
                                  ></div>
                                  <div
                                    class={` color-box ${
                                      riskLevelCount(row?.risk_level) > 4 &&
                                      " color-high"
                                    } `}
                                  ></div>
                                </>
                              )}
                              <span className="ps-1 fw-500">
                                {row?.risk_level}
                              </span>
                            </div>
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>
                <>
                  {isActionButton && (
                    <div>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuClick(e, row);
                        }}
                        size="small"
                        sx={{ color: "#15BE53" }}
                      >
                        <SettingsIconSVG />
                      </IconButton>
                    </div>
                  )}
                </>
              </Stack>

              {index < rows?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {rows && !rows?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>
      {!isActionButton &&
        selectedProeprtiesID?.length === 0 &&
        (optionType === "free_monitoring" ||
          optionType === "premium_monitoring") && (
          <p>
            {" "}
            <b>Note:</b>&nbsp;
            <span>
              {optionType === "premium_monitoring" ? (
                !investorReportResultData?.data?.user ? (
                  <>
                    You’ve already linked these properties to{" "}
                    {investorEmail ? <b>{investorEmail}</b> : "user"}. To
                    proceed, please create account using this email. If you
                    encounter any issues, contact the support team for
                    assistance.
                  </>
                ) : (
                  <>
                    The account for the properties listed here has already been
                    created by the{" "}
                    {investorEmail ? <b>{investorEmail}</b> : "user"}. Please{" "}
                    login to complete the process or contact the support team
                    for assistance.
                  </>
                )
              ) : (
                `The selected properties are already set up for free monitoring.
            Please pick the properties that are not enabled yet.`
              )}
            </span>
          </p>
        )}
      {/* Menu Options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="free_monitoring"
          onClick={() => {
            setIsFreeMonitoringModal(true);
            dispatch(
              propertyRiskManagementAction.setOptionType("free_monitoring")
            );
          }}
          className="setting-menu-item"
        >
          {!selectedRow?.free_subscription ? (
            <Box className="setting-menu-box">
              <CheckCircleOutlineIcon />
              <Typography className="setting-menu-text">
                Enable Free Monitoring
              </Typography>
            </Box>
          ) : (
            <Box className="setting-menu-box">
              <NotInterestedIcon />
              <Typography className="setting-menu-text">
                Disable Free Monitoring
              </Typography>
            </Box>
          )}
        </MenuItem>

        <MenuItem
          key="premium_subscription"
          onClick={premiumSubcriptionOption}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <AccountBalanceWalletOutlinedIcon />
            <Typography className="setting-menu-text">
              Start Premium Subscription
            </Typography>
          </Box>
        </MenuItem>
      </Menu>

      {isFreeMonitoringModal && (
        <AlertDialog
          type={isAddUseDetails ? null : "Info"}
          title={isAddUseDetails ? "Add Owner Info" : ""}
          width={isAddUseDetails ? "md" : "sm"}
          openPopup={true}
          onClose={true}
          onCloseFun={handleClose}
          mesage={
            <>
              {isAddUseDetails ? (
                <FreeMonitoringUserForm
                  handleClose={handleClose}
                  selectedPropertIds={[selectedRow?.id]}
                  owner_name={owner_name}
                />
              ) : (
                <>
                  Are you sure you want to{" "}
                  {selectedRow?.free_subscription ? "Stop" : "Start"} Free
                  Monitoring for <b>{selectedRow?.site_address}</b>?
                </>
              )}
            </>
          }
          footer={false}
          buttons={
            <>
              {isAddUseDetails ? null : (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={{ sm: "center", xs: "end" }}
                  className=""
                >
                  <Button
                    className="popup_Btn other_popup_btn  mx-2 mt-3 admin_buttons"
                    onClick={handleClose}
                  >
                    No
                  </Button>

                  <Button
                    className="popup_Btn success_popup_btn mx-2 mt-3 admin_buttons"
                    onClick={() => freeMonitoringInfoModalClose("add_details")}
                  >
                    Yes
                  </Button>
                </Stack>
              )}
            </>
          }
        />
      )}

      {isSubscriptionModal && (
        <AlertDialog
          type={
            isAddUseDetails
              ? null
              : //  : selectedRow?.hold_for_signup
                //? "Warning"
                "Info"
          }
          title={isAddUseDetails ? "Add Owner Info" : ""}
          width={isAddUseDetails ? "md" : "sm"}
          openPopup={true}
          onClose={true}
          onCloseFun={handleClose}
          mesage={
            <>
              {isAddUseDetails ? (
                <FreeMonitoringUserForm
                  handleClose={handleClose}
                  selectedPropertIds={[selectedRow?.id]}
                  owner_name={owner_name}
                  isSubscriptionModal={isSubscriptionModal}
                />
              ) : (
                <>
                  {selectedRow?.hold_for_signup ? (
                    <>
                      The account for the properties listed here has already
                      been created by the{" "}
                      {investorEmail ? <b>{investorEmail}</b> : "user"}. Please{" "}
                      login to complete the process or contact the support team
                      for assistance.
                    </>
                  ) : (
                    <>
                      {" "}
                      Are you sure you want to Purchase Premium Subscription for{" "}
                      <b>{selectedRow?.site_address}</b>?
                    </>
                  )}
                </>
              )}
            </>
          }
          footer={false}
          buttons={
            <>
              {isAddUseDetails ? null : (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={{ sm: "center", xs: "end" }}
                  className=""
                >
                  <Button
                    className={`popup_Btn mx-2 mt-3 admin_buttons ${
                      selectedRow?.hold_for_signup
                        ? "success_popup_btn"
                        : "other_popup_btn"
                    }`}
                    onClick={handleClose}
                  >
                    {selectedRow?.hold_for_signup ? "OK, all good" : " Cancel"}
                  </Button>

                  {!selectedRow?.hold_for_signup && (
                    <Button
                      className="popup_Btn success_popup_btn mx-2 mt-3 admin_buttons"
                      onClick={premiumSubscriptionInfoModalClose}
                    >
                      Next
                    </Button>
                  )}
                </Stack>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default React.memo(InvestorPropertiesListTable);
