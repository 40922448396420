import React from "react";

import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

import RealStoryDesign from "../common/RealStoryDesign";
import TitleLock from "../../assets/SEO/TitleLock.webp";
import FirstCommonBlogSection from "./FirstCommonBlogSection";
import SecondCommonBlogSection from "./SecondCommonBlogSection";
import LearnEquityProtectSection from "./LearnEquityProtectSection";

import "./Blogs.scss";

const TitleInsurance = () => {
  return (
    <div className="blogs-content">
      <FirstCommonBlogSection
        title={"What is Title Insurance and What Does It Cover?"}
        mainImg={"titleLockImg"}
      />
      <SecondCommonBlogSection
        mainContent={
          <>
            Title insurance is an integral part of the home-buying process,{" "}
            <Link to={"/title-protection-long-time-homeowners"} target="_blank">
              providing homeowners with protection
            </Link>{" "}
            and peace of mind. But what exactly does title insurance cover?
            Knowing how title insurance works can help homeowners avoid
            unexpected complications. Let’s explore how this coverage ensures a
            smooth homeownership experience.
          </>
        }
        content={[
          { type: "title_tag_2", content: "What Is Title Insurance?" },
          {
            type: "caption_texts",
            content:
              "Title insurance is a one-time purchase typically made at the closing of a real estate transaction. Its purpose is to eradicate risks and prevent losses caused by defects in title arising from past events. It protects property owners and their lenders against loss or damage occurring from liens or encumbrances. Through a title search and a title insurance policy, homeowners can be confident that they are the legitimate owners of their property.",
          },
          { type: "title_tag_3", content: "Types of Title Insurance Policies" },
          {
            type: "caption_texts",
            content: (
              <>
                There are two types of title insurance policies: lender's title
                insurance and owner's title insurance. The lender's policy
                protects the mortgage lender's interest in the property should a
                problem with the title arise. It's usually required by the
                lender in a mortgage loan transaction.
              </>
            ),
          },
          {
            type: "caption_texts",
            content: (
              <>
                On the other hand, owner's title insurance is meant to protect
                the homeowner. If a defect or hidden encumbrance on the title
                wasn't discovered during the title search, or if a lawsuit
                challenges the legality of the new owner's property ownership,
                this policy will cover the costs up to the original purchase
                price.
              </>
            ),
          },
          { type: "title_tag_2", content: "Title Lock vs. Title Insurance" },
          {
            type: "caption_texts",
            content: (
              <>
                Title lock services are often mistaken for title insurance but
                serve different functions. Title lock services monitor your
                property title and alert you if there are any changes or
                potentially fraudulent activities, such as someone attempting to
                claim ownership of your property under your name. These services
                aim to prevent title fraud and identity theft before they occur,
                but they do not offer financial coverage for any legal costs or
                loss in property value.
              </>
            ),
          },
          {
            type: "caption_texts",
            content: (
              <>
                On the flip side, title insurance provides financial coverage in
                the event of title defects or undisclosed liens that were missed
                during the title search or if there's a lawsuit challenging your
                property ownership. Unlike title lock, it does not continuously
                monitor your title for potential fraud.
              </>
            ),
          },
          {
            type: "image",
            imgSrc: TitleLock,
            alt: "Title Lock",
          },
          {
            type: "title_tag_2",
            content: "What Does Title Insurance Cover?",
          },
          {
            type: "caption_texts",
            content: (
              <>
                Title insurance offers essential coverage against pre-existing
                liens and encumbrances that were not found during the title
                search, ensuring you have clear, undisputed ownership of your
                property. For instance, if there are unpaid taxes, construction
                liens, or other legal claims against the property from previous
                owners, title insurance will cover these costs.
              </>
            ),
          },
          {
            type: "caption_texts",
            content: (
              <>
                Additionally, it also covers legal defects in the title, such as
                errors in public records, forgery, and fraud up to the amount of
                the original purchase price.
              </>
            ),
          },
          {
            type: "title_tag_2",
            content: "What Does Title Insurance Not Cover?",
          },
          {
            type: "caption_texts",
            content: (
              <>
                While title insurance covers a wide array of issues, it does
                have its limitations. It generally does not cover post-policy
                risks or certain disclosed risks listed in the policy
                exceptions. These may include specific recorded liens, certain
                governmental regulations, or issues that arise after you've
                purchased your policy, such as boundary disputes with neighbors
                or damages due to future events like flooding or land erosion.
              </>
            ),
          },
          {
            type: "title_tag_2",
            content: "Does Title Insurance Cover Title Theft?",
          },
          {
            type: "caption_texts",
            content: (
              <>
                <Link to={"/what-is-home-title-theft"} target="_blank">
                  Title theft, or property fraud
                </Link>
                , refers to situations where a scammer forges your identity to
                transfer your property title to themselves, typically to secure
                a mortgage loan and disappear with the money. Title insurance
                comes into play in such scenarios, providing legal defense and
                covering the costs associated with reinstating your rightful
                ownership.
              </>
            ),
          },
          {
            type: "title_tag_2",
            content: "How Much Is Title Insurance?",
          },
          {
            type: "caption_texts",
            content: (
              <>
                The cost of title insurance depends on several factors,
                including the value of your property, the state in which you
                reside, and whether you are buying a lender's or owner's policy.
                However, it is typically a one-time fee that ranges from a few
                hundred to a couple of thousand dollars. Homeowners should shop
                around and consider the different options available for maximum
                protection.
              </>
            ),
          },
          {
            type: "title_tag_2",
            content: "Let EquityProtect Help Protect Your Property",
          },
          {
            type: "caption_texts",
            content: (
              <>
                Understanding title insurance—what it covers and its costs—can
                make a big difference for homeowners. It shields your property
                rights and offers peace of mind during ownership. If you're
                looking for more details or want to explore your options,{" "}
                <Link to={"/"} target="_blank">
                  contact us
                </Link>
                 at EquityProtect today.
              </>
            ),
          },
        ]}
      />
      <LearnEquityProtectSection />
      <section className="story_section">
        <Container fluid className="mx-150">
          <div>
            <RealStoryDesign
              subTitle="Related articles"
              title="More stories about title fraud"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default TitleInsurance;
