import React, { Suspense } from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import { Stack, Typography } from "@mui/material";

import RealStoryDesign from "../common/RealStoryDesign";

const ProtectionCTASection = ({
  title_container2,
  content_containet2,
  buttons = true,
  is_real_story = true,
  classes = "",
}) => {
  return (
    <>
      {" "}
      <Container fluid className={`protection_container`}>
        <Row className={`protection__bg ${classes}`}>
          <Col className="">
            <div className="protection__block">
              <Typography variant="h3" className="protection__header-text">
                {title_container2}
              </Typography>
              <Typography className={"protection__para-text"}>
                {content_containet2}
              </Typography>
              {buttons && (
                <Stack
                  display={"flex"}
                  justifyContent={{
                    sm: "center",
                    md: "center",
                  }}
                  marginTop={"30px"}
                  alignItems={"center"}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                >
                  <Link
                    className="text-white text-decoration-none"
                    to="/property-risk-management"
                  >
                    <button className="ttp_buttons signup_btn">
                      Free risk assessment
                    </button>
                  </Link>
                  <Link
                    className="text-white text-decoration-none"
                    to="/create-account"
                  >
                    <button className=" ttp_buttons victim_button ">
                      Contact us now
                    </button>
                  </Link>
                </Stack>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {is_real_story && (
        <div className="pt-150 pb-5 story_section">
          <Container fluid className="spacing-right pb-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoadComponent threshold={200}>
                <RealStoryDesign />
              </LazyLoadComponent>
            </Suspense>
          </Container>
        </div>
      )}
    </>
  );
};

export default ProtectionCTASection;
