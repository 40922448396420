import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import StayInForm from "./StayInForm";
import NewMap from "../common/NewMap";
import { Loader } from "../common/loader";
import AlertDialog from "../common/AlertDialog";
import ContactSupport from "../common/ContactSupport";
import { SearchPropertyForm } from "./SearchPropertyForm";
import CommonReasonsSection from "./CommonReasonsSection";
import attention from "../../assets/properties/attention.png";
import ProtectPropertySection from "./ProtectPropertySection";
import RiskoMeterChart from "../common/charts/RiskoMeterChart";
import Facebook_Icon from "../../assets/articles/facebook.svg";
import { LoaderWithTimeout } from "../common/LoaderWithTimeout";
import maskgroup from "../../assets/properties/mask-group.webp";
import RiskAssesmentLoader from "../common/RiskAssesmentLoader";
import Instagram_Icon from "../../assets/articles/instagram.svg";
import county_pana from "../../assets/properties/county_pana.webp";
import { FACEBOOK_LINK, INSTAGRAM_LINK } from "../helpers/constants";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
import {
  currencyFormatter,
  decryptPayload,
  encryptPayload,
  getObjLength,
  propertyRiskReportDataObj,
} from "../helpers/utils";

import "./PropertyRiskReport.scss";

export const PropertyRiskReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loaded } = useSelector((state) => state?.autocomplete);
  const queryString = window.location.search;
  let paramString = queryString.split("?")[1];
  let valueOf = paramString?.split("=")[0];
  // Create URL object
  const parsedUrl = new URL(window?.location?.href);
  const params = new URLSearchParams(parsedUrl?.search);
  // Extract tokens
  const reportToken = params.get("report_token");
  const resendToken = params.get("resend_token");

  const [subscribeModal, setSubscribeModal] = useState(false);
  const [defaultSubscribeModal, setDefaultSubscribeModal] = useState(false); // for the subscribe when user comes from mail
  const [defaultWarningModal, setDefaultWarningModal] = useState(false); // for the users with medium or above risk property

  let is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  let host_path = decryptPayload(localStorage.getItem("host_path"));
  let isRiskAssessment = localStorage.getItem("isRiskAssessment");

  // store data
  const {
    riskManagementData,
    propertyData,
    riskManagementLoader,
    subscribeFreeMonitoring,
  } = useSelector((state) => state?.propertyRiskManagement);

  console.log("propertyData==>", propertyData);

  const handleStartFreeMonitoringModal = () => {
    if (propertyData?.success && !propertyData?.data?.status?.includes("Low")) {
      console.log("free monitoring");
      setSubscribeModal(false);
      setDefaultWarningModal(true);
    } else {
      console.log("free monitoring1");
      setSubscribeModal(true);
      setDefaultWarningModal(false);
    }
  };

  const subscribeFreeMonitor = (
    county_details,
    fromDefaultWarningModal = false
  ) => {
    // set county if it's already available
    if (getObjLength(county_details)) {
      is_scrp_hp = 1;
      countyCode = county_details?.county_domain_name;
    }

    setSubscribeModal(false);
    setDefaultWarningModal(false);
    let user_details = decryptPayload(localStorage.getItem("user_details"));
    const data = JSON.parse(user_details);
    console.log("data==>", data);
    if (
      propertyData?.success &&
      !propertyData?.data?.status?.includes("Low") &&
      !fromDefaultWarningModal
    ) {
      setDefaultWarningModal(true);
    } else {
      setDefaultWarningModal(false);
      dispatch(
        propertyRiskManagementAction.subscribeFreeMonitoring({
          url: `county-subscription/${propertyData?.data?.id}/1`,
          data: {},
        })
      );
    }
  };

  const closeSubscribeModal = () => {
    if (host_path) {
      navigate(`${host_path}`);
    } else {
      if (
        is_scrp_hp &&
        subscribeFreeMonitoring?.success &&
        valueOf !== "report_token"
      ) {
        navigate(`/county-recorder/${countyCode}`);
        dispatch(
          propertyRiskManagementAction.clearPropertyRiskManagementData({})
        );
      }
      // else {
      //   navigate("/property-risk-management");
      // }
    }
    dispatch(propertyRiskManagementAction.fetchSubscribeFreeMonitoring({}));
  };

  const goBackToPropertyRiskForm = () => {
    let county_details = riskManagementData?.data?.county;
    if (host_path) {
      navigate(`${host_path}`);
      localStorage.removeItem("host_path");
      localStorage.removeItem("host_code");
    } else {
      if (getObjLength(county_details)) {
        is_scrp_hp = 1;
        countyCode = county_details?.county_domain_name;
        navigate(`/county-recorder/${countyCode}`, { replace: true });
      } else {
        localStorage.removeItem("scrp_hp");
        localStorage.removeItem("county-code");
        if (isRiskAssessment) {
          navigate("/property-risk-assessment", { replace: true });
        } else {
          navigate("/property-risk-management", { replace: true });
        }
        localStorage.removeItem("isRiskAssessment");
      }
    }
    dispatch(propertyRiskManagementAction.clearPropertyRiskManagementData({}));
    dispatch(propertyRiskManagementAction.fetchSubscribeFreeMonitoring({}));
  };

  useEffect(() => {
    if (!getObjLength(propertyData) && valueOf !== "report_token") {
      if (host_path) {
        navigate(`${host_path}`);
        window.location.reload();
      } else {
        if (is_scrp_hp) {
          navigate(`/county-recorder/${countyCode}`);
        } else {
          if (isRiskAssessment) {
            navigate("/property-risk-assessment");
            localStorage.removeItem("isRiskAssessment");
          } else {
            navigate("/property-risk-management");
          }
          // window.location.reload();
        }
      }
    }
    if (getObjLength(propertyData)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // if (
      //   propertyData?.success &&
      //   !propertyData?.data?.status?.includes("Low") &&
      //   valueOf !== "report_token"
      // ) {
      //   setDefaultWarningModal(true);
      // } else {
      //   setDefaultWarningModal(false);
      // }
    }
  }, [propertyData]);

  const getProtctedBtn = () => {
    let user_details = decryptPayload(localStorage.getItem("user_details"));
    let data = JSON.parse(user_details);

    let property_search_data = {};
    if (valueOf === "report_token") {
      console.log("getRiskReportDetails-->", riskManagementData);
      property_search_data = propertyRiskReportDataObj(
        riskManagementData?.data
      );
    } else {
      property_search_data = propertyRiskReportDataObj(data);
    }
    console.log("property_search_data===>", property_search_data);
    localStorage.setItem(
      "property_search_data",
      JSON.stringify(property_search_data)
    );
    if (host_path) {
      navigate(`/create-account?from=risk_report`, {
        replace: true,
      });
      localStorage.removeItem("host_path");
      // localStorage.removeItem("host_code"); //due to set the host promocode on signup
    } else {
      navigate(
        `${is_scrp_hp ? "/" + countyCode : ""}/create-account?from=risk_report`,
        { replace: true }
      );
    }
    setDefaultWarningModal(false);
  };

  let proeprtyStatus = propertyData?.data?.status;

  useEffect(() => {
    if (valueOf === "report_token") {
      console.log("report_token=-->", reportToken);
      let data = {
        report_id: reportToken,
      };
      if (resendToken) {
        data.resend_token = resendToken;
      }
      dispatch(
        propertyRiskManagementAction.propertyRiskManagementData({
          url: "get-report-details",
          data: data,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      valueOf === "report_token" &&
      getObjLength(riskManagementData) &&
      riskManagementData?.success
      // &&  riskManagementData?.data?.free_subscription
    ) {
      // setDefaultSubscribeModal(true);
      let county_details = riskManagementData?.data?.county;
      subscribeFreeMonitor(county_details);
      // set county if it's already available
      if (getObjLength(county_details)) {
        localStorage.setItem("scrp_hp", 1);
        localStorage.setItem(
          "county-code",
          encryptPayload(county_details?.county_domain_name)
        );
      }
    }
  }, [riskManagementData]);

  if (!getObjLength(propertyData) || !loaded)
    return <LoaderWithTimeout open={true} />;

  return (
    <>
      <Box className="property-risk-report">
        <section
          className={`main_section`}
          style={{
            //  position: highRisk ? null : "relative",
            boxShadow: "0px 1px 0px 0px #DCE4EC,0px -1px 0px 0px #DCE4EC",
            // marginBottom: "150px",
          }}
        >
          <Container fluid className="warningcontainer">
            <Grid container alignItems="center">
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                className="mt-2"
                order={{ xs: 2, sm: 2, md: 1 }}
              >
                <Box className="property-image px-2 d-flex justify-content-center align-items-center section1">
                  <NewMap
                    className="map_section"
                    zoom={15}
                    center={{
                      lat: parseFloat(propertyData?.data?.latitude), //41.363114
                      lng: parseFloat(propertyData?.data?.longitude), //-74.186442
                    }}
                    coordinates={[]}
                    streetView={true}
                  />
                </Box>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                className="mt-2"
                order={{ xs: 1, sm: 1, md: 2 }}
              >
                <Box className="property-details position-relative px-2">
                  <Typography
                    variant="body1"
                    className="personal_info text-capitalize mb-2"
                  >
                    Personal information
                  </Typography>
                  <Typography variant="h5" className="property_add mb-2">
                    {propertyData?.data?.address || "-"}{" "}
                    {propertyData?.data?.unit_no &&
                      `#${propertyData?.data?.unit_no}`}
                  </Typography>

                  <Typography variant="body2" className="proeprty_date my-2">
                    Last checked on{" "}
                    {propertyData?.data?.last_checked_date || "-"}
                  </Typography>
                  {is_scrp_hp ? (
                    <Box className="subscribe_btn">
                      <Button
                        className={`monitoring_button `}
                        variant="contained"
                        onClick={handleStartFreeMonitoringModal}
                      >
                        Start Free Monitoring
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3.5}
                xl={3}
                className="border-left mt-2"
                order={{ xs: 3, sm: 3 }}
              >
                {/* <Stack
              className="bg-white m-0"
              direction={{ xs: "column", sm: "row" }}
              justifyContent={"space-around"}
              mt={4}
              textAlign={"center"}
            > */}
                <Box className="d-flex justify-content-center align-items-center h-75">
                  {riskManagementLoader ? (
                    <RiskoMeterChart chart_data={propertyData?.data} />
                  ) : (
                    <RiskoMeterChart chart_data={propertyData?.data} />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={2.5}
                xl={3}
                className="mt-2"
                order={{ xs: 4, sm: 4 }}
              >
                <Box className="property-details position-relative">
                  <Typography variant="body1" className="value_text">
                    A value
                  </Typography>
                  <Typography variant="body1" className="values">
                    {`${currencyFormatter(
                      propertyData?.data?.approx_price || 0
                    )}`}
                  </Typography>
                  {/* <Typography variant="body1" className="value_text">
                  Another value
                </Typography>
                <Typography variant="body1" className="values">
                  25%
                </Typography> */}
                </Box>
                {/* </Stack> */}
              </Grid>
            </Grid>
            <p className="text-center text-danger pb-4 m-0 mt-2 fw-500 alert_msg_spacing">
              {proeprtyStatus === "Low"
                ? "EquityProtect recommend our FREE monitoring service as your home received a low risk assessment score."
                : "EquityProtect strongly recommends our Premium Preventative Home Title Protection service due to your homes elevated risk  of Home Title Theft."}{" "}
              {propertyData?.data?.contact_support
                ? propertyData?.message
                : null}
            </p>
          </Container>
        </section>

        <section>
          <Container fluid className="warning-container">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                className={`text-center analytics_section ${proeprtyStatus?.toLowerCase()}`}
                style={{}}
              >
                <div className="analytics_div">
                  <p className="m-0">
                    Our analytics indicate that your property is currently
                    classified as{" "}
                    <span className="text-uppercase">{proeprtyStatus}</span>{" "}
                    risk for fraud. This assessment is based on a variety of
                    factors that have been identified through our extensive
                    analysis.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <CommonReasonsSection />
        </section>

        <ProtectPropertySection
          getProtctedBtn={getProtctedBtn}
          setSubscribeModal={handleStartFreeMonitoringModal}
        />

        <section className="serach_property_section ">
          {valueOf !== "report_token" ? (
            <Container fluid className="p-4 pb-0">
              <h3 className="add_property_title ">Analyze Another Property</h3>
              <Box className="search_property_form">
                <SearchPropertyForm />
              </Box>
            </Container>
          ) : (
            <Container fluid className="p-4 pb-0">
              <Box className="text-center">
                <Button
                  type="submit"
                  className="button1"
                  onClick={goBackToPropertyRiskForm}
                >
                  Analyze Another Property
                </Button>
              </Box>
            </Container>
          )}
        </section>

        <section className="py-5 social_section bg-white">
          <Container fluid className="px-3 px-md-5 ">
            <Stack
              direction={"column"}
              className=" card linear_bg"
              sx={{ padding: "30px 0" }}
            >
              <div className=" p-0">
                <p className="socialTitle card-title text-white text-center">
                  Liked our services? Please go on our social account and share
                  feedback.
                </p>
              </div>
              <Stack
                direction={"row"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className="text-center p-2 social_icons"
                sx={{ borderRadius: "8px" }}
              >
                <a
                  href={INSTAGRAM_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="px-3 pt-2"
                >
                  <img
                    src={Instagram_Icon}
                    alt="Instagram"
                    style={{ height: "50px" }}
                  />
                  {/* <InstagramIcon
                    style={{ color: "#E4405F" }}
                    fontSize="large"
                  /> */}
                </a>

                <a
                  href={FACEBOOK_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="px-3 pt-2 "
                >
                  <img
                    src={Facebook_Icon}
                    alt="Facebook"
                    style={{ height: "50px" }}
                  />
                  {/* <FacebookIcon fontSize="large" /> */}
                </a>
              </Stack>
            </Stack>
          </Container>
        </section>

        <section
          className="ep_glance_section "
          style={{
            position: "relative",
            background: "#F6F9FC",
            boxShadow: "0px 1px 0px 0px #DCE4EC,0px -1px 0px 0px #DCE4EC",
            // marginBottom: "150px",
          }}
        >
          <Box className="ep_glance_headers">
            <h3 className="ep_glance_title ">EquityProtect at a glance</h3>
            <Typography className="ep_glance_caption pt-2">
              {/* Varius nisl eget mauris ullamcorper diam maecenas nisl nulla
              praesent. */}
            </Typography>
          </Box>
          <Container fluid className="p-0 card_top box_shadow">
            <>
              <Stack
                className="bg-white m-0"
                direction={{ sm: "column", md: "row" }}
                justifyContent={"space-between"}
                mt={4}
                textAlign={"center"}
              >
                <Box className="card_content">
                  <>
                    <Typography
                      className="fw-600 text-black mb-4 d-flex text-start"
                      variant="h6"
                    >
                      Learn How EquityProtect™ Can Help Protect You
                    </Typography>
                    <ul
                      className="features_list mb-4"
                      style={{
                        listStyleType: "none",
                        textAlign: "left",
                        paddingRight: "1px",
                      }}
                    >
                      <li className="position-relative mb-3">
                        Physical Protection prevents the crime from occurring
                      </li>
                      <li className="position-relative mb-3">
                        24/7 title monitoring
                      </li>
                      <li className="position-relative mb-3">
                        Utilization of Multifactor authentication before
                        allowing permission to move forward with a new refinance
                        or sale
                      </li>
                    </ul>
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button1">
                        Prevention is a click away
                      </Button>
                    </Link>
                  </>
                </Box>
                <Box className="m-0 markgroup_img">
                  <img src={maskgroup} className="h-100 w-100" alt="home_Img" />
                </Box>
              </Stack>
            </>
          </Container>
        </section>

        <section className="pb-5 stay_loop_section">
          <Container fluid className="container_padding  pb-4">
            <Row className="d-flex justify-content-center align-items-center d-sm-pt-0 pt-2 m-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="stay_loop_img">
                  <img
                    src={county_pana}
                    className="h-100 w-100"
                    alt="home_Img"
                  />
                </Box>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className=" d-flex justify-content-center  second-column p-0 "
                id="lead-page-form"
              >
                <Box className="stay_loop_content">
                  <>
                    <Typography className="stay_loop_title py-2">
                      Stay in the loop
                    </Typography>
                    <Typography className="stay_loop_captions pt-2 pb-3">
                      Get the latest news and updates, straight to your inbox.
                      We’ll never spam you, you’ll only hear from us once a
                      month with the most important updates.
                    </Typography>
                    <Box className="pt-4 stay_in_form">
                      <StayInForm />
                    </Box>
                  </>
                </Box>
              </Col>
            </Row>
          </Container>
        </section>
      </Box>

      {getObjLength(riskManagementData) && !riskManagementData?.success && (
        <AlertDialog
          type="Error"
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (riskManagementData?.data?.errors?.search_count === 5) {
              navigate(`/county-recorder/${countyCode}`);
              dispatch(
                propertyRiskManagementAction.clearPropertyRiskManagementData({})
              );
            }
            if (riskManagementData?.statuscode === 422) {
              if (host_path) {
                navigate(`${host_path}`);
              } else {
                navigate(`/property-risk-report`);
              }
              dispatch(
                propertyRiskManagementAction.clearPropertyRiskManagementData({})
              );
            }
            dispatch(
              propertyRiskManagementAction.fetchPropertyRiskManagementData({})
            );
          }}
          mesage={
            <>
              {riskManagementData?.statuscode === 422
                ? "There was a problem processing your request. Please try again."
                : riskManagementData?.data?.message}
              {riskManagementData?.data?.errors?.contact_support && (
                <ContactSupport hideMsg={true} spacing={"pt-3"} />
              )}
            </>
          }
          footer={false}
        />
      )}

      {/* Free Monitoring modals */}

      {subscribeModal && (
        <AlertDialog
          type={"Attention"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => setSubscribeModal(false)}
          mesage={
            <>
              Are you sure you want to start free monitoring for{" "}
              <b>{propertyData?.data?.address}</b> ?
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn success_popup_btn mx-2"
                onClick={subscribeFreeMonitor}
              >
                Yes
              </Button>
              <Button
                className="popup_Btn other_popup_btn mx-2"
                onClick={() => setSubscribeModal(false)}
              >
                No
              </Button>
            </>
          }
        />
      )}

      {getObjLength(subscribeFreeMonitoring) ? (
        <AlertDialog
          type={subscribeFreeMonitoring?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={closeSubscribeModal}
          mesage={
            subscribeFreeMonitoring?.success
              ? subscribeFreeMonitoring?.message
              : subscribeFreeMonitoring?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      {defaultSubscribeModal ? (
        <AlertDialog
          type={"Success"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => setDefaultSubscribeModal(false)}
          mesage={
            <>
              The property <b>{riskManagementData?.data?.address}</b>{" "}
              subscription for free monitoring has been successfully activated
            </>
          }
        />
      ) : null}

      {/* for the proeprties with medium or high risk */}
      {defaultWarningModal && (
        <AlertDialog
          type={"Warning"}
          openPopup={defaultWarningModal}
          width="md"
          onClose={true}
          onCloseFun={() => setDefaultWarningModal(false)}
          mesage={
            <>
              Free monitoring is a <b>reactionary product</b> and is only
              recommended for homes of lower risk than your property. Your
              property received a rating of{" "}
              <span className="text-danger text-uppercase fw-700">
                {propertyData?.data?.status}
              </span>{" "}
              and EquityProtect <b>strongly recommends</b> our{" "}
              <b>preventative program</b> to protect your property from the{" "}
              <b>danger of fraudsters</b>.
            </>
          }
          buttons={
            <>
              <Button
                className="popup_Btn m-2"
                variant="contained"
                onClick={() =>
                  subscribeFreeMonitor(riskManagementData?.data?.county, true)
                }
              >
                Continue with monitoring
              </Button>
              <Button
                className="popup_Btn success_popup_btn m-2"
                onClick={getProtctedBtn}
              >
                sign up for preventative protection
              </Button>
            </>
          }
        />
      )}

      {/* <Loader open={riskManagementLoader} /> */}
      <RiskAssesmentLoader open={riskManagementLoader} />
    </>
  );
};
