import React from "react";

import bro from "../../assets/SEO/bro.webp";
import CommonFirstSection from "./CommonFirstSection";
import CommonThirdSection from "./CommonThirdSection";
import CommonFifthSection from "./CommonFifthSection";
import CommonSecondSection from "./CommonSecondSection";
import CommonFourthSection from "./CommonFourthSection";
import bgimg from "../../assets/SEO/TTP_RealEstateInvsBG.webp";
import house_documents from "../../assets/SEO/House documents.webp";
import protected_house from "../../assets/SEO/Protected House.webp";
import high_equity_home from "../../assets/SEO/High Equity Home.webp";
import bgimg_mobile from "../../assets/SEO/TTP_RealEstateInvsResponsiveBG.webp";

import "./TitleProtectionForRealEstateInvestors.scss";

const TitleProtectionForRealEstateInvestors = () => {
  return (
    <div className="title-protection-real-estate-investors">
      <CommonFirstSection
        topHeader={"Title Protection for Real Estate Investors"}
        topPara={`EquityProtect goes beyond monitoring to safeguard your title
                    and equity with our patented SmartPolicy, protecting your
                    home ownership from cybercriminals and fraud.`}
        bgImg={bgimg}
        bgImg_Mobile={bgimg_mobile}
      />

      <CommonSecondSection
        image={house_documents}
        content={[
          <>
            When you're a real estate investor, you need to keep up with the{" "}
            <a href={"/real-estate-scams"} target="_blank" rel="noreferrer">
              latest real estate investment scams
            </a>{" "}
            so you don't lose your investment. Some scams are obvious while
            others appear more legal on the face of it. Protecting your home's
            equity is important for you to realize all the benefits of your
            investment.
          </>,
          <>
            One of the most recent scams involves{" "}
            <a href="/what-is-home-title-theft" target="_blank">
              title theft
            </a>
            . The thief crafts paperwork that allows them to place a lien on
            your property. With the lien in place, they foreclose on the
            property and walk away with the equity. However, there are ways to
            be safe from deed theft.
          </>,
        ]}
      />
      <CommonThirdSection
        image={high_equity_home}
        title={"Why Thieves Go After Homes With High Equity"}
        content={[
          `You chose a home because of its attractiveness as a real
                  estate investment. It might be that you offer long-term
                  rentals or short vacation stays. You might be living in the
                  home and waiting until you have enough equity to upgrade to a
                  better, more expensive home. `,
          `People who engage in deed theft look for homes with high
                  equity because they can make the most money from them. For
                  example, it takes the same amount of work to fraudulently
                  assume a lien on a home with $20,000 worth of equity as it
                  does one with $100,000 worth of it. `,
          `  The higher-equity properties are more attractive to thieves
                  because they make more money. Protecting home equity becomes
                  more important as you gain more equity in the home or
                  property. `,
        ]}
      />
      <CommonFourthSection
        image={bro}
        title={"Can My Home Title Be Stolen When I Own My Home?"}
        content={[
          `Yes, your title can be stolen even if you own your home. Deed
                  theft is a process where the thieves file a fraudulent lien on
                  your property. The thieves typically create false loan
                  paperwork in which you offer your property to secure the
                  loan.  `,
          `  With the property as security, the thieves are able to place a
                  lien on the property even if you own your home. They wait for
                  a period of time before beginning the foreclosure process.
                  Since you didn't secure a loan with the property, you probably
                  won't know that there's a problem until the foreclosure
                  process starts. `,
          `  This delay can leave you struggling to prove fraud.
                  Unfortunately, the thieves have paperwork that "shows" you
                  offered the property as security. It falls to you to prove
                  that you didn't offer the property as security for a loan or
                  that a loan was never made. `,
        ]}
      />
      <CommonFifthSection
        image={protected_house}
        title={"Safeguard Your Hard-Earned Home Equity Investment"}
        content={[
          `Real estate investment scams are always evolving and becoming
                  more successful. It falls to you to protect your hard-earned
                  home equity. You can expend a lot of time and energy checking
                  with the local title office to ensure no one has
                  inappropriately placed a lien on your property. `,
          `There are many companies that offer to monitor your title for
                  you. However, this still leaves you playing defense if someone
                  does place a lien on your home. You have to take them to court
                  and prove that they don't have the right to a lien on your
                  property.  You need an option that puts you on the offense.
                  EquityProtect can help you do that. `,
        ]}
        title_container2={
          "Protection That Stops Home Equity Theft Before It Starts"
        }
        content_containet2={
          <>
            At{" "}
            <a href={"/"} target="_blank" rel="noreferrer">
              EquityProtect
            </a>
            , we want to help protect you from all real estate investment scams.
            Our team understands the devastation that comes from{" "}
            <a href="/what-is-home-title-theft" target="_blank">
              title theft
            </a>
            , and our unique program stops thieves in their tracks. We provide
            you with a lien on your home that you own, and no one else can
            supersede it. Contact us today to learn more and start protecting
            your investment property now.
          </>
        }
      />
    </div>
  );
};

export default TitleProtectionForRealEstateInvestors;
