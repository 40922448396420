import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";

import { Loader } from "../../common/loader";
import AlertDialog from "../../common/AlertDialog";
import { getObjLength } from "../../helpers/utils";
import AddPropertyPopup from "../../userProfile/add-property";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";

const ResumeSignupFlow = ({
  classes,
  from = "",
  getPropertyList,
  showBtn = true,
  initialyAddPropertyModal = false,
  setIsAddPropertyModalOpen = () => {},
  isByPassOptionAvailable = false,
  handleCloseModal = () => {},
  callListingAPI = true,
  selectedRowData = {},
  isAdminCreateUser = false,
}) => {
  console.log("YYYY initialyAddPropertyModal", initialyAddPropertyModal);

  const dispatch = useDispatch();

  const { getOTPVerificationData, userLoginLoader } = useSelector(
    (state) => state.signUpUser
  );

  const [isOtpConfirmationModalOpen, setIsOtpConfirmationModalOpen] =
    useState(true);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isOTPByPass, setIsOTPByPass] = useState(false);
  const [userLastStep, setUserLastStep] = useState(null);

  // handle otp confirmation modal
  const handleCreateUser = (isByPass) => {
    let payload = {};
    if (isByPass) {
      if (selectedRowData?.investor_id) {
        setUserLastStep("propertyList");
      } else {
        setUserLastStep("security");
      }
    } else {
      setUserLastStep("2fa");
    }
    setIsOTPByPass(isByPass);
    if (isByPass) {
      payload = {
        url: `admin/skip-otp-verification/${selectedRowData?.user_id}`,
        data: {},
        token: true,
      };
      dispatch(userSigupAction.getOTPVerificationData(payload));
    } else {
      payload = {
        url: `resend-otp/${
          selectedRowData?.json_last_step?.step_key === "VERIFY_PHONE_OTP"
            ? "email"
            : "phone"
        }`,
        data: { user_id: selectedRowData?.user_id },
      };
      console.log("ZZ called-->");
      if (selectedRowData?.json_last_step?.step_key === "VERIFY_PHONE_OTP") {
        localStorage.setItem("emailScreen", "1");
        setIsOtpConfirmationModalOpen(false);
      }
      dispatch(userSigupAction.getOTPVerificationData(payload));
    }

    console.log("send OTP::", isByPass);
  };

  useEffect(() => {
    // if (
    //   selectedRowData?.json_last_step?.step_key === "SEND_OTP" ||
    //   selectedRowData?.json_last_step?.step_key === "VERIFY_PHONE_OTP"
    // ) {
    //   setIsOtpConfirmationModalOpen(true);
    // } else {
    //   setIsOtpConfirmationModalOpen(false);
    // }
    if (selectedRowData?.json_last_step?.step > 0) {
      setIsOtpConfirmationModalOpen(false);
      localStorage.removeItem("emailScreen");
      if (
        (!selectedRowData?.investor_id &&
          selectedRowData?.json_last_step?.step_key === "VERIFY_EMAIL_OTP") ||
        (selectedRowData?.properties_count === 0 &&
          selectedRowData?.json_last_step?.step_key === "GENERATE_QUESTIONS")
      ) {
        setUserLastStep("security");
      } else if (
        (selectedRowData?.investor_id &&
          selectedRowData?.json_last_step?.step_key === "VERIFY_EMAIL_OTP") ||
        (selectedRowData?.properties_count > 0 &&
          selectedRowData?.json_last_step?.step_key === "GENERATE_QUESTIONS") ||
        selectedRowData?.json_last_step?.step_key === "VERIFY_ANSWERS" ||
        selectedRowData?.json_last_step?.step_key === "GET_MAILING_ADDRESS" ||
        selectedRowData?.json_last_step?.step_key === "SAVE_CREDIT_CARD"
      ) {
        setUserLastStep("propertyList");
      } else if (selectedRowData?.json_last_step?.step_key === "PAYMENT") {
        setUserLastStep("security-pin");
      } else if (selectedRowData?.json_last_step?.step_key === "SECURITY_PIN") {
        setUserLastStep("bio-metric");
      } else if (
        selectedRowData?.json_last_step?.step_key === "REGISTER_FACE"
      ) {
        setUserLastStep("signature");
      } else if (
        selectedRowData?.json_last_step?.step_key === "REGISTER_SIGNATURE"
      ) {
        setUserLastStep("beneficiaries");
      }
    }
    setIsLoaded(false);
  }, []);

  useEffect(() => {
    console.log("getOTPVerificationData==>", getOTPVerificationData);
    if (getObjLength(getOTPVerificationData)) {
      if (!getOTPVerificationData?.success) {
        setIsOtpConfirmationModalOpen(true);
      } else {
        setIsOtpConfirmationModalOpen(false);
        if (isOTPByPass) {
          localStorage.setItem("emailScreen", "1");
        }
      }
    }
  }, [getOTPVerificationData]);

  return (
    <>
      {!isLoaded && (
        <>
          {isOtpConfirmationModalOpen ? (
            <>
              <AlertDialog
                type={`${
                  getObjLength(getOTPVerificationData) &&
                  !getOTPVerificationData?.success
                    ? "Error"
                    : "Info"
                }`}
                openPopup={isOtpConfirmationModalOpen}
                onClose={true}
                onCloseFun={() => {
                  setIsOtpConfirmationModalOpen(false);
                  setIsAddPropertyModalOpen(false);
                }}
                mesage={
                  getObjLength(getOTPVerificationData) &&
                  !getOTPVerificationData?.success ? (
                    <p className="text-danger mb-0">
                      {getOTPVerificationData?.data?.message}
                    </p>
                  ) : (
                    "Do you want this user to bypass the Token confirmation process?"
                  )
                }
                footer={false}
                buttons={
                  getObjLength(getOTPVerificationData) &&
                  !getOTPVerificationData?.success ? (
                    <Button
                      className="popup_Btn other_popup_btn mx-2"
                      onClick={() => {
                        setIsOtpConfirmationModalOpen(false);
                        handleCloseModal();
                      }}
                    >
                      Close
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="popup_Btn other_popup_btn mx-2"
                        onClick={() => handleCreateUser(false)}
                      >
                        No
                      </Button>
                      <Button
                        className="popup_Btn success_popup_btn mx-2"
                        onClick={() => handleCreateUser(true)}
                      >
                        Yes
                      </Button>
                    </>
                  )
                }
              />
            </>
          ) : (
            <AddPropertyPopup
              isResumeSignup={true}
              classes={classes}
              from={from}
              getPropertyList={getPropertyList}
              showBtn={showBtn}
              initialyAddPropertyModal={initialyAddPropertyModal}
              setIsAddPropertyModalOpen={setIsAddPropertyModalOpen}
              isByPassOptionAvailable={isByPassOptionAvailable}
              handleCloseModal={handleCloseModal}
              callListingAPI={callListingAPI}
              selectedRowData={selectedRowData}
              step={userLastStep}
              user_id={selectedRowData?.user_id}
              isAdminCreateUser={isAdminCreateUser}
              showSecurityHeaderToCheckout={true}
              goToProeprtyList={true} // to get back on Owner Verified screen
            />
          )}
        </>
      )}
      <Loader open={isLoaded || userLoginLoader} />
    </>
  );
};

export default ResumeSignupFlow;
