import { put, call, takeLatest } from "redux-saga/effects";
import { postAPIPayload } from "../../../../apis/postApi";
import { propertyRiskManagementAction } from "../../../slices/admin/property-risk-management/propertyRiskManagementSlice";

export function* fetchRiskManagementDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    propertyRiskManagementAction.fetchPropertyRiskManagementData(response)
  );
}

export function* fetchCountyDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyRiskManagementAction.fetchCountyData(response));
}

export function* fetchSaveEmailSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyRiskManagementAction.fetchSaveEmailDetails(response));
}

export function* fetchSubscribeFreeMonitoringSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    propertyRiskManagementAction.fetchSubscribeFreeMonitoring(response)
  );
}

export function* fetchFreeMonitoringListSaga({ payload }) {
  console.log("In free Monitoring list sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  yield put(propertyRiskManagementAction.fetchFreeMonitoringList(response));
}

export function* fetchDeleteMonitoredPropertySaga({ payload }) {
  console.log("In free Monitoring list sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  yield put(
    propertyRiskManagementAction.fetchDeletedMonitoredPropertyData(response)
  );
}

export function* fetchResendReportMailSaga({ payload }) {
  console.log("In free Monitoring list sagas -->", payload);
  const response = yield call(postAPIPayload, payload);
  yield put(propertyRiskManagementAction.getResendReportMailData(response));
}

export function* fetchInvestorReportResultDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(
    propertyRiskManagementAction.fetchInvestorReportResultData(response)
  );
}

export function* fetchInvestorMonitoringDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);
  yield put(propertyRiskManagementAction.fetchInvestorMonitoringData(response));
}

export function* watchRiskManagementSagaAsync() {
  yield takeLatest(
    propertyRiskManagementAction.propertyRiskManagementData,
    fetchRiskManagementDataSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.countyData,
    fetchCountyDataSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.saveEmailDetails,
    fetchSaveEmailSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.subscribeFreeMonitoring,
    fetchSubscribeFreeMonitoringSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.freeMonitoringList,
    fetchFreeMonitoringListSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.deleteMonitoredProperty,
    fetchDeleteMonitoredPropertySaga
  );
  yield takeLatest(
    propertyRiskManagementAction.fetchResendReportMailData,
    fetchResendReportMailSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.getInvestorReportResultData,
    fetchInvestorReportResultDataSaga
  );
  yield takeLatest(
    propertyRiskManagementAction.getInvestorMonitoringData,
    fetchInvestorMonitoringDataSaga
  );
}
