import { useEffect } from "react";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import LockIcon from "@mui/icons-material/Lock";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

import SignInForm from "./signupForm";
import { Loader } from "../common/loader";
import ResumeProcess from "./resumeProcess";
import AlertDialog from "../common/AlertDialog";
import HorizontalLinearStepper from "./signupSteps";
import DialogBox from "../userProfile/Dashboard/cardPopup";
import AppStore from "../../assets/signin/app_store_img.webp";
import { appStoreURL, playStoreURL } from "../helpers/constants";
import GooglePlayeStore from "../../assets/signin/playstore.webp";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import {
  clearLocalStoarge,
  decryptPayload,
  encryptPayload,
  getObjLength,
  reactPixelEvent,
} from "../helpers/utils";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "./signup.scss";

const queryString = window.location.search;
let paramString = queryString.split("?")[1];
let source = paramString?.split("=");
let isIOS = source?.[1]?.split("&")?.[0];
let biometric = paramString?.split("&")?.[1];
let isBiometric = biometric?.split("=")?.[1]; // after the redirecting from the IOS native methods
let isBiometric1 = paramString?.split("="); // after the redirecting from the IOS native methods
console.log("isBiometric1==>", isBiometric1);
if (isBiometric1?.[0] === "isBiometric" && JSON.parse(isBiometric1?.[1])) {
  console.log("isBiometric1==>", isBiometric1);
  localStorage.setItem("isMobile", encryptPayload("ios"));
}
let iosCode = "";
if (isIOS === "ios") {
  let cookieValue = document.cookie;
  // iosCode = source?.[2];
  iosCode = cookieValue?.replace(
    /(?:(?:^|.*;\s*)iosValue\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  localStorage.setItem("preference", true);
}

// console.log(document.cookie, "status ios==>", iosCode);
console.log("isBiometric==>", isBiometric);

const UserSignup = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { county_name } = useParams();

  const [btnCLicked, setBtnClicked] = useState(false);
  const [resumePopup, setResumePopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [signupStep, setSignupStep] = useState(0);
  const [errorCount, setErrorCount] = useState(
    localStorage.getItem("errorCount")
  );

  const [resumeCount, setResumeCount] = useState(
    parseInt(localStorage.getItem("currentStep"))
  );
  const [activeStep, setActiveStep] = useState(
    parseInt(localStorage.getItem("currentStep"))
  );

  const resumeProcessResponse = useSelector(
    (state) => state.signUpUser.resumeProcessResponse
  );

  const errorFlagCount = useSelector(
    (state) => state.signUpUser.errorFlagCount
  );
  const { isFromIosDevice } = useSelector((state) => state?.signUpUser);
  const { countyData, countyLoader } = useSelector(
    (state) => state?.propertyRiskManagement
  );
  const is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));

  useEffect(() => {
    if (is_scrp_hp) {
      const currentURL = window?.location?.pathname.replace(/\/$/, ""); // remove extra / from the end of the URL
      navigate(currentURL);
      const currentUrl = window.location.href;
      if (!currentUrl.includes(countyCode)) {
        // for resume signup process for county users
        const newUrl = "/" + countyCode + "/create-account";
        navigate(newUrl, { replace: false });
        // window.location.href = newUrl;
      }
    }

    dispatch(userSigupAction.clearData());
    dispatch(propertiesAction.resetState());
    localStorage.removeItem("authToken");
    localStorage.removeItem("authorised");
    reactPixelEvent("Create User Account", {});
    console.log("clear data...");

    // for IOS APP
    if (isIOS === "ios") {
      if (iosCode) {
        dispatch(
          userSigupAction.fetchResumeData(JSON.parse(window.atob(iosCode)))
        );
        console.log("IOS response ==>", JSON.parse(window.atob(iosCode)));
      }
      //jump to sign screen when comes from native app
      if (isBiometric && JSON.parse(isBiometric)) {
        localStorage.setItem("currentStep", 6);
        setActiveStep(6);
        setResumeCount(6);
        localStorage.setItem("isMobile", encryptPayload("ios"));
        resumetheFlow();
      }
    }

    if (county_name) {
      dispatch(
        propertyRiskManagementAction.countyData({
          url: `county/details`,
          data: { county: county_name },
        })
      );
    }
  }, [dispatch]);

  const createAcc = (e) => {
    console.log("click on next==>", e);
    setBtnClicked(true);
  };
  const resumetheFlow = () => {
    if (getObjLength(resumeProcessResponse)) {
      if (resumeProcessResponse?.success) {
        console.log("resume flow==>", resumeProcessResponse);
        reactPixelEvent(
          "Resume Signup Process",
          resumeProcessResponse?.data?.user_signup_details
        );
        let county_details = resumeProcessResponse?.data?.county_details;
        if (getObjLength(county_details)) {
          localStorage.setItem(
            "county-code",
            encryptPayload(county_details?.county_domain_name)
          );
          //Set County-Recorder Page to HomePage(scrp_hp)
          localStorage.setItem("scrp_hp", 1);
          navigate(
            "/" + county_details?.county_domain_name + "/create-account",
            {
              replace: false,
            }
          );
        }
      }
      setActiveStep(
        resumeProcessResponse?.data?.json_last_step?.step ||
          localStorage.getItem("currentStep")
      );
    }
    setSignupStep(localStorage.getItem("currentStep"));
    setResumeCount(undefined);
    setBtnClicked(true);
  };
  const resumeProcess = () => {
    setResumePopup(true);
    // if (resumeCount + 1 > 0) {
    //   resumetheFlow();
    // } else {
    // return (
    //   <AlertDialog
    //     title="Resume Process"
    //     content="You have not started the process yet."
    //     open={true}
    //     onClose={() => {}}
    //   />
    // );
    // }
  };

  const reduceErrorCount = () => {
    setErrorPopup(false);
    if (parseInt(errorFlagCount) > 3) {
      dispatch(userSigupAction.setErrorCount(parseInt(errorFlagCount) - 1));
      localStorage.setItem("errorCount", parseInt(errorFlagCount) - 1);
    }
  };

  useEffect(() => {
    console.log("resumeProcessResponse => ", resumeProcessResponse);
    if (getObjLength(resumeProcessResponse) && resumeProcessResponse.success) {
      // setOpenPopup(false);
      resumetheFlow();
    }
  }, [resumeProcessResponse]);

  useEffect(() => {
    console.log("errorFlagCount => ", errorFlagCount);
    if (errorFlagCount > 3) {
      // setErrorCount(errorFlagCount);
      setErrorPopup(true);
    } else {
      setErrorPopup(false);
    }
  }, [errorFlagCount]);

  // set from ios device or not
  useEffect(() => {
    if (searchParams?.get("from") === "ios") {
      dispatch(userSigupAction?.setIsFromIosDevice(true));
    } else {
      dispatch(userSigupAction?.setIsFromIosDevice(false));
    }
  }, [dispatch, searchParams]);

  // County Details
  useEffect(() => {
    if (getObjLength(countyData)) {
      if (!countyData?.success) {
        dispatch(propertyRiskManagementAction.fetchCountyData({}));
        navigate("/404");
      }
    }
  }, [countyData]);

  console.log(signupStep, "session state==>", resumeCount + 1 > 0);
  console.log(parseInt(errorCount) >= 3, "error count==>", errorCount);
  return (
    <>
      <Box className="text-end mt-3 mb-0 mx-5">
        {signupStep === 0 && (
          <Button
            // variant="contained"
            className="text-capitalize fw-600 fs-16"
            onClick={() => resumeProcess()}
          >
            Resume Process ?
          </Button>
        )}
      </Box>
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          mdOffset={2}
          md={8}
          lgOffset={2}
          lg={8}
          xlOffset={2}
          xl={8}
          className="stepper-head"
        >
          <Box
            className="signIn-box pt-2"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {!btnCLicked ? (
              <Box>
                <CssBaseline />
                <Box
                  sx={{
                    bgcolor: "white",
                    width: "100%",
                    boxShadow: "0px 30px 70px -10px rgba(52, 75, 91, 0.29)",
                  }}
                >
                  <Typography
                    align="center"
                    variant="subtitle1"
                    className="py-1 formHeader"
                  >
                    <LockIcon sx={{ fontSize: 16 }} className="me-1" />
                    Secure connection
                  </Typography>
                  <Box className="accountBoxWrapper">
                    <Typography
                      align="center"
                      variant="h1"
                      className="title_texts"
                    >
                      Create your Account
                    </Typography>
                    <Typography
                      align="center"
                      variant="body1"
                      sx={{ marginBottom: "1rem !important" }}
                    >
                      We just need to gather some information to set up your
                      digital profile.
                    </Typography>
                    <SignInForm
                      onBtnClick={createAcc}
                      signupStep={setSignupStep}
                      reduceErrorCount={reduceErrorCount}
                      errorPopup={errorPopup}
                      resumePopup={resumePopup}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <HorizontalLinearStepper
                  resumeCount={activeStep}
                  signupStep={setSignupStep}
                  reduceErrorCount={reduceErrorCount}
                  errorPopup={errorPopup}
                  isIOS={isIOS}
                />
              </>
            )}
            {btnCLicked && (
              <Box py={5} className="mobileApp_Wrapper text-center zendesk-spacing">
                <Typography
                  variant="body1"
                  mb={1}
                  className={isFromIosDevice ? "d-none" : ""}
                >
                  Move faster with the mobile app
                </Typography>
                <div
                  className={
                    isFromIosDevice ? "d-none" : "d-flex justify-content-around"
                  }
                >
                  <img
                    src={GooglePlayeStore}
                    onClick={() => window.open(playStoreURL, "_blank")}
                    alt="GooglePlayStore"
                    style={{
                      width: "100px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  />
                  <img
                    src={AppStore}
                    onClick={() => window.open(appStoreURL, "_blank")}
                    alt="AppStore"
                    style={{ width: "100px", height: "30px" }}
                  />
                </div>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {!isBiometric && resumeCount + 1 > 0 && (
        <AlertDialog
          openPopup={resumeCount + 1 > 0 ? true : false}
          onClose={false}
          type="Warning"
          mesage={"Do you want to resume from where you left."}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={() => {
                  setResumeCount(undefined);
                  setActiveStep(0);
                  dispatch(userSigupAction.clearResumeProcessData());
                  clearLocalStoarge("check_county");
                }}
              >
                Nope
              </Button>
              <Button
                className="popup_Btn success_popup_btn "
                onClick={resumetheFlow}
              >
                Yes
              </Button>
            </>
          }
        />
      )}
      {resumePopup && (
        <DialogBox
          width="sm"
          openPopup={resumePopup}
          setOpenPopup={setResumePopup}
          onClose={() => {
            setResumePopup(false);
            dispatch(userSigupAction.clearResumeProcessData());
          }}
          mesage={
            <ResumeProcess
              setOpenPopup={setResumePopup}
              resumetheFlow={resumetheFlow}
            />
          }
        />
      )}

      {/* {parseInt(errorCount) >= 2 && ( */}
      {/* {errorPopup && (
        <AlertDialog
          openPopup={errorPopup}
          onClose={true}
          type="Error"
          mesage={"Please contact to admin..."}
          buttons={
            <>
              <Button
                className="popup_Btn other_popup_btn m-2"
                onClick={reduceErrorCount}
              >
                Nope
              </Button>
            </>
          }
        />
      )} */}
      <Loader open={countyLoader} />
    </>
  );
};

export default UserSignup;
