import React, { useState } from "react";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import { Box, Typography } from "@mui/material";

import RealStoryDesign from "../common/RealStoryDesign";
import QuitClaimImg from "../../assets/SEO/QuitClaim.webp";
import HELOC_FraudImg from "../../assets/SEO/HELOC_Fraud.webp";
import MortgageImg from "../../assets/SEO/MortgageResponsive.webp";
import RealEstateImg from "../../assets/SEO/RealEstateScamResponsive.webp";
import ProactiveMonitorImg from "../../assets/SEO/ProactiveMonitorImg.webp";
import TitleInsuranceImg from "../../assets/SEO/TitleInsuranceResponsive.webp";
const Blogs = () => {
  const [isSwiping, setIsSwiping] = useState(false);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    cssEase: "ease-in-out", // Smooth easing transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 4000, // Slide changes every 3 seconds
    pauseOnHover: true, // Pause when the user hovers over the slider
    pauseOnFocus: true, // Pause when the slider gains focus
    swipeToSlide: true, // Smooth swipe to slide
    draggable: true, // Smooth dragging
    beforeChange: () => {
      setIsSwiping(true);
    },
    afterChange: () => {
      setIsSwiping(false);
    },
    onSwipe: () => {
      setIsSwiping(false);
    },
    swipeEvent: () => {
      setIsSwiping(true);
    },
  };

  const readMoreText = (text, link) => (
    <>
      <p className="para_texts">
        {text.slice(0, 175)}
        {text.length > 175 ? "..." : ""}
      </p>
      <span
        onClick={(event) => {
          event.stopPropagation();
        }}
        //  className="read-or-hide"
      >
        <p className="read_more_blog">
          <Link
            to={link}
            //target="_blank"
          >
            {" Read More >"}
          </Link>
        </p>
      </span>
    </>
  );

  return (
    <div className="blogs-content">
      <div className="first_section_div">
        <section className="d-flex first-blog-section">
          <Container fluid className="text-white main_container">
            <Row className=" justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={8}
                className="header_texts"
              >
                <>
                  <h1 className="fw-700 mb-3 main-text text-center">Blogs</h1>
                </>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="blog_title learn_ep_section">
          <Container fluid className="p-0 card_top">
            <Slider {...settings}>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={RealEstateImg}
                      className="h-100 w-100"
                      alt="Real Estate"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        Common Real Estate Scams and How to Handle Them
                      </Typography>

                      {readMoreText(
                        `The real estate industry can be lucrative, but it also
                          comes with its fair share of scams and frauds.
                          Homeowners should be aware of the common red flags in
                          real estate transactions to protect themselves from
                          falling victim to these schemes. Here are some of the
                          most common real estate scams and how to handle them
                          to keep your money and property safe.
                        `,
                        "/real-estate-scams"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={TitleInsuranceImg}
                      className="h-100 w-100"
                      alt="Title Insurance"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        What is Title Insurance and What Does It Cover
                      </Typography>
                      {readMoreText(
                        `Title insurance is an integral part of the home-buying
                        process, providing homeowners with protection and peace
                        of mind. But what exactly does title insurance cover?
                        Knowing how title insurance works can help homeowners
                        avoid unexpected complications. Let’s explore how this
                        coverage ensures a smooth homeownership experience.`,
                        "/does-title-insurance-cover-title-theft"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={MortgageImg}
                      className="h-100 w-100"
                      alt="Mortgage"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        Can Your Home Title Be Stolen If You Have a Mortgage
                      </Typography>
                      {readMoreText(
                        `Homeownership is a significant investment and a source
                        of pride for many individuals. However, there are
                        potential risks associated with it, including the threat
                        of title theft. Homeowners must understand these risks
                        and take proactive steps to protect their most valuable
                        assets. You should be aware of home title protection and
                        the potential implications of home title theft with a
                        mortgage.`,
                        "/can-your-home-title-be-stolen-if-you-have-a-mortgage"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={QuitClaimImg}
                      className="h-100 w-100"
                      alt="Quit Claim"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        Quitclaim Deed Fraud: What is it and how to prevent it
                      </Typography>
                      {readMoreText(
                        `Quitclaim deeds are a common tool in real estate for transferring
                        property interest, but they come with risks that can expose both
                        parties to fraud. As quitclaim deed fraud becomes more prevalent,
                        understanding how these documents work and how they can be misused
                        is crucial for property owners and buyers. Learn the ins and outs
                        of quitclaim deeds and how to prevent fraud when you use this type
                        of real estate transfer.`,
                        "/blog/quitclaim-deed-fraud"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={HELOC_FraudImg}
                      className="h-100 w-100"
                      alt="HELOC Fraud"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        What is HELOC Fraud and ways to protect yourself
                      </Typography>
                      {readMoreText(
                        `Homeowners face the risk of HELOC fraud, a type of mortgage scam
                        that can drain home equity without the owner's knowledge.
                        Understanding this threat is essential for anyone looking to
                        protect their assets. Breaking down HELOC fraud, explaining how
                        these scams operate, and offering strategies to safeguard your
                        home equity can help. Learn the difference between a HELOC and a
                        home equity loan, and discover how fraudsters use tactics like
                        deed theft and identity theft.`,
                        "/blog/heloc-fraud"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
              <Row className="text-start blog_card">
                <Col xs={12} sm={12} md={6} className="p-0">
                  <Box className="m-0 markgroup_img">
                    <img
                      src={ProactiveMonitorImg}
                      className="h-100 w-100"
                      alt="Proactive Monitor"
                    />
                  </Box>
                </Col>
                <Col xs={12} sm={12} md={6} className="">
                  <Box className="blog_content">
                    <>
                      <Typography className="title mb-4 d-flex text-start text-capitalize">
                        Proactive steps on how to monitor your home title
                      </Typography>
                      {readMoreText(
                        `Protecting your home title from fraud is a necessary task that
                        property owners must regularly complete. As one of your most
                        valuable assets, your home faces potential risks from title fraud
                        that can compromise security. Whether you’re a first-time
                        homeowner or a seasoned property owner, you need to stay vigilant
                        about your home title and protect your investment. Discover
                        practical strategies for monitoring your home title, including
                        using services like EquityProtect to effectively detect
                        unauthorized activity and protect your property from fraudulent
                        claims.`,
                        "/blog/proactive-steps-monitor-home-title"
                      )}
                    </>
                  </Box>
                </Col>
              </Row>
            </Slider>
          </Container>
        </section>
      </div>

      <section className="story_section">
        <Container fluid className="mx-150">
          <div>
            <RealStoryDesign
              subTitle="hide"
              title="Other articles"
              IsGrid={true}
              readMoreClass="read_more_blog"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Blogs;
