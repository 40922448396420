import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Button } from "@mui/material";

import "./PropertyRiskReport.scss";

const ProtectPropertySection = ({
  setSubscribeModal = () => {},
  getProtctedBtn = () => {},
}) => {
  return (
    <section className="protect_comparison_section ">
      <Container fluid className="protect_comparison_container pb-0">
        <Box className="">
          <h3 className="protect_comp_title ">
            What can you do to protect your property from FRAUD?
          </h3>
          <Row className="mt-4 mb-2 text-start comp_points">
            <Col
              xs={12}
              sm={10}
              md={9}
              lg={5}
              // xl={6}
              className="comp_content_spacing border1"
            >
              <div className="comp_content">
                <p className="comp_title">Free monitoring</p>
                <p className="comp_desc ">
                  To help safeguard your property, we recommend using{" "}
                  <span className="ep_texts">EquityProtect</span>{" "}
                  <span className="other_texts">Equity Guard</span>. Unlike our
                  competitors who charge $240 per year for similar services,
                  EquityProtect offers ABSOLUTELY FREE monitoring. This service
                  will notify you as soon as we detect any changes in the
                  ownership of your property.
                </p>
                <p className="comp_desc">
                  <em>
                    Important Note: While{" "}
                    <span className="ep_texts">EquityProtect</span>{" "}
                    <span className="other_texts">Equity Guard</span> will alert
                    you to any changes, it does not prevent the crime from
                    occurring. It simply notifies you that a change has taken
                    place so you can take immediate.
                  </em>
                </p>
              </div>
              <Button
                className="start_monitoring_button button1"
                onClick={() => {
                  setSubscribeModal(true);
                }}
              >
                Start monitoring
              </Button>
            </Col>
            <Col
              xs={12}
              sm={10}
              md={9}
              lg={5}
              // xl={6}
              className="comp_content_spacing border2"
            >
              <div className="comp_content">
                <p className="comp_title">Complete protection</p>
                <p className="comp_desc">
                  <span className="other_texts">
                    For the most complete protection available on the market
                    today, consider adding{" "}
                    <span className="ep_texts">EquityProtect</span> to your
                    property.
                  </span>
                </p>
                <p className="comp_desc">
                  We do what others don't—we protect you from the fraud
                  occurring. For less than what others charge to merely monitor,{" "}
                  <span className="ep_texts">EquityProtect</span> will{" "}
                  <span className="fw-700">STOP</span> the crime from happening
                  to you. Click below to learn more and start getting protected
                  today.{" "}
                </p>
              </div>

              <Button
                className="get_protected button1"
                onClick={getProtctedBtn}
              >
                Get protected
              </Button>
            </Col>
          </Row>
        </Box>
      </Container>
    </section>
  );
};

export default ProtectPropertySection;
