import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

import useTimer from "../hooks/useTimer";
import { Loader } from "../common/loader";
import DialogBox from "../common/DialogBox";
import AlertDialog from "../common/AlertDialog";
import vector1 from "../../assets/SEO/Vector1.png";
import vector2 from "../../assets/SEO/Vector2.png";
import vector3 from "../../assets/SEO/Vector3.png";
import vector4 from "../../assets/SEO/Vector4.png";
import support from "../../assets/SEO/support.webp";
import pana from "../../assets/properties/pana.png";
import money_back from "../../assets/SEO/dollar.webp";
import pana2 from "../../assets/properties/pana2.png";
import vector from "../../assets/properties/Vector.png";
import bro_hacker from "../../assets/SEO/bro_hacker.webp";
import certificate from "../../assets/SEO/certificate.webp";
import check_img from "../../assets/icons/checkmark_blk.png";
import FreeMonitoringUserForm from "./FreeMonitoringUserForm";
import RiskoMeterChart from "../common/charts/RiskoMeterChart";
import useClearLocalStorage from "../hooks/useClearLocalStorage";
import { currencyFormatter, getObjLength } from "../helpers/utils";
import InvestorPropertiesListTable from "./InvestorPropertiesListTable";
import HolidayVillage from "../../assets/properties/holiday_village.webp";
import CommonFourthSection from "../homeTitleTheftProtection/CommonFourthSection";
import ProtectionCTASection from "../homeTitleTheftProtection/ProtectionCTASection";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "./ReportResultsInvestor.scss";

let columns = [
  {
    key: 1,
    id: "sr number",
    disablePadding: true,
    label: "",
    value: "address",
    minWidth: 30,
    isSelecetAll: true,
  },

  {
    key: 2,
    id: "address",
    disablePadding: true,
    label: "Address",
    value: "address",
    minWidth: 120,
  },
  {
    key: 3,
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 80,
  },
  {
    key: 4,
    id: "state",
    disablePadding: true,
    label: "State",
    value: "state",
    minWidth: 80,
  },
  {
    key: 5,
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 80,
  },
  {
    key: 6,
    id: "owner",
    disablePadding: true,
    label: "Owner",
    value: "owner",
    minWidth: 120,
  },
  {
    key: 7,
    id: "APN",
    disablePadding: true,
    label: "APN",
    value: "apn_number",
    minWidth: 100,
  },
  {
    key: 8,
    id: "Free Monitoring",
    disablePadding: true,
    label: "Free Monitoring",
    value: "free_onitoring",
    minWidth: 120,
  },
  {
    key: 9,
    id: "risk_score",
    disablePadding: true,
    label: "Risk Score",
    value: "risk_score",
    minWidth: 80,
  },
  {
    key: 10,
    id: "action",
    disablePadding: true,
    label: "",
    value: "action",
    minWidth: 70,
  },
];

const ReportResultsInvestor = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //initializes the timer states for hook
  const { timer, startTimer, stopTimer, formatTimeForResendOTP } = useTimer(10);
  //initializes the clear Localstorage states for hook
  const { clearLocalStorageValues } = useClearLocalStorage();

  const [isValidUUID, setIsValidUUID] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [isSelectePropertyModal, setIsSelectePropertyModal] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [isAddUseDetails, setIsAddUseDetails] = useState(false);
  const [selectedProeprtiesID, setSelectedProeprtiesID] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  //console.log("uuid-->", uuid);

  // store data
  const {
    investorReportResultData,
    investorReportResultsLoader,
    investorMonitoringData,
    optionType,
  } = useSelector((state) => state?.propertyRiskManagement);

  const closeInvestorsModal = () => {
    dispatch(propertyRiskManagementAction.fetchInvestorReportResultData({}));
    setIsValidUUID(false);
    stopTimer();
    navigate(`/`);
  };

  const callActionAPIs = () => {
    let URL = `investor/enable-property-monitoring/${investorReportResultData?.data?.id}/enable`;
    let data = { property_ids: selectedProeprtiesID };
    if (optionType === "premium_monitoring") {
      URL = `investor/update-property/${investorReportResultData?.data?.id}`;
      data = { ...data, hold_for_signup: 1 };
    }
    if (selectedProeprtiesID?.length > 0) {
      dispatch(
        propertyRiskManagementAction.getInvestorMonitoringData({
          url: URL,
          data: data,
        })
      );
    } else {
      if (optionType === "premium_monitoring") {
        dispatch(propertyRiskManagementAction.setOptionType(null));
        if (!investorReportResultData?.data?.user) {
          navigate(`/create-account`);
        } else {
          navigate(`/login`);
        }
      }
    }

    closeModal();
    console.log(optionType, "Selcted properties=>", selectedProeprtiesID);
  };

  const closeInvestorMonitoringDataModal = () => {
    if (investorMonitoringData?.success) {
      setSelectedProperties([]);
      setSelectedProeprtiesID([]);
      dispatch(propertyRiskManagementAction.fetchInvestorReportResultData({}));
      ListAPIcall();
      dispatch(propertyRiskManagementAction.resetInvestorFormDetails({}));
    } else {
      if (!investorReportResultData?.data?.email) {
        setIsAddUseDetails(true);
      }
      setIsSelectePropertyModal(true);
    }
    if (optionType !== "premium_monitoring") {
      dispatch(propertyRiskManagementAction.setOptionType(null));
    }
    dispatch(propertyRiskManagementAction.fetchInvestorMonitoringData({}));
  };

  const closeModal = () => {
    setIsSelectePropertyModal(false);
    setIsAddUseDetails(false);
    if (optionType !== "premium_monitoring")
      dispatch(propertyRiskManagementAction.setOptionType(null));
  };

  const _BulkFreeMonitoring = () => {
    if (selectedProperties?.length > 0) {
      let data = selectedProperties?.map((data) =>
        !data?.free_subscription ? data?.id : null
      );
      data = data?.filter((e) => e !== null);

      setSelectedProeprtiesID(data);
    }
    setIsSelectePropertyModal(true);
    dispatch(propertyRiskManagementAction.setOptionType("free_monitoring"));
  };

  const _BulkPremiumMonitoring = () => {
    if (selectedProperties?.length > 0) {
      let data = selectedProperties?.map((data) =>
        !data?.hold_for_signup ? data?.id : null
      );
      data = data?.filter((e) => e !== null);

      setSelectedProeprtiesID(data);
      dispatch(
        propertyRiskManagementAction.selectedPropertiesForPremiumMonitoring(
          // selectedProperties?.filter((e) => !e?.hold_for_signup)
          selectedProperties
        )
      );
    }
    setIsSelectePropertyModal(true);
    dispatch(propertyRiskManagementAction.setOptionType("premium_monitoring"));
  };

  const ListAPIcall = () => {
    dispatch(
      propertyRiskManagementAction.getInvestorReportResultData({
        url: `get-investor/${uuid}`,
        data: {},
      })
    );
    setIsLoader(true);
  };
  useEffect(() => {
    if (uuid) {
      ListAPIcall();
    }
  }, []);

  useEffect(() => {
    if (getObjLength(investorReportResultData)) {
      if (
        investorReportResultData?.success &&
        optionType === "premium_monitoring"
      ) {
        clearLocalStorageValues();
        console.log("logss-->1", optionType);
        localStorage.setItem("investor_id", investorReportResultData?.data?.id);
        navigate(`/create-account`);
        dispatch(propertyRiskManagementAction.setOptionType(null));
        dispatch(propertyRiskManagementAction.fetchInvestorMonitoringData({}));
        dispatch(propertyRiskManagementAction.resetInvestorFormDetails({}));
      }
      if (!investorReportResultData?.success) {
        startTimer(10);
        setIsValidUUID(true);
      }
      setIsLoader(false);
    }
  }, [investorReportResultData]);

  useEffect(() => {
    if (
      getObjLength(investorReportResultData) &&
      !investorReportResultData?.success &&
      timer === 0
    ) {
      closeInvestorsModal();
    }
  }, [timer]);

  useEffect(() => {
    const filteredColumns = columns.filter(
      (column) => column?.key !== 1 && column?.key !== 10
    );
    setColumnsList(filteredColumns);
  }, []);

  const investoraData = investorReportResultData?.success
    ? investorReportResultData?.data
    : {};

  useEffect(() => {
    if (getObjLength(investorMonitoringData)) {
      closeModal();
      if (
        investorMonitoringData?.success &&
        optionType === "premium_monitoring"
      ) {
        ListAPIcall();
      }
    }
  }, [investorMonitoringData]);

  return (
    <>
      <Box className="report-results-investor">
        <section className="top_section">
          <Container fluid>
            <Row>
              <Col className="">
                <div className="PRA__block">
                  <Typography className="PRA__header-text">
                    Property Risk Assessment
                  </Typography>
                  {/* <Typography className={"PRA__para-text"}>
                    We have identified the following properties in your
                    portfolio.  Using our advanced, proprietary system, we have
                    conducted a thorough risk analysis on each one and assigned
                    a corresponding risk level to help you understand potential
                    vulnerabilities.
                  </Typography> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`main_section`}>
          <Container fluid>
            <Grid container alignItems="center" className="summary-card">
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                //   className="mt-2"
                className="left-section"
                order={{ xs: 2, sm: 2, md: 1 }}
              >
                <Box className="section1">
                  <Typography
                    variant="h6"
                    className="personal_info text-capitalize mb-2"
                  >
                    Summary
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className=""
                  >
                    <Typography variant="h5" className="investor-name">
                      Hi, {investoraData?.full_name || "there!"}
                    </Typography>
                    <Box className="property-stats">
                      <Stack
                        direction={"row"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="property-count"
                      >
                        <div className="pe-2 pl-1 d-flex align-items-center">
                          <img
                            src={HolidayVillage}
                            alt="HolidayVillage"
                            className="pe-2 pl-0"
                          />
                          {investorReportResultData?.count
                            ? investorReportResultData?.count > 1
                              ? `${investorReportResultData?.count} Properties`
                              : `${investorReportResultData?.count} Property`
                            : "Your Properties"}
                        </div>
                        {/* <div className="px-2 border-left">
                          <img src={Contract} alt="Contract" className="px-2" />
                          24 Records
                        </div> */}
                      </Stack>
                    </Box>
                  </Stack>
                  <Typography variant="body1" className="description py-2">
                    We have identified the following properties in your
                    portfolio. Using our advanced, proprietary system, we have
                    conducted a thorough risk analysis on each one and assigned
                    a corresponding risk level to help you understand potential
                    vulnerabilities.
                  </Typography>

                  <Box className="alert-section pt-3">
                    {parseInt(investoraData?.high_count) > 0 && (
                      <Button disabled className="alert critical-alert">
                        <ErrorOutlineIcon />
                        &nbsp;{investoraData?.high_count || 0} Critical Alerts
                      </Button>
                    )}
                    {parseInt(investoraData?.medium_high_count) +
                      parseInt(investoraData?.medium_count) >
                      0 && (
                      <Button disabled className="alert medium-alert">
                        <ErrorOutlineIcon />
                        &nbsp;
                        {investoraData?.medium_count +
                          investoraData?.medium_high_count || 0}{" "}
                        Medium Alerts
                      </Button>
                    )}
                    {parseInt(investoraData?.low_count) > 0 && (
                      <Button disabled className="alert medium-low-alert">
                        <ErrorOutlineIcon />
                        &nbsp;{investoraData?.low_count || 0} Low Alerts
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="below_divider"
                order={{ xs: 3, sm: 3 }}
              >
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={3.5}
                xl={3}
                className="border-left middle-section"
                order={{ xs: 5, sm: 5, md: 4 }}
              >
                <Box className="chart-section d-flex justify-content-center align-items-center h-75">
                  <RiskoMeterChart chart_status={investoraData?.risk_level} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2.5}
                xl={3}
                className="right-section"
                order={{ xs: 4, sm: 4, md: 4 }}
              >
                <Box className="property-details position-relative text-center">
                  <Typography variant="body1" className="value_text">
                    All Properties Value
                  </Typography>
                  <Typography className="approx_price">
                    {`${currencyFormatter(investoraData?.property_value || 0)}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="investor_table_section">
          <Box className="investor_table">
            {selectedProperties?.length > 0 && (
              <Stack
                direction={{ sm: "row", xs: "column" }}
                justifyContent={"end"}
                alignItems={{ sm: "center", xs: "end" }}
                className="mb-3"
              >
                <Box className="">
                  <Button
                    className="success-btn  mx-2 admin_buttons"
                    onClick={_BulkFreeMonitoring}
                  >
                    Free Monitoring
                  </Button>
                  <Button
                    className="success-btn  mx-2 admin_buttons"
                    onClick={_BulkPremiumMonitoring}
                  >
                    Premium Subscription
                  </Button>
                </Box>
              </Stack>
            )}
            {!isValidUUID ? (
              <InvestorPropertiesListTable
                setSelectedProperties={setSelectedProperties}
                selectedProperties={selectedProperties}
                selectedProeprtiesID={selectedProeprtiesID}
                isActionButton={true}
                rows={investoraData?.properties}
                // rows={rows}
                columns={columns}
                owner_name={investoraData?.full_name}
                setSelectedProeprtiesID={setSelectedProeprtiesID}
              />
            ) : (
              <AlertDialog
                type={"Error"}
                openPopup={true}
                onClose={true}
                onCloseFun={closeInvestorsModal}
                mesage={
                  <p>
                    {investorReportResultData?.data?.message ||
                      "Something went wrong"}
                    <br />
                    <span>
                      You will be taken to the homepage shortly{" "}
                      <b>({formatTimeForResendOTP(timer)})</b>. For additional
                      assistance, kindly contact our support team.
                    </span>
                  </p>
                }
              />
            )}
          </Box>
        </section>

        <div className="title-protection-real-estate-investors">
          <CommonFourthSection
            reversColumn={true}
            image={bro_hacker}
            image_mobile={bro_hacker}
            title={"The ONLY Company That PREVENTS Deed And Home Title Theft"}
            content={[
              <>
                As a multi-property investor, your real estate portfolio is one
                of your most valuable assets. But did you know that
                <b> Title Deed Fraud</b> and <b>Home Stealing</b> are rapidly
                growing threats, with over <b>$1 billion</b> lost to these
                crimes since 2020? Criminals are increasingly targeting
                non-owner-occupied properties like yours because they know
                investors may not notice fraudulent activity until it's too
                late.
              </>,
            ]}
          />
        </div>

        <section className="title-protection-real-estate-investors">
          <Container fluid className="fifth-section">
            <ProtectionCTASection
              title_container2={"How EquityProtect Safeguards Your Investments"}
              content_containet2={
                <span>
                  Most protection services merely monitor and alert you after a
                  fraudulent change occurs. But by then, the damage may already
                  be done.{" "}
                  <b>
                    <a
                      href={"/"}
                      target="_blank"
                      rel="noreferrer"
                      className="link__a"
                    >
                      EquityProtect
                    </a>
                    is different
                  </b>
                  . We’re the <b>only service</b> that actively prevents
                  unauthorized changes to your property titles before they can
                  happen.
                </span>
              }
              buttons={false}
              is_real_story={false}
              classes={"ep__safeguard"}
            />
          </Container>
        </section>

        <section className="pt-5 section-padding">
          <Container fluid className="mx-150">
            <Grid
              margin={"0 auto"}
              container
              spacing={1}
              justifyContent="space-around"
            >
              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <p className="notify_header">What we offer</p>
                <h3 className="sub_header">
                  Key Benefits for Multi-Property Investors
                </h3>
                <p>
                  We take pride in being at the forefront of creating
                  Multi-Factor Authentication technology and monitoring services
                  to protect you from this terrible new crime.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <span className="text-description">
                    <b>Prevention Over Detection</b>
                    <br />
                    <span className="protection_desc">
                      We block fraudulent activity before it happens, not after.
                    </span>
                  </span>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <span className="text-description">
                    <b>Portfolio-Wide Protection</b>
                    <br />
                    <span className="protection_desc">
                      Whether you own 2 or 200 properties, EquityProtect
                      provides comprehensive risk management.
                    </span>
                  </span>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <span className="text-description">
                    <b>Peace of Mind</b>
                    <br />
                    <span className="protection_desc">
                      Focus on growing your investments, knowing your properties
                      are safeguarded.
                    </span>
                  </span>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <span className="text-description">
                    <b>Real-Time Protection</b>
                    <br />
                    <span className="protection_desc">
                      Our system stops any unauthorized changes to your title
                      records.
                    </span>
                  </span>
                </Stack>
                <Stack direction={"row"} className="mt-3">
                  <img src={check_img} className="check_img" alt="check" />
                  <span className="text-description">
                    <b>Alerts You Can Trust</b>
                    <br />
                    <span className="protection_desc">
                      Receive timely updates when legitimate title activities
                      occur, ensuring transparency and security.
                    </span>
                  </span>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section>
          <Container fluid className="px-3 px-md-5">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <div className="card text-center private_guard_bg">
                  <div className="card-body p-0">
                    <img src={vector4} className="vector_4" alt="Vector" />
                    <img src={vector3} className="vector_3" alt="Vector" />
                    <img src={vector2} className="vector_2" alt="Vector" />
                    <img src={vector1} className="vector_1" alt="Vector" />
                    <img src={vector} className="vector" alt="Vector" />
                    <img src={pana} className="pana" alt="pana" />
                    <img src={pana2} className="pana2" alt="pana" />
                    <p className="private_guard_title card-title text-white text-center">
                      Don’t Let Title Fraud Undermine Your Investments
                    </p>
                    <p>
                      Protect your properties today with EquityProtect and enjoy
                      the confidence that comes with knowing your investments
                      are secure.
                    </p>
                  </div>
                  <div className="mt-2">
                    <Link
                      className=" text-white text-decoration-none"
                      to="/create-account"
                    >
                      <Button className="button2">Find Out More</Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="mobile-secions-pt-0"
          style={{
            position: "relative",
            background: "#F6F9FC",
            // marginBottom: "150px",
          }}
        >
          <Container fluid className={`last-container`}>
            <Row className={`mt-4 text-center pp_points`}>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing "
              >
                <img
                  className="mb-3"
                  src={certificate}
                  alt="US PATENTED TECHNOLOGY"
                />
                <p className="protection_title">US PATENTED TECHNOLOGY</p>
                <p className="protection_desc pe-0">
                  Criminals can literally remove someone from their home's
                  title. Then they take out loans against the home and leave its
                  owner in debt.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing"
              >
                <img
                  className="mb-3"
                  src={support}
                  alt="24/7 US-BASED PREMIUM SUPPORT"
                />
                <p className="protection_title" style={{ paddingTop: "5px" }}>
                  24/7 US-BASED PREMIUM SUPPORT
                </p>
                <p className="protection_desc pe-0">
                  EquityProtect will continuously monitor for a home’s address
                  to be listed on popular vacation rental and classified ad
                  sites.
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                className="content-spacing"
              >
                <img
                  className="mb-3"
                  src={money_back}
                  alt="60-DAY MONEY BACK GUARANTEE"
                />
                <p className="protection_title">60-DAY MONEY BACK GUARANTEE</p>
                <p className="protection_desc pe-0">
                  Instant alerts if we detect tampering with a title or mortgage
                  - we’re the hawk keeping a close watch at all times.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </Box>

      {isSelectePropertyModal && (
        <DialogBox
          width="lg"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          title={
            <p className="me-5">
              {optionType === "free_monitoring"
                ? "Do you want to proceed with Free Monitoring using the properties below?"
                : "Do you want to continue to start a Premium Subscription with the properties below?"}
            </p>
          }
          openPopup={isSelectePropertyModal}
          onClose={() => {
            dispatch(propertyRiskManagementAction.resetInvestorFormDetails({}));
            closeModal();
          }}
          close
          content={
            <>
              <Box className="my-2 ">
                {isAddUseDetails ? (
                  <FreeMonitoringUserForm
                    handleClose={closeModal}
                    selectedPropertIds={selectedProeprtiesID}
                    owner_name={investoraData?.full_name}
                    classes={"pt-4"}
                    optionType={optionType}
                  />
                ) : (
                  <InvestorPropertiesListTable
                    investoraData={investoraData}
                    isActionButton={false}
                    rows={selectedProperties}
                    columns={columnsList}
                    owner_name={investoraData?.full_name}
                    selectedProeprtiesID={selectedProeprtiesID}
                    optionType={optionType}
                  />
                )}
                {!isAddUseDetails && (
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    justifyContent={"end"}
                    alignItems={{ sm: "center", xs: "end" }}
                    className=""
                  >
                    <Box className="investor_table_section">
                      <Button
                        className="popup_Btn other_popup_btn  mx-2 mt-3 admin_buttons"
                        onClick={closeModal}
                      >
                        {selectedProeprtiesID?.length === 0 ? "CLose" : "  No"}
                      </Button>
                      {((selectedProeprtiesID?.length > 0 &&
                        optionType === "free_monitoring") ||
                        optionType === "premium_monitoring") && (
                        <Button
                          className="popup_Btn success_popup_btn mx-2 mt-3 admin_buttons"
                          onClick={() => {
                            if (investorReportResultData?.data?.email) {
                              callActionAPIs();
                            } else {
                              setIsAddUseDetails(true);
                            }
                          }}
                        >
                          Yes
                        </Button>
                      )}
                    </Box>
                  </Stack>
                )}
              </Box>
            </>
          }
        />
      )}

      {getObjLength(investorMonitoringData) ? (
        <AlertDialog
          type={investorMonitoringData?.success ? "Success" : "Error"}
          openPopup={
            investorMonitoringData?.success &&
            optionType === "premium_monitoring"
              ? false
              : true
          }
          onClose={true}
          onCloseFun={closeInvestorMonitoringDataModal}
          mesage={
            investorMonitoringData?.success
              ? investorMonitoringData?.message
              : investorMonitoringData?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      <Loader open={investorReportResultsLoader || isLoader} />
    </>
  );
};

export default ReportResultsInvestor;
