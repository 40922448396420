import React, { useCallback, useEffect, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import IosShareIcon from "@mui/icons-material/IosShare";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import ViewEditUserPage from "./ViewEditUserPage";
import AlertDialog from "../../common/AlertDialog";
import DownloadCSV from "../exportCSV/downloadCSV";
import PropertyUsersListTable from "./PropertyUsersListTable";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import { exportCSVDetialsAction } from "../../../redux/slices/export-csv/exportcsvSlice";
import {
  COUNTRY_CODE,
  jsonLastStepOptions,
  PER_PAGE,
  ROLES,
} from "../../helpers/constants";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import { otherProvidersAction } from "../../../redux/slices/admin/other-providers/otherProvidersSlice";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import {
  decryptPayload,
  encryptPayload,
  getDateInYYYYMMDDFormat,
  getObjLength,
} from "../../helpers/utils";

import "./propertyUsers.scss";
import { useNavigate } from "react-router-dom";
import { adminDashboardAction } from "../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
const columns = [
  { key: 1, disablePadding: true, id: "no", label: "No.", minWidth: 50 },
  { key: 2, id: "name", disablePadding: true, label: "Name", minWidth: 115 },
  {
    key: 3,
    id: "email",
    disablePadding: true,
    label: "Username",
    minWidth: 250,
  },
  {
    key: 4,
    id: "phone",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 155,
  },
  { key: 5, id: "type", disablePadding: true, label: "Type", minWidth: 110 },
  {
    key: 6,
    id: "properties",
    disablePadding: true,
    label: "Properties",
    minWidth: 110,
  },
  {
    key: 7,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  {
    key: 8, // set this key as default for sorting
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  // {
  //   id: "notes",
  //   disablePadding: true,
  //   label: "Notes",
  //   minWidth: 80,
  // },
  // {
  //   id: "referral_user",
  //   disablePadding: true,
  //   label: "Referral User",
  //   minWidth: 120,
  // },
  // {
  //   id: "promo_code",
  //   disablePadding: true,
  //   label: "Promo Code",
  //   minWidth: 120,
  // },
  // {
  //   id: "json_last_step",
  //   disablePadding: true,
  //   label: "Last Step",
  //   minWidth: 140,
  // },
  // {
  //   id: "created_by",
  //   disablePadding: true,
  //   label: "Created By",
  //   minWidth: 130,
  // },
  {
    key: 9,
    id: "actions",
    disablePadding: true,
    label: "Actions",
    minWidth: 50,
  },
];

const filterUserType = [
  {
    label: "Customer",
    value: "customer",
  },
  {
    label: "Sub User",
    value: "sub_user",
  },
  {
    label: "Professional",
    value: "professional",
  },
  {
    label: "Service Provider",
    value: "service_provider",
  },
];

const PropertyUsersIndex = () => {
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];
  console.log(parseInt(userId), "url val== >>", queryString);
  const queryString2 = window.location.search;
  let paramString = queryString2?.split("?")[1]?.replace("from=", "");
  let paramString2 = paramString?.split("&");
  const redirect_from = paramString2?.[0];
  const user_id = paramString2?.[1]?.replace("user_id=", "");
  console.log(redirect_from, "Param==>", user_id);
  const is_subadmin = window.location.search;
  const subadmin_id = is_subadmin?.split("subadmin/")?.[1];

  let fromDashboard = window.location.search
    ?.split("?")[1]
    ?.replace("fromDashboard=", "");
  let tokenValue = fromDashboard?.split("&&");
  let tempUsersFilterData =
    JSON.parse(decryptPayload(localStorage.getItem("tempUsersFilterData"))) ||
    null;

  //when user opend the cards in new tab
  if (fromDashboard !== "users" && tokenValue?.[0] === "users") {
    tempUsersFilterData = JSON.parse(decryptPayload(tokenValue?.[1]) || null);
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertMsg, setAlertMsg] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [allTypes, setAllTypes] = useState("all");
  const [domainName, setDomainName] = useState();
  const [jsonLastStep, setJsonLastStep] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [value, setValue] = useState("");
  const [payload, setPayload] = useState({});
  const [isViewEditUserScreenOpen, setIsViewEditUserScreenOpen] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(8); // updated_at key for sorting
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [selectedStatusType, setSelectedStatusType] = useState("");
  const [missingContact, setMissingContact] = useState("");
  const [subAdminId, setSubAdminId] = useState();
  const [subAdminName, setSubAdminName] = useState();
  const [csv_Payload, setCSV_Payload] = useState([]);

  const {
    propertyUsersLoader,
    propertyUsersData,
    propertyUsersList,
    historyNoteLoader,
    internalAdminLoader,
    internalAdminDetails,
  } = useSelector((state) => state?.propertyUsers);

  const { domainListDataList } = useSelector((state) => state?.otherProviders);
  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );
  const { exportCsvLoader, exportCSVFileData } = useSelector(
    (state) => state.exportCSVDetails
  );
  const { usersModuleFilterData } = useSelector(
    (state) => state?.adminDashboard
  );

  const onSuccess = () => {
    resetFilterStates();
    setAlertMsg(false);
    dispatch(propertyUsersAction.clearData({}));
    setEditAlert(true);
    handleUsersListAPICall();
  };
  const pageChangeHandler = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  // To clear the URL prams and manage the API filters accordingly
  const handleURLParameters = () => {
    localStorage.removeItem("tempUsersFilterData");
    dispatch(adminDashboardAction.getUsersModuleFilterData({}));
    const currentUrl = window.location.href;
    // Check if the URL contains the query parameter 'abcd'
    if (currentUrl?.includes(fromDashboard)) {
      // Remove the query parameter
      const newUrl = currentUrl?.replace(`?fromDashboard=${fromDashboard}`, "");

      // Use react-router-dom to navigate to the new URL
      navigate(newUrl?.replace(window.location.origin, ""));
    }
  };

  const filterOptions = () => {
    handleURLParameters();
    if (
      value?.length > 0 ||
      selectedUserType ||
      selectedDateRange ||
      selectedStatusType ||
      subAdminId ||
      missingContact ||
      domainName
    ) {
      localStorage.removeItem("offset");
      setCallApi(true);
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: allTypes || undefined,
        value: value || undefined,
        from_date:
          (allTypes === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (allTypes === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          allTypes === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (allTypes === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        user_type: selectedUserType || undefined,
        has_no_email: missingContact || undefined,
        domain: domainName || undefined,
        status: selectedStatusType || undefined,
        limit: PER_PAGE,
        offset: 0,
        admin_id: subAdminId,
      }));
    }
  };

  const resetData = () => {
    handleURLParameters();
    localStorage.removeItem("offset");
    setAllTypes("all");
    setValue("");
    setDomainName("");
    setSelectedDateRange(null);
    setSelectedUserType("");
    setSelectedDateType("created_at");
    setSelectedStatusType("");
    setSubAdminId();
    setMissingContact();
    setSubAdminName();
    if (callApi) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setCallApi(false);
      setPage(1);
      setDirectionFlow(true);
      setSelectedColumn(8);
      fromDashboard = null;
    }
  };

  const resetFilterStates = () => {
    console.log("Resetting filter states");
    setAllTypes("all");
    setDomainName("");
    setValue("");
    setSelectedUserType("");
    setSelectedDateType("created_at");
    setSelectedStatusType("");
    setSubAdminId();
    setSubAdminName();
    fromDashboard = null;
    setMissingContact();
  };

  const clearLocalStorageData = () => {
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    localStorage.removeItem("county_data");
  };

  // get listing of the property user
  const getPropertyUserListingData = useCallback(
    (removeOffset) => {
      localStorage.removeItem("tempUsersFilterData");
      let FilteredData =
        JSON.parse(decryptPayload(localStorage.getItem("offset"))) || 0;
      let URL = "";
      if (userId?.length > 0) {
        URL = `admin/users-v1/${userId}`;
      } else {
        URL = `admin/users-v1`;
      }
      let data = {
        type: FilteredData?.type || payload?.type,
        value: FilteredData?.value || payload?.value,
        has_no_email: FilteredData?.has_no_email || payload?.has_no_email,
        admin_id: subadmin_id
          ? subadmin_id
          : FilteredData?.admin_id || payload?.admin_id,
        from_date: FilteredData?.from_date || payload?.from_date,
        to_date: FilteredData?.to_date || payload?.to_date,
        domain: FilteredData?.domain || payload?.domain,
        modified_from_date:
          FilteredData?.modified_from_date || payload?.modified_from_date,
        modified_to_date:
          FilteredData?.modified_to_date || payload?.modified_to_date,
        user_type: FilteredData?.user_type || payload?.user_type,
        limit: PER_PAGE,
        offset: FilteredData?.offset || payload?.offset || 0,
        status: FilteredData?.status || payload?.status,
        order_by_column:
          FilteredData?.order_by_column ||
          payload?.order_by_column ||
          "updated_at",
        sorting_order:
          FilteredData?.sorting_order || payload?.sorting_order || "desc",
      };
      setCSV_Payload(data);
      dispatch(
        propertyUsersAction.getPropertyUsersData({
          url: URL,
          data,
          token: true,
        })
      );
      // for display Tab UI whenever admin redirect from provider or logs module
      if (removeOffset) {
        setAllTypes(FilteredData?.type || "all");
        setValue(FilteredData?.value || "");
        setMissingContact(FilteredData?.has_no_email || "");
        setDomainName(FilteredData?.domain);
        if (FilteredData?.from_date && FilteredData?.to_date) {
          setSelectedDateRange({
            startDate: FilteredData?.from_date,
            endDate: FilteredData?.to_date,
          });
        } else {
          setSelectedDateRange(null);
        }
        setSelectedUserType(FilteredData?.user_type || "");
        setSelectedDateType("created_at");
        setSelectedStatusType(FilteredData?.status || "");
        // setSubAdminId(FilteredData?.admin_id || "");
        setJsonLastStep(FilteredData?.value || "");
        setCallApi(true);
        setPayload(FilteredData);
        if (Object.keys(FilteredData).length > 2) {
          setToggleFilterOptions(true);
        } else {
          setToggleFilterOptions(false);
        }
        localStorage.removeItem("offset");
      }
      if (FilteredData?.offset) {
        setPage(parseInt(FilteredData?.offset) / PER_PAGE + 1);
      }
    },
    [
      userId,
      dispatch,
      payload?.type,
      payload?.value,
      payload?.has_no_email,
      payload?.admin_id,
      payload?.from_date,
      payload?.to_date,
      payload?.domain,
      payload?.modified_from_date,
      payload?.modified_to_date,
      payload?.user_type,
      payload?.offset,
      payload?.status,
      payload?.order_by_column,
      payload?.sorting_order,
      subadmin_id,
    ]
  );

  // get listing of the property user
  const getPropertyUserListingAPI = (filterData) => {
    console.log("kk filter data -->", filterData);

    let URL = `admin/users-v1`;
    let data = {
      limit: PER_PAGE,
      offset: payload?.offset || 0,
      order_by_column: payload?.order_by_column || "updated_at",
      sorting_order: payload?.sorting_order || "desc",
    };
    if (getObjLength(filterData)) {
      data = { ...data, ...filterData };
    }
    console.log("KK Get property list-->", data);
    if (getObjLength(filterData)) {
      localStorage.setItem(
        "tempUsersFilterData",
        encryptPayload(JSON.stringify(data))
      );
    }
    setCallApi(true);
    dispatch(
      propertyUsersAction.getPropertyUsersData({
        url: URL,
        data,
        token: true,
      })
    );
  };

  // For manage API calls for menu actions componnets
  const handleUsersListAPICall = (action) => {
    if (
      (fromDashboard === "users" || tokenValue?.[0] === "users") &&
      (getObjLength(usersModuleFilterData) || getObjLength(tempUsersFilterData)) // to set API Call with the specific filter data
    ) {
      getPropertyUserListingAPI(usersModuleFilterData || tempUsersFilterData);
    } else {
      getPropertyUserListingData(action); // default API call with exising filter data
    }
  };

  // handle view or edit user button
  const handleViewEditUser = (row, isPropertyTab = false) => {
    if (isPropertyTab) {
      setActiveTab(2);
    } else {
      setActiveTab(1);
    }
    dispatch(propertyUsersAction.setSelectedProperty(row));
    setSelectedRow(row);
    setIsViewEditUserScreenOpen(!isViewEditUserScreenOpen);
  };

  const handleBackToUserListPage = () => {
    setIsViewEditUserScreenOpen(false);
    setSelectedRow(null);
    dispatch(propertyUsersAction.setSelectedProperty({}));
    dispatch(propertiesAction.setSelectedUserID(null));
    handleUsersListAPICall(true);
  };

  const handleExportCSV = () => {
    console.log(userId, "payload-->", csv_Payload);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    dispatch(
      exportCSVDetialsAction.exportCSVFile({
        url: "export-user-detail-csv",
        data: { ...csv_Payload, user_time_zone: userTimeZone },
        token: true,
      })
    );
  };

  const handleCloseDownloadModal = (action) => {
    dispatch(exportCSVDetialsAction.getExportCSVFile({}));
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  useEffect(() => {
    const filterData =
      (getObjLength(usersModuleFilterData) && usersModuleFilterData) ||
      (getObjLength(tempUsersFilterData) && tempUsersFilterData);
    if (
      (fromDashboard === "users" || tokenValue?.[0] === "users") &&
      getObjLength(filterData)
    ) {
      console.log("kk usersModuleFilterData==>", filterData);
      setToggleFilterOptions(true);
      // setAllTypes(filterData?.type);
      if (filterData?.status) {
        setSelectedStatusType(filterData?.status);
      }
      if (filterData?.user_type) {
        setSelectedUserType(filterData?.user_type);
      }
      if (filterData?.from_date && filterData?.to_date) {
        setSelectedDateRange({
          startDate: filterData?.from_date,
          endDate: filterData?.to_date,
        });
        setSelectedDateType("created_at");
      }
      if (filterData?.action === "newUsers") {
        setSelectedDateRange({
          startDate: filterData?.from_date,
          endDate: filterData?.to_date,
        });
      }
      getPropertyUserListingAPI(filterData);
    } else {
      getPropertyUserListingData(); // default API call
    }
  }, [dispatch, getPropertyUserListingData, fromDashboard]);

  useEffect(() => {
    if (getObjLength(propertyUsersList)) {
      if (propertyUsersList?.success) {
        // setLeadsData(propertyUsersList?.data);
      }
      setEditAlert(false);
      if (
        redirect_from === ROLES.provider ||
        redirect_from === ROLES.countyRecorder ||
        redirect_from === "logs"
      ) {
        let data = propertyUsersList?.data?.find(
          (i) => i.user_id === parseInt(user_id)
        );
        setSelectedRow(data);
        const newUrl = window.location.origin + window.location.pathname;
        handleViewEditUser(
          data,
          redirect_from === (ROLES.provider || ROLES.countyRecorder)
        );
        window.history.replaceState({}, document.title, newUrl);
        console.log("dat==>", data);
      }
    }
  }, [propertyUsersList]);

  useEffect(() => {
    if (getObjLength(propertyUsersData)) {
      setAlertMsg(true);
      if (propertyUsersData?.success) {
        setSuccessMsg(propertyUsersData?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(propertyUsersData?.data?.message);
      }
    }
  }, [dispatch, propertyUsersData]);

  useEffect(() => {
    clearLocalStorageData();
  }, []);

  useEffect(() => {
    console.log(is_subadmin, "subadmin_id==>", subadmin_id);

    if (is_subadmin && subadmin_id) {
      // setPayload((prevState) => ({
      //   ...prevState,
      //   admin_id: subadmin_id,
      // }));
      const subAdminFullName = internalAdminDetails?.data?.find(
        (subadmin) => subadmin.id === parseInt(subadmin_id)
      );
      setSubAdminName(subAdminFullName?.full_name);
    }
  }, [is_subadmin, subadmin_id]);

  useEffect(() => {
    console.log(
      !decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.provider),
      "1111->>",
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    );
    if (
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.provider
      ) &&
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    ) {
      dispatch(
        otherProvidersAction.domainListData({
          url: "admin/providers-list",
          data: {},
          token: true,
        })
      );
      dispatch(
        countyRecordersAction.registeredCountyList({
          url: `admin/counties`,
          data: {},
          token: true,
        })
      );
      dispatch(
        propertyUsersAction.internalAdminList({
          url: "admin/admins",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );

  const handleCountyChange = (event, newValue) => {
    const selectedCounty = registeredCountyListData?.data?.find(
      (county) => county.county_domain_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setValue(selectedCounty.id.toString());
      setDomainName();
    }
  };

  const handleInternalAdminChange = (event, newValue) => {
    const selectedSubadmin = internalAdminDetails?.data?.find(
      (subadmin) => subadmin.full_name === newValue
    );
    if (getObjLength(selectedSubadmin)) {
      setSubAdminId(selectedSubadmin.id.toString());
      setSubAdminName(selectedSubadmin.full_name);
      setDomainName();
    }
  };
  console.log("internalAdminDetails==>", internalAdminDetails);
  return (
    <>
      <>
        {isViewEditUserScreenOpen ? (
          <ViewEditUserPage
            selectedUser={selectedRow}
            backToUserListPageHandler={handleBackToUserListPage}
            activeTab={activeTab}
            offsetNum={
              getObjLength(payload)
                ? encryptPayload(JSON.stringify(payload))
                : null
            }
          />
        ) : (
          <Box>
            <Paper
              elevation={0}
              className=" d-flex justify-content-between"
              sx={{ background: "none" }}
            >
              <Box
                // className="w-100"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography variant="h6" className="card-title fw-600">
                  Property Users
                </Typography>
              </Box>
              {!userId && !county_admin && (
                <Box className="">
                  <Button
                    variant="contained"
                    className="success-btn d-none d-sm-block"
                    style={{
                      padding: "10px 30px",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      handleExportCSV();
                    }}
                  >
                    Export CSV
                  </Button>
                  <Button
                    className="success-btn d-flex d-sm-none"
                    variant="outlined"
                    onClick={() => {
                      handleExportCSV();
                    }}
                    startIcon={<IosShareIcon />}
                    sx={{ color: "#FFF" }}
                  >
                    Export
                  </Button>
                </Box>
              )}
            </Paper>

            {/* Filtering Component Section */}
            <Box
              id="filter"
              onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
            >
              <h3 className="filter-text">Filter your results</h3>
              <ArrowDropDownIcon
                className="filter-arrow"
                sx={{ ms: 1, fontSize: "20px" }}
              />
            </Box>

            {/* Filtering Options */}
            <Box
              sx={{
                overflow: "hidden",
                overflowX: "auto",
                mb: 1.5,
              }}
            >
              {toggleFilterOptions && (
                <>
                  {/* for mobile view */}
                  <Box className="d-block d-md-none">
                    <Grid
                      container
                      className="bg-white d-flex justify-content-around align-items-center px-4"
                      spacing={2}
                      pb={2}
                      pt={2}
                    >
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <Typography className="search-text">
                          Search By
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <FormControl className="filter-select-one w-100">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="All Types"
                            name="type"
                            onChange={(event) => {
                              setAllTypes(event.target.value);
                              setJsonLastStep("");
                              setValue("");
                              setSelectedUserType("");

                              setSelectedDateRange(null);
                              setSelectedStatusType("");
                              setSelectedDateType("created_at");
                            }}
                            value={allTypes}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {columns.map(
                              (cell) =>
                                cell.id !== "no" &&
                                cell.id !== "created_by" &&
                                cell.id !== "status" &&
                                cell.id !== "notes" &&
                                cell.id !== "properties" &&
                                cell.id !== "docs" &&
                                cell.id !== "actions" && (
                                  <MenuItem key={cell.id} value={cell.id}>
                                    {cell.label}
                                  </MenuItem>
                                )
                            )}
                            <MenuItem value={"status"}>User Status</MenuItem>
                            {!decryptPayload(
                              localStorage.getItem("roles")
                            )?.includes(ROLES.provider) &&
                              !county_admin && (
                                <MenuItem value={"domain"}>Provider</MenuItem>
                              )}
                            {!county_admin && (
                              <MenuItem value={"county"}>County</MenuItem>
                            )}
                            <MenuItem value={"referral_user"}>
                              Referral User
                            </MenuItem>
                            <MenuItem value={"promo_code"}>Promo Code</MenuItem>
                            <MenuItem value={"json_last_step"}>
                              Last Step
                            </MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      {allTypes !== "json_last_step" &&
                        allTypes !== "phone" &&
                        allTypes !== "created_at" &&
                        allTypes !== "county" &&
                        allTypes !== "updated_at" &&
                        allTypes !== "domain " &&
                        allTypes !== "type" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl
                              className="filter-search w-100"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                name="value"
                                onChange={(event) => {
                                  setValue(event.target.value);
                                }}
                                value={value}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      {allTypes === "json_last_step" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Last Step"
                              value={jsonLastStep}
                              onChange={(e) => {
                                setValue(e.target.value);
                                setJsonLastStep(e.target.value);
                              }}
                            >
                              {jsonLastStepOptions.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "county" && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="County List"
                              value={value}
                              onChange={(e) =>
                                setValue(e.target.value.toString())
                              }
                            >
                              {registeredCountyListData?.data?.map(
                                (county, key) => (
                                  <MenuItem value={county?.id}>
                                    {county?.county_domain_name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "type" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="User Type"
                              value={selectedUserType}
                              onChange={(e) => {
                                setValue("");
                                setSelectedUserType(e.target.value);
                                fromDashboard = null;
                              }}
                            >
                              {filterUserType?.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "domain" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Provider Name"
                              value={domainName}
                              onChange={(e) => {
                                setDomainName(e.target.value);
                              }}
                            >
                              {domainListDataList?.data?.map((data, key) => (
                                <MenuItem value={data.domain}>
                                  {data.domain || data?.custom_domain}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "phone" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Box
                            className={`phone-number-input bg-white filter w-100`}
                          >
                            <PhoneInput
                              className="bg-white"
                              international={false}
                              placeholder="Phone Number"
                              value={value}
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              onChange={(event) => {
                                setValue(event);
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                      {allTypes === "all" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status type"
                              value={selectedStatusType}
                              onChange={(e) => {
                                setSelectedStatusType(e.target.value);
                                fromDashboard = null;
                              }}
                            >
                              {[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                                { label: "Pending", value: "pending" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "all" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {(allTypes === "all" ||
                        allTypes === "created_at" ||
                        allTypes === "updated_at") && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-search w-100">
                            <CustomDateRangePicker
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <FormControl className="w-100">
                          <Button
                            type="submit"
                            onClick={filterOptions}
                            sx={mobileFilterResultStyle}
                          >
                            Filter Results
                          </Button>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Button
                          type="reset"
                          onClick={resetData}
                          sx={mobileFilterResetBtnStyle}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* for desktop view */}
                  <Card
                    className="d-none d-md-block filter-options-carddiv"
                    // id="filter-options-card"
                  >
                    <Grid container className="filter-options-box">
                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <div className="search-text mx-2">Search By</div>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="All Types"
                              name="type"
                              onChange={(event) => {
                                setAllTypes(event.target.value);
                                setValue("");
                                setJsonLastStep("");
                                setSelectedUserType("");
                                setSelectedDateRange(null);
                                setSelectedStatusType("");
                                setSubAdminId();
                                setSubAdminName();
                                setSelectedDateType("created_at");
                              }}
                              value={allTypes}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              {columns.map(
                                (cell) =>
                                  cell.id !== "no" &&
                                  cell.id !== "created_by" &&
                                  cell.id !== "status" &&
                                  cell.id !== "notes" &&
                                  cell.id !== "properties" &&
                                  cell.id !== "docs" &&
                                  cell.id !== "actions" && (
                                    <MenuItem key={cell.id} value={cell.id}>
                                      {cell.label}
                                    </MenuItem>
                                  )
                              )}
                              <MenuItem value={"status"}>User Status</MenuItem>
                              {!decryptPayload(
                                localStorage.getItem("roles")
                              )?.includes(ROLES.provider) &&
                                !county_admin && (
                                  <MenuItem value={"domain"}>Provider</MenuItem>
                                )}
                              {!county_admin && (
                                <MenuItem value={"county"}>County</MenuItem>
                              )}
                              {!decryptPayload(
                                localStorage.getItem("roles")
                              )?.includes(ROLES.provider) &&
                                !decryptPayload(
                                  localStorage.getItem("roles")
                                )?.includes(ROLES.countyRecorder) && (
                                  <MenuItem value={"subadmin"}>
                                    Sub Admin
                                  </MenuItem>
                                )}
                              <MenuItem value={"has_no_email"}>
                                User with MissingContact
                              </MenuItem>
                              <MenuItem value={"referral_user"}>
                                Referral User
                              </MenuItem>
                              <MenuItem value={"promo_code"}>
                                Promo Code
                              </MenuItem>
                              <MenuItem value={"json_last_step"}>
                                Last Step
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </Stack>
                      </Grid>

                      {allTypes !== "json_last_step" &&
                        allTypes !== "phone" &&
                        allTypes !== "created_at" &&
                        allTypes !== "updated_at" &&
                        allTypes !== "county" &&
                        allTypes !== "status" &&
                        allTypes !== "domain" &&
                        allTypes !== "subadmin" &&
                        allTypes !== "has_no_email" &&
                        allTypes !== "type" && (
                          <Grid item>
                            <FormControl
                              className="filter-search"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                name="value"
                                onChange={(event) => {
                                  setValue(event.target.value);
                                }}
                                value={value}
                                className={`${
                                  value ? "highlight_filter_field" : ""
                                }`}
                              />
                            </FormControl>
                          </Grid>
                        )}

                      {allTypes === "county" && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <Autocomplete
                              id="asynchronous-demo"
                              disableClearable
                              options={
                                registeredCountyListData?.data.map(
                                  (option) => option?.county_domain_name
                                ) || {}
                              }
                              onChange={handleCountyChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="County List"
                                  className={`${
                                    value ? "highlight_filter_field" : ""
                                  }`}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      {!decryptPayload(localStorage.getItem("roles"))?.includes(
                        ROLES.countyRecorder
                      ) &&
                        !decryptPayload(
                          localStorage.getItem("roles")
                        )?.includes(ROLES.provider) &&
                        (allTypes === "all" || allTypes === "subadmin") && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <Autocomplete
                                id="asynchronous-demo"
                                value={subAdminName ? subAdminName : ""}
                                disableClearable
                                options={
                                  internalAdminDetails?.data?.map(
                                    (option) => option?.full_name
                                  ) || {}
                                }
                                onChange={handleInternalAdminChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sub Admins List"
                                    className={`${
                                      subAdminName
                                        ? "highlight_filter_field"
                                        : ""
                                    }`}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}

                      {allTypes === "type" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="User Type"
                              value={selectedUserType}
                              className={`${
                                selectedUserType ? "highlight_filter_field" : ""
                              }`}
                              onChange={(e) => {
                                setValue("");
                                setDomainName();
                                setSelectedUserType(e.target.value);
                                fromDashboard = null;
                              }}
                            >
                              {filterUserType?.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "domain" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Provider Name"
                              value={domainName}
                              className={`${
                                domainName ? "highlight_filter_field" : ""
                              }`}
                              onChange={(e) => {
                                setDomainName(e.target.value);
                                setValue("");
                              }}
                            >
                              {domainListDataList?.data?.map((data, key) => (
                                <MenuItem
                                  value={data.domain || data?.custom_domain}
                                >
                                  {data.domain || data?.custom_domain}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "json_last_step" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Last Step"
                              value={jsonLastStep}
                              className={`${
                                jsonLastStep ? "highlight_filter_field" : ""
                              }`}
                              onChange={(e) => {
                                setValue(e.target.value);
                                setJsonLastStep(e.target.value);
                              }}
                            >
                              {jsonLastStepOptions.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "phone" && (
                        <Grid item>
                          <Box
                            className={`phone-number-input bg-white filter
                              ${value ? "highlight_filter_phone" : ""}
                          `}
                          >
                            <PhoneInput
                              className="bg-white"
                              international={false}
                              placeholder="Phone Number"
                              value={value}
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              onChange={(event) => {
                                setValue(event);
                              }}
                            />
                          </Box>
                        </Grid>
                      )}

                      {(allTypes === "all" || allTypes === "status") && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status type"
                              value={selectedStatusType}
                              className={`${
                                selectedStatusType
                                  ? "highlight_filter_field"
                                  : ""
                              }`}
                              onChange={(e) => {
                                setSelectedStatusType(e.target.value);
                                fromDashboard = null;
                              }}
                            >
                              {[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                                { label: "Pending", value: "pending" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "has_no_email" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="User with MissingContact"
                              value={missingContact}
                              onChange={(e) => {
                                setMissingContact(e.target.value);
                              }}
                            >
                              {[
                                { label: "True", value: "1" },
                                { label: "False", value: "0" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "all" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              className={`${
                                selectedDateType ? "highlight_filter_field" : ""
                              }`}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {(allTypes === "all" ||
                        allTypes === "created_at" ||
                        allTypes === "updated_at") && (
                        <Grid item>
                          <FormControl
                            className="filter-search position-relative"
                            variant="outlined"
                          >
                            <CustomDateRangePicker
                              sx={{ paddingRight: "0px" }}
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FormControl>
                            <Button
                              type="submit"
                              onClick={filterOptions}
                              className="filter-button"
                            >
                              Filter Results
                            </Button>
                          </FormControl>
                          <Button
                            type="reset"
                            onClick={() => {
                              resetData();
                            }}
                            className="reset-filters"
                          >
                            Reset
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </>
              )}
            </Box>
            <PropertyUsersListTable
              openPopup={editAlert}
              columns={columns}
              resetStates={resetFilterStates}
              page={page}
              setPage={setPage}
              pageChangeHandler={pageChangeHandler}
              callListingAPI={() => {
                handleUsersListAPICall();
              }}
              handleViewEditUser={handleViewEditUser}
              directionFlow={directionFlow}
              handleSorting={handleSorting}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              offsetNum={
                getObjLength(payload)
                  ? encryptPayload(JSON.stringify(payload))
                  : null
              }
              setCSV_Payload={setCSV_Payload}
            />
          </Box>
        )}
      </>

      {alertMsg && getObjLength(propertyUsersData) && (
        <>
          {getObjLength(propertyUsersData) && propertyUsersData?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                onSuccess();
              }}
              mesage={successMsg}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                dispatch(propertyUsersAction.clearData({}));
              }}
              mesage={errMsg}
              footer={false}
            />
          )}
        </>
      )}

      {/* Export CSV file */}
      {getObjLength(exportCSVFileData) && (
        <DialogBox
          width="md"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          openPopup={true}
          onClose={() => {
            dispatch(exportCSVDetialsAction.clearData({}));
          }}
          content={
            <DownloadCSV
              onClose={(action) => {
                handleCloseDownloadModal(action);
              }}
              exportCsvData={exportCSVFileData}
            />
          }
        />
      )}

      <Loader
        open={
          propertyUsersLoader ||
          historyNoteLoader ||
          internalAdminLoader ||
          exportCsvLoader
        }
      />
    </>
  );
};
export default PropertyUsersIndex;
