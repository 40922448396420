import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Typography } from "@mui/material";

import CardPayment from "./CardPayment";
import CreditCardForm from "./CreditCardForm";
import { getObjLength } from "../../helpers/utils";
import SubscriptionPlans from "./SubscriptionPlans";
import OwnerVerification from "./OwnerVerification";
import SecurityInfoScreen from "./SecurityInfoScreen";
import SignatureScreen from "../../user-signup/signatureScreen";
import BeneficiaryForm from "../../user-signup/BeneficiaryForm";
import SecurityPinGeneration from "../../common/SecurityPinGeneration";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const SecurityInfoAndCardPayment = ({
  onCloseModalHandler,
  screen,
  onChangeModalScreen,
  isByPassOptionAvailable,
  isAdminCreateUser = false,
  backToSearchProperty,
  selectedData,
  editProperty,
  addPromoCode = false,
  from = "",
  skipVerification = false,
  isShow = true,
  setSkipVerification,
  isUserCreateProperty = false,
  handleCloseAddPropertyModal = () => {},
  completedProcess = () => {},
  user_id = null,
  tabComponent = false,
  selectedRowData = {},
  isResumeSignup = false,
}) => {
  const dispatch = useDispatch();
  const [isBeneficiariesSkip, setIsBeneficiariesSkip] = useState(false);

  const { selectedUserID, selectedProperty } = useSelector(
    (state) => state.properties
  );
  const {
    mailingAddress,
    statesCodeList,
    subscriptionPlans,
    createAccResponse,
  } = useSelector((state) => state.signUpUser);
  const { propertyUsersLoader, securityPINResponse } = useSelector(
    (state) => state.propertyUsers
  );
  const { userProfileDetails } = useSelector((state) => state?.userProfile);

  const admin_id = userProfileDetails?.data?.user_id;

  // states
  const [answers, setAnswers] = useState({});
  const [planId, setPlanId] = useState("");
  const [yearlyPlan, setYearlyPlan] = useState();
  const [fromPropertyList, setFromPropertyList] = useState(false);
  const [planDetails, setPlanDetails] = useState(yearlyPlan);
  const [finalAmount, setFinalAmount] = useState(yearlyPlan);

  useEffect(() => {
    // let data = {
    //   user_id: getObjLength(createAccResponse)
    //     ? createAccResponse.data?.user_id
    //     : userIdFromSession,
    //   step: 2,
    //   step_key: "GET_MAILING_ADDRESS",
    // };
    // dispatch(
    //   userSigupAction.getMailingAddress({
    //     url: "get-mailing-address",
    //     data: data,
    //   })
    // );
    // dispatch(
    //   userSigupAction.getStateCode({ url: "get-states-code", data: {} })
    // );
    let plan = subscriptionPlans?.data?.find((i) => i.name === "Yearly");
    setYearlyPlan(plan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (planId) {
      const planData = subscriptionPlans?.data?.filter(
        (i) => i.plan_id === parseInt(planId)
      );
      console.log("e.target.value==>", planData?.[0]);
      setPlanDetails(planData?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const cardListAPI = () => {
    dispatch(
      // propertyUsersAction.getCardListData({
      userProfileAction.userCardDetails({
        url: `admin/users/${selectedUserID}/card`,
        data: {},
        token: true,
      })
    );
  };

  useEffect(() => {
    if (from === "add-property") {
      cardListAPI();
    }
  }, [from]);

  const onFinalPIN = (pin) => {
    let inputParam = {
      user_id:
        (getObjLength(createAccResponse)
          ? createAccResponse.data?.user_id
          : JSON.parse(localStorage.getItem("user_Id"))) || user_id,
      security_pin: pin,
      from_admin: 1,
      admin_id: admin_id,
      step: 5,
      step_key: "SECURITY_PIN",
    };
    dispatch(
      propertyUsersAction.getSecurityPIN({
        url: "security-pin",
        data: inputParam,
        token: true,
      })
    );
  };

  const goToSignatureStep = () => {
    dispatch(
      userSigupAction.skipRegisterFaceStep({
        url: "skip-face",
        data: {
          user_id:
            (getObjLength(createAccResponse)
              ? createAccResponse.data?.user_id
              : JSON.parse(localStorage.getItem("user_Id"))) || user_id,
          admin_id: admin_id,
          from_admin: 1,
        },
      })
    );
    onChangeModalScreen("signature");
  };

  const skipBenificiary = () => {
    dispatch(
      userSigupAction.skipBeneficiaryStep({
        url: "skip-beneficiary",
        data: {
          user_id:
            (getObjLength(createAccResponse)
              ? createAccResponse.data?.user_id
              : JSON.parse(localStorage.getItem("user_Id"))) || user_id,
          admin_id: admin_id,
          from_admin: 1,
        },
      })
    );
    setIsBeneficiariesSkip(true);
  };
  return (
    <Box
    // className={`mb-5 profile-wrapper ${
    //   screen === "cardsInfo" ? "pb-0" : ""
    // } bg-white`}
    >
      {screen === "questions" && (
        <SecurityInfoScreen
          isShow={isShow}
          onChangeModalScreen={(scr) =>
            // isByPassOptionAvailable || isAdminCreateUser
            // editProperty
            //   ? onCloseModalHandler("success"):
            onChangeModalScreen(scr)
          }
          handleCloseAddPropertyModal={handleCloseAddPropertyModal}
          onCloseModalHandler={onCloseModalHandler}
          backToSearchProperty={backToSearchProperty}
          setAnswers={setAnswers}
          isByPassOptionAvailable={isByPassOptionAvailable}
          isAdminCreateUser={isAdminCreateUser}
          editProperty={editProperty}
          oldPropertyData={selectedData}
          setSkipVerification={setSkipVerification}
          user_id={user_id}
        />
      )}

      {screen === "propertyList" && (
        <OwnerVerification
          onChangeModalScreen={onChangeModalScreen}
          setFromPropertyList={setFromPropertyList}
          user_id={user_id}
          isResumeSignup={isResumeSignup}
        />
      )}

      {screen === "subscriptions" && (
        <SubscriptionPlans
          isShow={isShow}
          handleCloseAddPropertyModal={handleCloseAddPropertyModal}
          from={from}
          planId={setPlanId}
          onChangeModalScreen={onChangeModalScreen}
          isByPassOptionAvailable={isByPassOptionAvailable}
          isAdminCreateUser={isAdminCreateUser}
          setFinalAmount={setFinalAmount}
          setSkipVerification={setSkipVerification}
          isUserCreateProperty={isUserCreateProperty}
          fromPropertyList={fromPropertyList}
          showSource={isResumeSignup}
          uid={user_id}
        />
      )}
      {screen === "cardsInfo" &&
        (isAdminCreateUser ? (
          <Box className={`bg-white px-5 pt-2`}>
            {/* <Elements stripe={stripePromise}> */}
            <CreditCardForm
              isShow={isShow}
              mailingAddress={mailingAddress?.data?.address}
              mailingData={mailingAddress?.data}
              stateCodesData={statesCodeList}
              planDetails={planDetails}
              onChangeModalScreen={onChangeModalScreen}
              finalAmount={finalAmount}
              onCloseModalHandler={onCloseModalHandler}
              fromPropertyList={fromPropertyList}
              setFromPropertyList={setFromPropertyList}
              user_id={user_id}
            />
            {/* </Elements> */}
          </Box>
        ) : (
          <CardPayment
            isShow={isShow}
            handleCloseAddPropertyModal={handleCloseAddPropertyModal}
            planId={planId}
            answers={answers}
            onChangeModalScreen={onChangeModalScreen}
            onCloseModalHandler={onCloseModalHandler}
            isByPassOptionAvailable={isByPassOptionAvailable}
            isAdminCreateUser={isAdminCreateUser}
            selectedData={
              from === "add-property" ? selectedProperty : selectedData
            }
            addPromoCode={addPromoCode}
            // for add property
            from={from}
            uid={from === "add-property" && selectedUserID}
            callListingAPI={cardListAPI}
            skipVerification={skipVerification}
            isUserCreateProperty={isUserCreateProperty}
            tabComponent={tabComponent}
          />
        ))}

      {screen === "security-pin" && (
        <SecurityPinGeneration
          isAdminCreateUser={isAdminCreateUser}
          titleText={
            from === "add-property" ? "Security PIN" : "User's Security PIN"
          }
          confirmText={"Confirm User's Security PIN"}
          subTitletext={
            "You can change User's security PIN from Users List table anytime you want."
          }
          onCloseBtnFun={() => onChangeModalScreen("bio-metric")}
          apiCall={onFinalPIN}
          securityPinResponse={securityPINResponse}
          loader={propertyUsersLoader}
        />
      )}
      {screen === "bio-metric" && (
        <Box className="accountBoxWrapper text-center m-auto">
          <Box className="" sx={{ mb: 1 }}>
            <Typography variant="h4" className="card-title text-uppercase">
              Biometric
            </Typography>
          </Box>
          <Typography
            variant="h6"
            className="text-center px-5 caption my-5 mb-lg-4"
          >
            Once the sign-up process is completed successfully, kindly prompt
            the user to add their biometric details.
          </Typography>

          <Button
            className="next_button mt-3"
            type="submit"
            onClick={goToSignatureStep}
          >
            Go to Signature step
          </Button>
        </Box>
      )}
      {screen === "signature" && (
        <Box className="bg-white pb-3 d-flex justify-content-center align-items-center text-center m-auto">
          <SignatureScreen
            isAdminCreateUser={isAdminCreateUser}
            onBtnClick={() => {
              onChangeModalScreen("beneficiaries");
              setIsBeneficiariesSkip(false);
            }}
            HideSkipBtn={() => {}}
            admin_id={admin_id}
            user_id={user_id}
            selectedRowData={selectedRowData}
          />
        </Box>
      )}
      {(screen === "beneficiaries" || screen === "welcome") && (
        <Box className="bg-white py-4  text-center m-auto">
          <BeneficiaryForm
            isAdminCreateUser={isAdminCreateUser || from === "add-property"}
            from={from}
            onBtnClick={() => {
              onChangeModalScreen("welcome");
              setIsBeneficiariesSkip(false);
            }}
            admin_id={admin_id}
            user_id={user_id}
            completedProcess={completedProcess}
            skipBenificiary={skipBenificiary}
            isBeneficiariesSkip={isBeneficiariesSkip}
            isResumeSignup={isResumeSignup}
          />
        </Box>
      )}
    </Box>
  );
};

export default SecurityInfoAndCardPayment;
