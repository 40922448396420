import * as React from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { Search } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { PendingActionsOutlined } from "@mui/icons-material";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  TableSortLabel,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import DeleteModal from "../../common/DeleteModal";
import EditPropertyModal from "./EditPropertyModal";
import DocumnetListModal from "./DocumnetListModal";
import EditPropertyDetails from "./EditPropertyDetails";
import { PER_PAGE, ROLES } from "../../helpers/constants";
import UpdateMailingAddress from "./UpdateMailingAddress";
import UpdateClosingByAdmin from "./UpdateClosingByAdmin";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import UploadRequestDocModal from "./UploadRequestDocModal";
import RenewPlanModal from "../../Subscriptions/RenewPlanModal";
import RefundRecordingFeesModal from "./RefundRecordingFeesModal";
import RequestPropertyDocuments from "./RequestPropertyDocuments";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import ChangeSubscriptionPlanModal from "./ChangeSubscriptionPlanModal";
import PropertyRequestDocumentModal from "./PropertyRequestDocumentModal";
import AddSubcription from "../../userProfile/add-property/AddSubcription";
import SecurityQuestionsVerification from "./SecurityQuestionsVerification";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../../redux/slices/user/userProfileSlice";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import { propertiesAction } from "../../../redux/slices/properties/propertiesSlice";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import AdminPropertyViewDetailsModal from "../../common/AdminPropertyViewDetailsModal";
import CancelLennarSubscriptionModal from "../../common/CancelLennarSubscriptionModal";
import { subscriptionAction } from "../../../redux/slices/subscription/subscriptionSlice";
import ResendInvitationModal from "../../professional/send-invitation/ResendInvitationModal";
import { professionalAction } from "../../../redux/slices/admin/professionals/professionalsSlice";
import { propertyListAction } from "../../../redux/slices/admin/admin-dashboard/propertyListSlice";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import { adminDashboardAction } from "../../../redux/slices/admin/admin-dashboard/adminDashboardSlice";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";
import { requestDocumentsAction } from "../../../redux/slices/admin/requestDocuments/requestDocumentsSlice";
import {
  calculateDaysBehind,
  currencyFormatter,
  daysPassedSinceISODate,
  decryptPayload,
  encryptPayload,
  getDateInYYYYMMDDFormat,
  getLocalFormatedDate,
  getObjLength,
} from "../../helpers/utils";

import "./propertyTable.scss";
import "./propertyTable.scss";
import "../../Subscriptions/subscription.scss";

let columns = [
  {
    key: 1,
    id: "sr number",
    disablePadding: true,
    label: "No.",
    value: "address",
    minWidth: 20,
  },
  // {
  //   key: 2,
  //   id: "Payment",
  //   disablePadding: true,
  //   label: "Payment",
  //   value: "addressss",
  //   minWidth: 80,
  // },
  {
    key: 3,
    id: "address",
    disablePadding: true,
    label: "Address",
    value: "address",
    minWidth: 120,
  },
  {
    key: 4,
    id: "city",
    disablePadding: true,
    label: "City",
    value: "city",
    minWidth: 120,
  },
  {
    key: 5,
    id: "state",
    disablePadding: true,
    label: "State",
    value: "state",
    minWidth: 80,
  },
  {
    key: 6,
    id: "Zip",
    disablePadding: true,
    label: "Zip",
    value: "zip",
    minWidth: 80,
  },
  {
    key: 7,
    id: "owner",
    disablePadding: true,
    label: "Owner name",
    value: "owner",
    minWidth: 100,
  },
  {
    key: 8,
    id: "docs",
    disablePadding: true,
    label: "Docs",
    value: "docs",
    minWidth: 110,
  },
  {
    key: 9,
    id: "closing_at",
    disablePadding: true,
    label: "Closing",
    value: "closing_at",
    minWidth: 130,
    sorting: true,
  },
  {
    key: 10, // set this key as default for sorting
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    value: "updated_at",
    minWidth: 130,
    sorting: true,
  },
  {
    key: 11,
    id: "action",
    disablePadding: true,
    label: "Action",
    value: "action",
    minWidth: 30,
  },
];

const subscriptionOptions = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "expired",
    label: "Expired",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "not_applicable",
    label: "Not Applicable",
  },
  {
    value: "terminated",
    label: "Terminated",
  },
];

const propertyStatusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Verified",
    label: "Verified",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Security Questions Skipped",
    label: "Security Questions Skipped",
  },
  {
    value: "Terminated",
    label: "Terminated",
  },
];

function EnhancedTableHead({
  handleSorting,
  directionFlow,
  selectedColumn,
  setSelectedColumn,
  tabComponent,
}) {
  console.log("tabComponent==>", tabComponent);
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell, key) =>
          headCell?.sorting && !tabComponent ? (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={true}
              sx={{ minWidth: headCell?.minWidth }}
            >
              <TableSortLabel
                active={selectedColumn === key + 2 ? true : false}
                direction={
                  selectedColumn === key + 2
                    ? directionFlow
                      ? "desc"
                      : "asc"
                    : "desc"
                }
                onClick={() => {
                  handleSorting(headCell.id);
                  setSelectedColumn(headCell?.key);
                }}
                sx={{ color: "#0395FF !important" }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={"normal"}
              sortDirection={false}
              sx={{ color: "#0395FF", minWidth: headCell?.minWidth }}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

function Row({
  key,
  row,
  open,
  handleMenuClick,
  page,
  index,
  tabComponent = false,
  handleCollapsibleRow,
  isCollapsible = false,
  handleOpenDocumentListModal,
  setSelectedProperty,
  selectProperties = () => {},
  setPropertiesIds = [],
  setpropertyIDList = [],
  propertyIDList = [],
  setCheck = () => {},
  check = false,
  handleCheckboxChange = () => {},
  setCheckedItems = () => {},
  checkedItems = false,
  providersIDList,
  userId = null,
}) {
  const county_details = row?.owner?.[0]?.county_details;

  const calculateBehindDays = calculateDaysBehind(row?.subscription?.start_at);
  const isRowHighlight =
    row?.owner?.[0]?.json_last_step?.step > 3 &&
    (calculateBehindDays > 6 || row?.renewed_no_of_times > 0) &&
    row?.latest_ucc?.length === 0 &&
    !row?.terminated &&
    row?.subscription && //for Active Subscription
    ((row.subscription?.expired === false &&
      row.subscription?.is_active &&
      row.subscription?.cancel_at === null) ||
      //for Expired Subscription
      (row.subscription?.expired === true &&
        row.subscription?.cancel_at === null) ||
      //for Cancelled Subscription
      (row.subscription?.expired === false &&
        row.subscription?.cancel_at !== null));

  return (
    <React.Fragment>
      <TableRow
        hover={!isRowHighlight && parseInt(userId) === parseInt(row?.owner_id)}
        role="checkbox"
        tabIndex={-1}
        key={(page - 1) * 10 + index + 1}
        onClick={() => handleCollapsibleRow(row)}
        className="cursor-pointer"
        sx={{
          backgroundColor: isRowHighlight
            ? "#E83E8C26"
            : parseInt(userId) !== parseInt(row?.owner_id) && tabComponent
            ? "rgb(0 0 0 / 7%)"
            : isCollapsible
            ? "rgba(3, 149, 255, 0.07)"
            : "",
        }}
      >
        <TableCell align="left">{(page - 1) * PER_PAGE + index + 1}</TableCell>
        {tabComponent && (
          <TableCell align="center">
            {!row?.added_by_other_user &&
            (!row?.subscription ||
              row?.subscription === null ||
              (row?.subscription &&
                (row?.subscription?.cancel_at !== null ||
                  (row?.subscription?.payment_status === "failed" &&
                    !row?.subscription?.expired)))) ? (
              //  (row?.subscription && row?.subscription?.cancel_at !== null) ||
              //(row?.subscription &&
              //row?.subscription?.payment_status === "failed")
              !row?.provider ? (
                <Tooltip
                  title="Select Property to Purchase Plan"
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <Checkbox
                    // defaultChecked
                    disabled={
                      !checkedItems ||
                      parseInt(userId) !== parseInt(row?.owner_id)
                    }
                    color="primary"
                    onChange={(e) => {
                      selectProperties(e, row, false);
                      setCheck(!check);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={`This property is from ${
                    row?.provider?.domain || row?.provider?.custom_domain
                  } domain.`}
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <Checkbox
                    color="success"
                    disabled={propertyIDList?.length > 0}
                    key={index}
                    checked={!checkedItems}
                    onChange={(e) => {
                      selectProperties(e, row, true);
                      setCheckedItems(!checkedItems);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Tooltip>
              )
            ) : (
              "-"
            )}
          </TableCell>
        )}
        <TableCell
          component="th"
          id={row?.property_id}
          scope="row"
          padding="none"
        >
          {row?.address.split(",")?.[0]?.length > 0
            ? row?.address.split(",")?.[0]
            : "Raw Parcel : " + row?.parcel_number}
        </TableCell>
        <TableCell className="text-capitalize" align="left">
          {row?.city || "-"}
        </TableCell>
        <TableCell align="left">{row?.state.toUpperCase() || "-"}</TableCell>
        <TableCell align="left">{row?.zip || "-"}</TableCell>
        <TableCell align="left">
          {row?.property_details?.OwnerInfo?.["Owner Name"]?.trim()
            ? row?.property_details?.OwnerInfo?.["Owner Name"]?.trim()
            : row?.property_details?.OwnerInfo?.["Owner Name 2"]?.trim()
            ? row?.property_details?.OwnerInfo?.["Owner Name 2"]?.trim()
            : "-"}
        </TableCell>
        <TableCell align="left">
          {row?.latest_ucc?.length !== 0 ||
          row?.general_documents?.length !== 0 ? (
            <span
              className={`doc-status ${
                row?.latest_ucc?.length + row?.general_documents?.length > 0
                  ? "text-primary text-decoration-underline"
                  : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedProperty(row);
                handleOpenDocumentListModal();
              }}
            >
              {row?.latest_ucc || row?.general_documents
                ? row?.latest_ucc?.length + row?.general_documents?.length
                : "-"}
            </span>
          ) : (
            <span>{row?.latest_ucc?.length}</span>
          )}
        </TableCell>
        <TableCell align="left" width={130}>
          {row?.closing_at ? getLocalFormatedDate(row?.closing_at) : "-"}
        </TableCell>
        <TableCell align="left" width={130}>
          {row?.updated_at ? getLocalFormatedDate(row?.updated_at) : "-"}
        </TableCell>

        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            {tabComponent ? (
              parseInt(userId) === parseInt(row?.owner_id) ? (
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuClick(event);
                    setSelectedProperty(row);
                    if (
                      !(row?.subscription || row?.subscription === null) &&
                      !propertyIDList.includes(row?.id)
                    ) {
                      setPropertiesIds([row?.property_id]);
                      setpropertyIDList([row?.id]);
                    }
                  }}
                  size="small"
                  className="settings-button"
                  aria-controls={open ? "settings-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <SettingsIconSVG
                    color={
                      row?.status === "Pending"
                        ? "#DC3545"
                        : row?.status === "Pending Recorder"
                        ? "#0395FF"
                        : row?.status === "Secured"
                        ? "#15BE53"
                        : ""
                    }
                  />
                </IconButton>
              ) : null
            ) : (
              !decryptPayload(localStorage.getItem("roles"))?.includes(
                ROLES.provider
              ) &&
              !decryptPayload(localStorage.getItem("roles"))?.includes(
                ROLES.countyRecorder
              ) && (
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuClick(event);
                    setSelectedProperty(row);
                    if (
                      !(row?.subscription || row?.subscription === null) &&
                      !propertyIDList.includes(row?.id)
                    ) {
                      setPropertiesIds([row?.property_id]);
                      setpropertyIDList([row?.id]);
                    }
                  }}
                  size="small"
                  className="settings-button"
                  aria-controls={open ? "settings-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <SettingsIconSVG
                    color={
                      row?.status === "Pending"
                        ? "#DC3545"
                        : row?.status === "Pending Recorder"
                        ? "#0395FF"
                        : row?.status === "Secured"
                        ? "#15BE53"
                        : ""
                    }
                  />
                </IconButton>
              )
            )}

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleCollapsibleRow(row)}
            >
              {isCollapsible ? (
                <KeyboardArrowUpIcon style={{ color: " #15BE53" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: " #15BE53" }} />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{
          backgroundColor: isRowHighlight
            ? " #E83E8C26"
            : parseInt(userId) !== parseInt(row?.owner_id) && tabComponent
            ? "rgb(16 82 122 / 13%)"
            : "rgba(3, 149, 255, 0.07)",
        }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={tabComponent ? 2 : 1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  User Name
                </Typography>
                <Typography className="content-text text-capitalize">
                  {tabComponent ? (
                    row?.full_name ? (
                      row?.full_name
                    ) : row?.owner ? (
                      row?.owner?.[0]?.full_name
                    ) : (
                      "-"
                    )
                  ) : (
                    <Tooltip
                      title="Click here to see user's details."
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                    >
                      <Link
                        className="text-decoration-none "
                        to={
                          row?.created_by?.id &&
                          row?.created_by?.type?.includes(
                            ROLES?.serviceProvider
                          )
                            ? "/service-providers/" + row?.owner_id
                            : "/admin-users/" + row?.owner_id
                        }
                      >
                        {row?.full_name
                          ? row?.full_name
                          : row?.owner
                          ? row?.owner?.[0]?.full_name
                          : "-"}
                      </Link>
                    </Tooltip>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                  sx={{ wordBreak: "break-all" }}
                >
                  Property Status
                </Typography>
                <Typography className="content-text">
                  {row?.status === "Pending" ? (
                    <span className="text-muted">Pending</span>
                  ) : row?.status === "Verified" ? (
                    <span className="text-info">Verified</span>
                  ) : row?.status === "Active" ? (
                    <span className="text-success">Active</span>
                  ) : row?.status === "Terminated" ? (
                    <span className="text-danger">Terminated</span>
                  ) : row?.status === "Cancelled" ? (
                    <span className="text-muted">Pending</span>
                  ) : (
                    "-"
                  )}
                  {row?.is_verified === 0 && (
                    <Typography className="content-text text-danger">
                      (Security Questions Skipped)
                    </Typography>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Transaction Id
                </Typography>
                <Typography className="content-text">
                  {row?.transaction_id ? row?.transaction_id : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Promo code
                </Typography>
                <Typography className="content-text">
                  {/* {row?.subscription &&
                  row?.subscription !== null &&
                  row?.subscription?.sub_payments?.promocode !== null
                    ? row?.subscription?.sub_payments?.promocode
                    : "-"} */}
                  {row?.promocode || "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  UCC status
                </Typography>

                <Typography className="content-text">
                  {row?.ucc_status ? (
                    <span className="text-success">Generated</span>
                  ) : (
                    <span className="text-muted">Pending</span>
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "70px",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Subscription
                </Typography>

                {!row?.subscription || row?.subscription === null ? (
                  <Typography className="text-info content-text">
                    Not Applicable
                  </Typography>
                ) : row?.subscription?.expired === false &&
                  row?.subscription?.is_active &&
                  row?.subscription?.cancel_at === null ? (
                  <Typography className="text-success content-text">
                    Active
                  </Typography>
                ) : row?.subscription?.expired === true &&
                  row?.subscription?.cancel_at === null ? (
                  <Typography className="text-danger content-text">
                    Expired
                  </Typography>
                ) : //  ) : row?.subscription?.expired === false &&
                //  row?.subscription?.payment_status === "failed" ? (
                //<Typography className="text-danger content-text">
                //Failed
                //</Typography>
                row?.terminated ? (
                  <Typography className="text-danger content-text">
                    Terminated
                  </Typography>
                ) : row?.subscription?.expired === false &&
                  row?.subscription?.cancel_at !== null ? (
                  <>
                    <Typography className="text-warning content-text">
                      Cancelled
                    </Typography>
                    {!row?.subscription?.sub_payments?.refund && (
                      <p className="text-black content-text">
                        ({row?.subscription?.end_at})
                      </p>
                    )}
                  </>
                ) : row?.subscription?.payment_status === "failed" ? (
                  <Typography className="text-info content-text">
                    Not Applicable
                  </Typography>
                ) : (
                  "-"
                )}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        {getObjLength(county_details) ? (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
            }}
            colSpan={1}
          >
            <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  minHeight: "70px",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="h6"
                    className="content-heading"
                    component="div"
                  >
                    County
                  </Typography>
                  <Typography className="content-text m-0" component="div">
                    <span className="text-capitalize">
                      {county_details?.county_name}
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Collapse>
          </TableCell>
        ) : (
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
            colSpan={1}
          ></TableCell>
        )}
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
      </TableRow>

      <TableRow
        className="collapsible-row"
        sx={{
          backgroundColor: isRowHighlight
            ? "#E83E8C26"
            : parseInt(userId) !== parseInt(row?.owner_id) && tabComponent
            ? "rgb(16 82 122 / 13%)"
            : "rgba(3, 149, 255, 0.07)",
        }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={tabComponent ? 2 : 1}
        ></TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Built
                </Typography>

                <Typography className="content-text">
                  {row?.year_built}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Sold
                </Typography>

                <Typography className="content-text">
                  {row?.sold ? row?.sold : "-"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={2}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Paid Recording Fees
                </Typography>

                <Typography className="content-text">
                  {row?.paid_recording_charge
                    ? currencyFormatter(row?.paid_recording_charge)
                    : "$0.00"}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Refund Recording Fees
                </Typography>

                <Typography className="content-text">
                  {row?.refunded_recording_charge
                    ? currencyFormatter(row?.refunded_recording_charge)
                    : "$0.00"}
                  {/* {row?.subscription?.refund_subscription?.refunded_amount
                    ? currencyFormatter(
                        row?.subscription?.refund_subscription?.refunded_amount
                      )
                    : "$0.00"} */}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                  sx={{ wordBreak: "break-all" }}
                >
                  Created By
                </Typography>
                <Typography className="content-text text-capitalize">
                  {row?.created_by?.id ? (
                    row?.created_by?.type === ROLES?.admin ? (
                      "Super Admin"
                    ) : row?.created_by?.type === ROLES?.professional ? (
                      <Tooltip
                        title="Click here to see user's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/admin-professionals/" + row?.created_by?.id}
                        >
                          {row?.created_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : row?.created_by?.type === ROLES?.customer ||
                      row?.created_by?.type === ROLES?.secondaryUser ? (
                      tabComponent ? (
                        row?.owner?.[0]?.full_name || "-"
                      ) : (
                        <span style={{ color: "red" }}>
                          <Tooltip
                            title="Click here to see user's details."
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <Link
                              className="text-decoration-none"
                              to={"/admin-dashboard/" + row?.created_by?.id}
                            >
                              {row?.created_by?.full_name || "-"}
                            </Link>
                          </Tooltip>
                        </span>
                      )
                    ) : row?.created_by?.type === ROLES?.subAdmin ? (
                      <Tooltip
                        title="Click here to see Admin's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/admins/" + row?.created_by?.id}
                        >
                          {row?.created_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : row?.created_by?.type === ROLES?.serviceProvider ? (
                      <Tooltip
                        title="Click here to see Service Provider's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/service-providers/" + row?.created_by?.id}
                        >
                          {row?.created_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>

        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Closing By
                </Typography>

                <Typography className="content-text text-capitalize">
                  {row?.closing_by?.id ? (
                    row?.closing_by?.type === ROLES?.admin ? (
                      "Super Admin"
                    ) : row?.closing_by?.type === ROLES?.professional ? (
                      <Tooltip
                        title="Click here to see user's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/admin-professionals/" + row?.closing_by?.id}
                        >
                          {row?.closing_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : row?.closing_by?.type === ROLES?.customer ||
                      row?.closing_by?.type === ROLES?.secondaryUser ? (
                      tabComponent ? (
                        row?.owner?.[0]?.full_name || "-"
                      ) : (
                        <span style={{ color: "red" }}>
                          <Tooltip
                            title="Click here to see user's details."
                            TransitionComponent={Zoom}
                            placement="top"
                            arrow
                          >
                            <Link
                              className="text-decoration-none"
                              to={"/admin-dashboard/" + row?.closing_by?.id}
                            >
                              {row?.closing_by?.full_name || "-"}
                            </Link>
                          </Tooltip>
                        </span>
                      )
                    ) : row?.closing_by?.type === ROLES?.subAdmin ? (
                      <Tooltip
                        title="Click here to see Admin's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/admins/" + row?.closing_by?.id}
                        >
                          {row?.closing_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : row?.closing_by?.type === ROLES?.serviceProvider ? (
                      <Tooltip
                        title="Click here to see Service Provider's details."
                        TransitionComponent={Zoom}
                        placement="top"
                        arrow
                      >
                        <Link
                          className="text-decoration-none "
                          to={"/service-providers/" + row?.closing_by?.id}
                        >
                          {row?.closing_by?.full_name || "-"}
                        </Link>
                      </Tooltip>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
          }}
          colSpan={1}
        >
          <Collapse in={isCollapsible} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
                minHeight: "100px",
                maxHeight: "200px",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Stack direction="column">
                <Typography
                  variant="h6"
                  className="content-heading"
                  component="div"
                >
                  Created At
                </Typography>
                <Typography className="content-text m-0" component="div">
                  <span className="text-capitalize">
                    {row?.created_at
                      ? getLocalFormatedDate(row?.created_at)
                      : "-"}
                  </span>
                </Typography>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          colSpan={1}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PropertyTable({
  userId,
  tabComponent,
  offsetNum,
  setCSV_Payload,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [selected, setSelected] = useState([]);
  const [skippedOrSubmitted, setSkippedorSubmitted] = useState(false);
  const [propertyPayload, setPropertyPayload] = useState();
  const [propertyType, setPropertyType] = useState("all");
  const [propertyTerm, setPropertyTerm] = useState("");
  const [countyId, setCountyId] = useState("");
  const [postcardStatus, setPostcardStatus] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [uccStatus, setUCCStatus] = useState("");
  const [isResendPostcardModalOpen, setIsResendPostcardModalOpen] =
    useState(false);
  const [selectedDateType, setSelectedDateType] = useState("closing_at");

  const [alertMsg, setAlertMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPendingStatusModalOpen, setIsPendingStatusModalOpen] =
    useState(null);
  const [
    isPendingRecorderStatusModalOpen,
    setIsPendingRecorderStatusModalOpen,
  ] = useState(null);
  const [isReplaceUCCModalOpen, setIsReplaceUCCModalOpen] = useState(null);
  const [isGeneralDocumentsModalOpen, setIsGeneralDocumentsModalOpen] =
    useState(null);
  const [isEditPropertyModalOpen, setIsEditPropertyModalOpen] = useState(false);
  const [documnetListModal, setDocumnetListModal] = useState(false);
  const [isViewPropertyDetailModalOpen, setIsViewPropertyDetailModalOpen] =
    useState(null);
  const [isVerifyAddressModalOpen, setIsVerifyAddressModalOpen] =
    useState(false);
  const [
    isVerifyAddressResponseModalOpen,
    setIsVerifyAddressResponseModalOpen,
  ] = useState(false);
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const [isChangeSubscriptionModalOpen, setIsChangeSubscriptionModalOpen] =
    useState(false);
  const [
    isCancelChangeSubscriptionRequestModalOpen,
    setIsCancelChangeSubscriptionRequestModalOpen,
  ] = useState(false);
  const [deletePropetyModal, setDeletePropetyModal] = useState(false);
  const [selectedCollapsibleRow, setSelectedCollapsibleRow] = useState(null);
  const [isCollapsibleRow, setIsCallapsibleRow] = useState(false);
  const [isRefundRecordingFeesModalOpen, setIsRefundRecordingFeesModalOpen] =
    useState(false);
  const [editPropetyModal, setEditPropetyModal] = useState(false);
  const [addSubcriptionPopup, setAddSubcriptionPopup] = useState(false);
  const [securityQuestionsPopup, setSecurityQuestionsPopup] = useState(false);
  const [propertiesIds, setPropertiesIds] = useState([]);
  const [propertyIDList, setpropertyIDList] = useState([]);
  const [providersIDList, setProvidersIDList] = useState([]);
  const [countyDetail, setCountyDetails] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(10); // updated_at key for sorting
  const [isCardListOpen, setIsCardListOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [accessRequestedDocumentComp, setAccessRequestedDocumentComp] =
    useState(false);
  const [requestedDocumentAlert, setRequestedDocumentAlert] = useState(false);
  const [requestDocumentAction, setRequestDocumentAction] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [isResumeSubscription, setIsResumeSubscription] = useState(false);
  const [isUpdateMailingAddress, setIsUpdateMailingAddress] = useState(false);

  // plan renewal
  const [isRenewPlanModalOpen, setIsRenewPlanModalOpen] = useState(false);
  const [isRenewFinalAmtModalOpen, setIsRenewFinalAmtModalOpen] =
    useState(false);
  const [valdiateModal, setValdiateModal] = useState(false);
  const [retryPayment, setRetryPayment] = useState(false);
  const [subAdminId, setSubAdminId] = useState();
  const [subAdminName, setSubAdminName] = useState();
  const [closingAdminPopup, setClosingAdminPopup] = useState(false);

  // store data
  const {
    bindProfessionalDetails,
    resendPostcardLoader,
    resendPostcardDetails,
    updateMailingAddressDetails,
  } = useSelector((state) => state?.propertyLists);
  const { professionalListLoader } = useSelector(
    (state) => state?.professionals
  );

  const {
    deletePropertyData,
    deletePropertyLoader,
    internalAdminLoader,
    internalAdminDetails,
  } = useSelector((state) => state?.propertyUsers);

  const {
    subscriptionLoader,
    subscriptionRenewed,
    retryPaymentData,
    resumeSubscriptionData,
  } = useSelector((state) => state?.subscription);

  const { statesCodeList } = useSelector((state) => state.signUpUser);

  const { subscriptionModuleFilterData } = useSelector(
    (state) => state?.adminDashboard
  );
  let tempPropertyFilterData =
    JSON.parse(
      decryptPayload(localStorage.getItem("tempPropertyFilterData"))
    ) || null;
  const open = Boolean(anchorEl);

  const queryString2 = window.location.search;
  let paramString = queryString2?.split("?")[1]?.replace("from=", "");
  let paramString2 = paramString?.split("&");
  const is_provider = paramString2?.[0];

  let fromDashboard = window.location.search
    ?.split("?")[1]
    ?.replace("fromDashboard=", "");
  let tokenValue = fromDashboard?.split("&&");
  console.log("Param==>", is_provider);

  //when user opend the cards in new tab
  if (fromDashboard !== "subscription" && tokenValue?.[0] === "subscription") {
    tempPropertyFilterData = JSON.parse(
      decryptPayload(tokenValue?.[1]) || null
    );
  }

  console.log("SELELCTEDITEM", selectedProperty);
  // Pagination States & Variables
  let [page, setPage] = useState(1);

  // Selectors for Property Listing
  const {
    propertyListDataCount,
    propertyListData,
    propertiesLoader,
    propertyRequestDocument,
    uploadedPropertyDocuments,
    replacedPropertyDocuments,
    uploadedPropertyGeneralDocuments,
    verifyAddressDetails,
    verifyAddressLoader,
    canceledSubscriptionDetails,
    cancelSubscriptionLoader,
    changedSubscriptionDetails,
    cancelChangeSubscriptionRequestDetails,
    cancelChangeSubscriptionRequestLoader,
    refundRecordingFeesDetails,
  } = useSelector((state) => state?.propertyLists);

  const { stopSubscriptionData, propertyLoader } = useSelector(
    (state) => state?.properties
  );

  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const count = Math.ceil(propertyListDataCount / PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    setPropertyPayload((prevState) => ({
      ...prevState,
      limit: PER_PAGE,
      offset: (p - 1) * PER_PAGE,
    }));
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterProperty = (e) => {
    e.preventDefault();
    handleURLParameters();
    // if (!propertyTerm || !propertyStatus) return;
    setPage(1);
    setPropertyPayload((prevState) => ({
      ...prevState,
      type: propertyType || undefined,
      term: propertyTerm || undefined,
      county_id: countyId || undefined,
      status: propertyStatus || undefined,
      ucc_status: uccStatus || undefined,
      postcard_status: postcardStatus || undefined,
      subscription: subscriptionStatus || undefined,
      subscription_type: subscriptionType || undefined,
      from_date:
        (propertyType === "created_at" || selectedDateType === "created_at") &&
        selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
          : undefined,
      to_date:
        (propertyType === "created_at" || selectedDateType === "created_at") &&
        selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
      modified_from_date:
        propertyType === "updated_at" || selectedDateType === "updated_at"
          ? selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined
          : undefined,
      modified_to_date:
        (propertyType === "updated_at" || selectedDateType === "updated_at") &&
        selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
      upcoming_subscription_renewals_from_date:
        propertyType === "upcoming_subscription_renewals" ||
        selectedDateType === "upcoming_subscription_renewals"
          ? selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined
          : undefined,
      upcoming_subscription_renewals_to_date:
        (propertyType === "upcoming_subscription_renewals" ||
          selectedDateType === "upcoming_subscription_renewals") &&
        selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
      closing_at_from_date:
        propertyType === "closing_at" || selectedDateType === "closing_at"
          ? selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined
          : undefined,
      closing_at_to_date:
        (propertyType === "closing_at" || selectedDateType === "closing_at") &&
        selectedDateRange
          ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
          : undefined,
      limit: PER_PAGE || 10,
      offset: 0,
      admin_id: subAdminId,
    }));
  };

  const resetAllFilterOptions = () => {
    handleURLParameters();
    if (
      propertyTerm ||
      propertyStatus ||
      uccStatus ||
      postcardStatus ||
      subscriptionStatus ||
      subscriptionType ||
      selectedDateRange ||
      selectedDateType ||
      countyId ||
      subAdminId ||
      subAdminName
    ) {
      setPage(1);
      setPropertyType("all");
      setPropertyTerm("");
      setCountyId("");
      setPropertyStatus("");
      setUCCStatus("");
      setPropertyPayload();
      setPostcardStatus("");
      setSubscriptionStatus("");
      setSubscriptionType("");
      setSelectedDateRange(null);
      setDirectionFlow(true);
      setSelectedDateType("closing_at");
      setSubAdminId();
      setSubAdminName();
      fromDashboard = null;
    }
  };

  const getPropertyListData = useCallback(() => {
    localStorage.removeItem("tempPropertyFilterData");
    setIsRenewPlanModalOpen(false);
    cancelLennarSubscriptionModalClose();
    console.log("===kkk propertyPayload?.offset=> ", propertyPayload?.offset);
    let apiURL = "admin/property-list";
    if (`${userId}`?.length > 0 && !isNaN(userId)) {
      apiURL = "admin/property-list/" + userId;
    }
    let data = {
      limit: PER_PAGE,
      offset: propertyPayload?.offset || 0,
      type: propertyPayload?.type,
      term: propertyPayload?.term,
      county_id: propertyPayload?.county_id,
      status: propertyPayload?.status,
      ucc_status: propertyPayload?.ucc_status,
      postcard_status: propertyPayload?.postcard_status,
      subscription: propertyPayload?.subscription,
      subscription_type: propertyPayload?.subscription_type,
      from_date: propertyPayload?.from_date,
      to_date: propertyPayload?.to_date,
      modified_from_date: propertyPayload?.modified_from_date,
      modified_to_date: propertyPayload?.modified_to_date,
      upcoming_subscription_renewals_from_date:
        propertyPayload?.upcoming_subscription_renewals_from_date,
      upcoming_subscription_renewals_to_date:
        propertyPayload?.upcoming_subscription_renewals_to_date,
      closing_at_from_date: propertyPayload?.closing_at_from_date,
      closing_at_to_date: propertyPayload?.closing_at_to_date,
      order_by_column: propertyPayload?.order_by_column || "updated_at",
      sorting_order: propertyPayload?.sorting_order || "desc",
      admin_id:
        propertyType === "subadmin" ? propertyPayload?.admin_id : undefined,
      closing_by_id:
        propertyType === "all" ? propertyPayload?.admin_id : undefined,
    };
    setCSV_Payload(data);
    dispatch(
      propertyListAction.getPropertyListData({
        url: apiURL,
        data,
        token: true,
      })
    );
  }, [
    propertyPayload?.offset,
    propertyPayload?.type,
    propertyPayload?.term,
    propertyPayload?.county_id,
    propertyPayload?.status,
    propertyPayload?.ucc_status,
    propertyPayload?.postcard_status,
    propertyPayload?.subscription,
    propertyPayload?.subscription_type,
    propertyPayload?.from_date,
    propertyPayload?.to_date,
    propertyPayload?.modified_from_date,
    propertyPayload?.modified_to_date,
    propertyPayload?.closing_at_from_date,
    propertyPayload?.closing_at_to_date,
    propertyPayload?.order_by_column,
    propertyPayload?.sorting_order,
    propertyPayload?.admin_id,
    userId,
    dispatch,
  ]);

  // API call when Admin redirect from the New dashboard
  const getPropertyListAPI = (filterData) => {
    let apiURL = "admin/property-list";
    let data = {
      limit: PER_PAGE,
      offset: propertyPayload?.offset || 0,
      order_by_column: propertyPayload?.order_by_column || "updated_at",
      sorting_order: propertyPayload?.sorting_order || "desc",
    };
    if (getObjLength(filterData)) {
      data = { ...filterData, ...data };
    }
    console.log("KK Get property list-->", data);
    if (getObjLength(filterData)) {
      localStorage.setItem(
        "tempPropertyFilterData",
        encryptPayload(JSON.stringify(data))
      );
    }

    dispatch(
      propertyListAction.getPropertyListData({
        url: apiURL,
        data,
        token: true,
      })
    );
  };

  // For manage API calls for menu actions componnets
  const handlePropertyListAPICall = () => {
    if (
      (fromDashboard === "subscription" ||
        tokenValue?.[0] === "subscription") &&
      (getObjLength(subscriptionModuleFilterData) ||
        getObjLength(tempPropertyFilterData)) // to set API Call with the specific filter data
    ) {
      getPropertyListAPI(
        subscriptionModuleFilterData || tempPropertyFilterData
      );
    } else {
      getPropertyListData(); // default API call with exising filter data
    }
  };

  // To clear the URL prams and manage the API filters accordingly
  const handleURLParameters = () => {
    localStorage.removeItem("tempPropertyFilterData");
    dispatch(adminDashboardAction.getSubscriptionModuleFilterData({}));
    const currentUrl = window.location.href;
    // Check if the URL contains the query parameter 'abcd'
    if (currentUrl?.includes(fromDashboard)) {
      // Remove the query parameter
      const newUrl = currentUrl?.replace(`?fromDashboard=${fromDashboard}`, "");

      // Use react-router-dom to navigate to the new URL
      navigate(newUrl?.replace(window.location.origin, ""));
    }
  };

  const handleAllTypeFilterOption = (e) => {
    setPropertyType(e.target.value);
    setPropertyTerm("");
    setPropertyStatus("");
    setUCCStatus("");
    setCountyId("");
    setPostcardStatus("");
    setSubscriptionStatus("");
    setSubscriptionType("");
    setSelectedDateRange(null);
    setSubAdminId();
    setSubAdminName();
    setSelectedDateType("closing_at");

    // if (e.target.value !== "subscription") {
    //  handleURLParameters();
    // }
  };

  useEffect(() => {
    // when admin redirect from the admin dashboard's subscription section
    let filterData =
      (getObjLength(subscriptionModuleFilterData) &&
        subscriptionModuleFilterData) ||
      (getObjLength(tempPropertyFilterData) && tempPropertyFilterData);

    if (
      (fromDashboard === "subscription" ||
        tokenValue?.[0] === "subscription") &&
      getObjLength(filterData)
    ) {
      console.log("subscriptionModuleFilterData==>", filterData);
      setToggleFilterOptions(true);
      // setPropertyType(filterData?.type);
      if (filterData?.subscription) {
        setSubscriptionStatus(filterData?.subscription);
      }
      if (filterData?.status) {
        setPropertyStatus(filterData?.status);
      }
      if (filterData?.ucc_status) {
        setUCCStatus(filterData?.ucc_status);
      }
      if (filterData?.from_date && filterData?.to_date) {
        setSelectedDateRange({
          startDate: filterData?.from_date,
          endDate: filterData?.to_date,
        });
        setSelectedDateType("created_at");
      }
      if (filterData?.action === "newProperties") {
        setSelectedDateRange({
          startDate: filterData?.from_date,
          endDate: filterData?.to_date,
        });
      }
      if (filterData?.action === "upcoming_subscription_renewals") {
        setSelectedDateType(filterData?.action);
        setSelectedDateRange({
          startDate: filterData?.upcoming_subscription_renewals_from_date,
          endDate: filterData?.upcoming_subscription_renewals_to_date,
        });
      }
      getPropertyListAPI(filterData);
    } else {
      getPropertyListData(); // default API call
    }
    if (is_provider === "provider") {
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [dispatch, getPropertyListData, is_provider]);

  const handleEditPropertyEvent = (event) => {
    dispatch(propertyListAction.clearBindProfessionalDetails());
    setIsEditPropertyModalOpen(true);
  };

  const handlePendingStatus = (row) => {
    setSelectedProperty(row);
    setIsPendingStatusModalOpen(true);
  };
  const handlePendingRecorderStatus = (row) => {
    setSelectedProperty(row);
    setIsPendingRecorderStatusModalOpen(true);
    dispatch(propertyListAction.clearPropertyRequestDocument());
  };

  const handleReplaceUCCForm = (row) => {
    setSelectedProperty(row);
    setIsReplaceUCCModalOpen(true);
    dispatch(propertyListAction.clearPropertyRequestDocument());
  };

  const handleCloseModal = () => {
    setIsGeneralDocumentsModalOpen(false);
    setIsPendingStatusModalOpen(false);
    setIsReplaceUCCModalOpen(false);
    setIsPendingRecorderStatusModalOpen(false);
    setSelectedProperty(null);
    dispatch(propertyListAction.clearPropertyRequestDocument());
    setDocumnetListModal(false);
    setAlertMsg(false);
    setIsVerifyAddressResponseModalOpen(false);
    setIsVerifyAddressModalOpen(false);
    dispatch(propertyListAction.setVerifyAddressDetails({}));
    setIsEditPropertyModalOpen(false);
  };

  const handleAddsubscriptionCLoseModal = () => {
    setAddSubcriptionPopup(false);
    setpropertyIDList(
      propertyIDList?.filter((e) => e.id !== selectedProperty?.id)
    );
    if (propertyIDList?.[0]?.id === selectedProperty?.id) {
      if (selectedProperty?.allow_promocode) {
        handlePropertyListAPICall();
      }
    }
  };
  console.log(selectedProperty, "propertyIDList==>", propertyIDList);

  const handlePropertyDocRequestBtn = (values) => {
    if (selectedProperty && selectedProperty?.id) {
      dispatch(
        propertyListAction.getRequestPropertyDocument({
          url: "admin/request-document",
          data: {
            id: selectedProperty.id,
            ...values,
          },
          token: true,
        })
      );

      console.log(propertyRequestDocument);
      // setIsPendingStatusModalOpen(false);
    }
  };

  const handleUploadDocuments = (values, replaceDocuments = false) => {
    const uploadedDocs = values.upload_doc;

    const formData = new FormData();

    formData.append("id", selectedProperty.id.toString());
    uploadedDocs.map((doc, index) =>
      formData.append(`document[${index}]`, doc)
    );
    formData.append("property_id", selectedProperty?.property_id);
    formData.append(
      "recording_date",
      getDateInYYYYMMDDFormat(new Date(values?.recording_date), "-")
    );
    if (values?.booking_page) {
      formData.append("booking_page", values?.booking_page);
    }
    // formData.append(
    //   "booking_page",
    //   values?.booking_page ? values?.booking_page : null
    // );
    if (values?.instrument_number) {
      formData.append("instrument_number", values?.instrument_number);
    }
    // formData.append(
    //   "instrument_number",
    //   values?.instrument_number ? values?.instrument_number : null
    // );
    if (replaceDocuments) {
      formData.append("replace", 1);
    }

    console.log("form values", formData);
    dispatch(
      propertyListAction.getUploadedPropertyDocuments({
        url: "admin/verify-document",
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  const handleUploadGeneralDocuments = (values) => {
    const uploadedDocs = values.upload_doc;

    const formData = new FormData();

    formData.append("id", selectedProperty.id.toString());
    uploadedDocs.map((doc, index) =>
      formData.append(`document[${index}]`, doc)
    );
    // formData.append("property_id", selectedProperty?.property_id);

    // return console.log("form values", formData);
    dispatch(
      propertyListAction.getUploadedPropertyGeneralDocuments({
        url: "admin/upload-general-document",
        data: formData,
        token: true,
        isFile: true,
      })
    );
  };

  // bind professional with property
  const handleBindProfessional = (values) => {
    // console.log("handleBindProfessional", values);
    // console.log("selectedProperty", selectedProperty);

    if (selectedProperty && selectedProperty?.id) {
      dispatch(
        propertyListAction.bindProfessionalsToProperty({
          url: `admin/assign-professional`,
          data: {
            ...values,
            id: selectedProperty?.id,
            property_id: parseInt(selectedProperty?.property_id),
          },
          token: true,
        })
      );
    }
  };

  const handleOpenDocumentListModal = () => {
    setDocumnetListModal(true);
  };

  const handleCheckboxChange = (id) => {
    const newValue = !checkedItems[id];
    setCheckedItems({ ...checkedItems, [id]: newValue });
    setCheck(Object.values(checkedItems).every((value) => value)); // Update master checkbox state
  };

  // handle open view property modal
  const handleOpenViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(true);
  };

  // handle close view property modal
  const handleCloseViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(false);
  };

  // handle verify address button
  const handleVerifyAddress = () => {
    // call an API
    dispatch(
      propertyListAction.verifyAddress({
        url: "admin/verify-property",
        data: {
          user_address_id: selectedProperty?.user_mailing_address?.id,
        },
        token: true,
      })
    );
  };

  // handle cancel subscription
  const handleCancelSubscription = (card_id) => {
    dispatch(
      propertyListAction.cancelSubscription({
        url: `admin/cancel-subscription/${selectedProperty?.subscription?.id}`,
        data: {
          type: "cancel_at_period_end",
          user_id: selectedProperty?.user?.id,
          card_id,
        },
        token: true,
      })
    );
  };

  const cancelSubscription = () => {
    setIsCardListOpen(true);
    setIsCancelSubscriptionModalOpen(false);
  };

  const selectedUserCardListingAPI = () => {
    dispatch(
      userProfileAction.userCardDetails({
        url: `admin/users/${selectedProperty?.owner_id}/card`,
        data: {},
        token: true,
      })
    );
  };

  const openCancelSubscriptionPopup = () => {
    setIsCancelSubscriptionModalOpen(true);
    if (selectedProperty?.payment_required) {
      selectedUserCardListingAPI();
    }
  };

  const cancelLennarSubscriptionModalClose = () => {
    setIsCardListOpen(false);
    setIsCancelSubscriptionModalOpen(false);
    dispatch(propertiesAction.clearCancelSubscriptionStates());
  };
  const handleCancelSubscriptionBtn = (type, card_id) => {
    // handleCancelSubscription(card_id);
    let data = { type: type, user_id: selectedProperty?.user?.id, card_id };
    dispatch(
      propertiesAction.cancelSubscription({
        url: `admin/cancel-subscription/${selectedProperty?.subscription?.id}`,
        data: data,
        token: true,
        propertyAddress: selectedProperty?.address,
      })
    );
  };

  // filter county
  const handleCountyChange = (event, newValue) => {
    const selectedCounty = registeredCountyListData?.data.find(
      (county) => county.county_domain_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setCountyId(selectedCounty.id.toString());
    }
  };

  // handle cancle and refund subscription
  const handleCancelAndRefundSubscription = () => {
    dispatch(
      propertyListAction.cancelSubscription({
        url: `admin/cancel-subscription/${selectedProperty?.subscription?.id}`,
        data: {
          type: "cancel_and_refund",
          user_id: selectedProperty?.user?.id,
        },
        token: true,
      })
    );
  };

  // Resume Subscription Actions
  const handleResumeSubscription = () => {
    dispatch(
      subscriptionAction.getResumeSubscriptionData({
        url: `admin/resume-subscription/${selectedProperty?.subscription?.id}`,
        token: true,
      })
    );
    setIsResumeSubscription(false);
  };

  const closeResumeSubscriptionModal = () => {
    handlePropertyListAPICall();
    dispatch(subscriptionAction.fetchResumeSubscriptionData({}));
  };

  // handle closing change subscription modal
  const handleCloseChangeSubscriptionModal = () => {
    setIsChangeSubscriptionModalOpen(false);
  };

  // handle change subscription plan for the property
  const handleChangeSubscription = (values) => {
    dispatch(
      propertyListAction.changeSubscriptionPlan({
        url: `admin/modify-plan/${selectedProperty?.subscription?.id}`,
        data: {
          next_plan_id: values?.subscription,
        },
        token: true,
      })
    );
  };

  // handle cancel changed subscription
  const handleCancelChangedSubscriptionRequest = () => {
    // call dispatch for canceling changed subscription request
    if (selectedProperty?.subscription !== null) {
      dispatch(
        propertyListAction.cancelChangeSubscriptionRequest({
          url: `admin/cancel-plan-request/${selectedProperty?.subscription?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // handle Add subscription
  const addSubcriptionHandler = () => {
    setAddSubcriptionPopup(true);
    setPropertiesIds([selectedProperty?.property_id]);
    if (!selectedProperty?.provider) {
      setpropertyIDList([
        {
          id: selectedProperty?.id,
          address: selectedProperty?.address,
          property_id: selectedProperty?.property_id,
          city: selectedProperty?.city,
          state: selectedProperty?.state,
          zip: selectedProperty?.zip,
          provider: selectedProperty?.provider,
        },
      ]);
    }
    console.log("setAddSubcription===>", selectedProperty);
  };

  // close delete Property modal
  const handleClosePropertyModal = () => {
    setDeletePropetyModal(false);
  };

  // open Property Requested modal
  const openRequestedDocumentAlert = (action) => {
    console.log("selected property ==>", selectedProperty);
    setAccessRequestedDocumentComp(true);
    if (
      (action === "waiver" && selectedProperty?.status === "Active") ||
      (action === "termination" &&
        selectedProperty?.status !== "Terminated" &&
        selectedProperty?.subscription &&
        selectedProperty?.subscription !== null &&
        (selectedProperty?.subscription?.expired ||
          selectedProperty?.subscription?.payment_status !== "failed"))
    ) {
      if (!getObjLength(statesCodeList)) {
        dispatch(
          userSigupAction.getStateCode({ url: "get-states-code", data: {} })
        );
      }
      setRequestDocumentAction(true);
      if (
        selectedProperty?.requested_by_other_user ||
        selectedProperty?.termination_request
      ) {
        setAlreadyRequested(true);
      } else {
        setAlreadyRequested(false);
      }
    } else {
      setRequestedDocumentAlert(true);
    }
    setRequestType(action);
  };
  const closeRequestedDocumentAlert = () => {
    setAccessRequestedDocumentComp(false);
    setRequestDocumentAction(false);
    setRequestedDocumentAlert(false);
    setAlreadyRequested(false);
    setRequestType(null);
    dispatch(requestDocumentsAction?.setRequestedDocumentDetails({}));
  };

  // handle Edit Property API
  const handleSelectedEditProperty = () => {
    console.log("edit property ==>", selectedProperty);
    dispatch(propertiesAction.setSelectedUserID(selectedProperty?.owner_id));
    localStorage.removeItem("searched_Add_popup");
    setEditPropetyModal(true);

    if (selectedProperty && selectedProperty?.id) {
      dispatch(
        propertiesAction.editPropertiesDetails({
          url: `admin/get-property-address/${selectedProperty?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };
  // handle deleting Property API
  const handleDeleteProperty = () => {
    console.log("==?>", selectedProperty);
    if (selectedProperty && selectedProperty?.id) {
      //   // return console.log("selectedBeneficiary", selectedBeneficiary);
      dispatch(
        propertyUsersAction.getDeletePropertyData({
          url: `admin/delete-property`,
          data: { property_id: selectedProperty?.id },
          token: true,
        })
      );
    }
  };

  // handle security questions API
  const handleSecurityPropertyQuestions = () => {
    console.log("==?>", selectedProperty);
    setSecurityQuestionsPopup(true);
    if (selectedProperty && selectedProperty?.id) {
      dispatch(
        propertiesAction.securityPropertiesQueDetails({
          url: `admin/generate-questions/${selectedProperty?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // Stop Subscription API
  const stopSubscriptionAPI = () => {
    console.log("==?>", selectedProperty);
    if (selectedProperty && selectedProperty?.id) {
      dispatch(
        propertiesAction.setStopSubscriptionData({
          url: `admin/stop-subscription/${selectedProperty?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  const handleCollapsibleRow = (row) => {
    if (selectedCollapsibleRow?.id === row?.id && isCollapsibleRow) {
      setIsCallapsibleRow(false);
      return;
    }
    setIsCallapsibleRow(true);
    setSelectedCollapsibleRow(row);
  };

  const handleRefundRecordingFees = (values) => {
    console.log("Submitted values", { values });
    if (selectedProperty?.first_subscription_id) {
      dispatch(
        propertyListAction.sendRefundRecordingFees({
          url: `admin/subscriptions/${selectedProperty?.first_subscription_id}/refund`,
          data: { amount: parseFloat(values?.amount) },
          token: true,
        })
      );
    }
  };

  const selectProperties = (event, row, isProvider) => {
    let properties_id = row?.property_id;
    let id = row?.id;
    // var subscription_ids = []
    if (
      row?.subscription === null ||
      (row?.subscription && row?.subscription?.cancel_at !== null) ||
      (row?.subscription && row?.subscription?.payment_status === "failed")
    ) {
      if (!event.target.checked) {
        // to remove deselected properties
        if (isProvider) {
          setProvidersIDList(providersIDList?.filter((e) => e.id !== id));
        } else {
          setPropertiesIds(propertiesIds?.filter((e) => e !== properties_id));
          setpropertyIDList(propertyIDList?.filter((e) => e.id !== id));
        }
        setCountyDetails(propertyIDList?.filter((e) => e.id !== id));
      } else {
        // to add selected properties
        if (isProvider) {
          setProvidersIDList((prev) => [
            {
              id: row?.id,
              address: row?.address,
              property_id: row?.property_id,
              city: row?.city,
              state: row?.state,
              zip: row?.zip,
              provider: row?.provider,
              countyDetails: row?.owner?.[0]?.county_details,
            },
          ]);
        } else {
          setPropertiesIds((prev) => [...prev, properties_id]);
          setpropertyIDList((prev) => [
            ...prev,
            {
              id: row?.id,
              address: row?.address,
              property_id: row?.property_id,
              city: row?.city,
              state: row?.state,
              zip: row?.zip,
              countyDetails: row?.owner?.[0]?.county_details,
            },
          ]);
        }
        setCountyDetails((prev) => [
          ...prev,
          {
            id: row?.id,
            countyDetails: row?.owner?.[0]?.county_details,
          },
        ]);
      }
    }

    console.log(properties_id, "selected values==>", event.target.checked);
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", propertyPayload);
    console.log("Sorting flow", directionFlow);
    setPropertyPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  // Subscription plans renewal
  const handleGetEstimatedAmount = (row) => {
    console.log("row==>", row);
    let data;
    if (row) {
      data = {
        property_id: [row?.subscription?.property_id],
      };
    }
    dispatch(
      userSigupAction.getTotalPaymentDetails({
        url: `get-amount`,
        data: data,
        token: true,
      })
    );
    setIsRenewFinalAmtModalOpen(true);
  };

  const handleRenewPlanModalClose = () => {
    dispatch(subscriptionAction.clearData({}));
    setIsRenewPlanModalOpen(false);
  };

  const handleRenewPlan = (selectedCard) => {
    let subId = selectedProperty?.subscription?.id;
    console.log(subId, "selectedCard==>", selectedCard, selectedCard?.[0]?.id);
    if (subId) {
      if (retryPayment) {
        dispatch(
          subscriptionAction.getRetryPaymentData({
            url: `admin/re-payment/${subId}`,
            data: {
              card_id: selectedCard?.[0]?.id,
            },
            token: true,
          })
        );
      } else {
        dispatch(
          subscriptionAction.getSubscriptionRenewed({
            url: `admin/subscription-renew/${subId}?card_id=${selectedCard?.[0]?.id}`,
            token: true,
          })
        );
      }
    }
  };
  const internalAdmins = Array.isArray(internalAdminDetails?.data)
    ? internalAdminDetails?.data
    : [];
  const adminDropdownListData = [
    { key: 1, id: 1, full_name: "Super Admin" },
    ...internalAdmins,
  ];

  const handleInternalAdminChange = (event, newValue) => {
    const selectedSubadmin =
      adminDropdownListData?.find(
        (subadmin) => subadmin?.full_name === newValue
      ) || {};
    if (getObjLength(selectedSubadmin)) {
      setSubAdminId(selectedSubadmin?.id?.toString());
      setSubAdminName(selectedSubadmin?.full_name);
    }
  };

  const onSuccess = () => {
    dispatch(subscriptionAction.clearData({}));
    handlePropertyListAPICall();
  };

  const onCloseMailingAddress = () => {
    setIsUpdateMailingAddress(false);
    dispatch(propertyListAction.fetchUpdateMailingAddressDetails({}));
  };

  // handle successfully send postcard for address verification
  const handleResendPostcard = () => {
    if (selectedProperty?.id) {
      dispatch(
        propertyListAction.resendPostcard({
          url: `sent-postcard/${selectedProperty?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  const getProfessionalList = useCallback(() => {
    dispatch(
      professionalAction.fetchProfessionalListData({
        url: `professional`,
        data: {},
        token: true,
      })
    );
  }, [dispatch]);

  // handle Add Closing by
  const addClosingAdminHandler = () => {
    setClosingAdminPopup(true);
  };

  // close Closing by modal
  const handleAdminCloseModal = () => {
    setClosingAdminPopup(false);
  };

  useEffect(() => {
    if (
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    ) {
      getProfessionalList();
      dispatch(
        countyRecordersAction.registeredCountyList({
          url: `admin/counties`,
          data: {},
          token: true,
        })
      );
    }
    if (
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.provider
      ) &&
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    ) {
      dispatch(
        propertyUsersAction.internalAdminList({
          url: "admin/admins",
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch, getProfessionalList]);

  //  for Bind Professional alert messgaes.
  useEffect(() => {
    if (getObjLength(bindProfessionalDetails)) {
      setAlertMsg(true);
      if (bindProfessionalDetails?.success) {
        setSuccessMsg(bindProfessionalDetails?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(bindProfessionalDetails?.data?.message);
      }
    }
  }, [dispatch, bindProfessionalDetails]);

  // check if verify address id success or not
  useEffect(() => {
    if (getObjLength(verifyAddressDetails)) {
      setIsVerifyAddressResponseModalOpen(true);
    }
  }, [verifyAddressDetails]);

  // stop Subscription Data
  useEffect(() => {
    if (getObjLength(stopSubscriptionData)) {
      console.log("stopSubscriptionData==>", stopSubscriptionData);
    }
  }, [stopSubscriptionData]);

  // when properties select/deselected
  useEffect(() => {
    dispatch(
      propertiesAction.selectedPropertiesData({
        properties_Id: propertiesIds,
        ids: propertyIDList,
        uid: userId,
        provider: providersIDList,
        countyDetails: countyDetail,
      })
    );
  }, [
    dispatch,
    propertiesIds,
    propertyIDList,
    providersIDList,
    userId,
    countyDetail,
  ]);

  // Manage selected property logic
  useEffect(() => {
    if (tabComponent) {
      console.log("propertyListData==>", propertyListData);
      let properties = propertyListData?.data;
      if (properties?.length > 0) {
        console.log("properties==>", properties);
        let pIds = properties?.map((i) =>
          (!i?.subscription || i?.subscription === null) && !i?.provider
            ? i?.property_id
            : null
        );
        setPropertiesIds(pIds.filter((e) => e !== null));
        let ids = properties?.map((i) =>
          (!i?.subscription || i?.subscription === null) && !i?.provider
            ? {
                id: i?.id,
                address: i?.address,
                property_id: i?.property_id,
                state: i?.state,
                city: i?.city,
                zip: i?.zip,
              }
            : null
        );
        let cancelIds = properties?.map((i) =>
          i?.subscription && i?.subscription?.cancel_at !== null && !i?.provider
            ? {
                id: i?.id,
                address: i?.address,
                property_id: i?.property_id,
                city: i?.city,
                state: i?.state,
                zip: i?.zip,
              }
            : null
        );
        let cIds = cancelIds.filter((e) => e !== null);
        // if (cIds.length > 0) {
        //   setpropertyIDList(cIds);
        // }
        let providersIds = properties?.map((i) =>
          (!i?.subscription || i?.subscription === null) && i?.provider
            ? {
                id: i?.id,
                address: i?.address,
                property_id: i?.property_id,
                city: i?.city,
                state: i?.state,
                zip: i?.zip,
                provider: i?.provider,
              }
            : null
        );
        // setpropertyIDList(ids.filter((e) => e !== null));
        let idsList = ids.filter((e) => e !== null);
        if (idsList?.length === 0) {
          console.log("property ids==>", ids);
          setpropertyIDList([]);
          // setProvidersIDList(providersIds.filter((e) => e !== null));
        }
        dispatch(
          propertiesAction.selectedPropertiesData({
            properties_Id: pIds,
            ids: ids,
            // ids: [...ids, ...cIds],
            uid: userId,
            // providersIds: providersIds,
          })
        );
      }
    }
  }, [propertyListData]);

  // logic to add/remove columns
  useEffect(() => {
    if (
      tabComponent &&
      decryptPayload(localStorage.getItem("roles")) !== ROLES?.countyRecorder
    ) {
      console.log("colmss::", !columns.find((e) => e.key === 2));
      if (!columns.find((e) => e.key === 2)) {
        columns.push({
          key: 2,
          id: "Payment",
          disablePadding: true,
          label: "Payment",
          value: "addressss",
          minWidth: 80,
        });
      }
      columns.sort(function (a, b) {
        return a.key - b.key;
      });
    } else {
      columns = columns.filter(function (e) {
        return e.key !== 2;
      });
      console.log("colmss::", columns);
    }
  }, []);

  // subscription renewal
  useEffect(() => {
    if (getObjLength(subscriptionRenewed)) {
      if (subscriptionRenewed?.success) {
        if (subscriptionRenewed?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !subscriptionRenewed?.data?.actionRequired &&
          subscriptionRenewed?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, subscriptionRenewed]);

  useEffect(() => {
    if (getObjLength(retryPaymentData)) {
      if (retryPaymentData?.success) {
        if (retryPaymentData?.data?.actionRequired) {
          setValdiateModal(false);
          return;
        }
        if (
          !retryPaymentData?.data?.actionRequired &&
          retryPaymentData?.data?.length === 0
        ) {
          setValdiateModal(true);
          return;
        }
      } else {
        setValdiateModal(true);
      }
    }
  }, [dispatch, retryPaymentData]);

  useEffect(() => {
    if (
      getObjLength(refundRecordingFeesDetails) &&
      refundRecordingFeesDetails?.success
    ) {
      setIsRefundRecordingFeesModalOpen(false);
    }
  }, [refundRecordingFeesDetails]);

  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );

  const isProvider =
    getObjLength(selectedProperty?.provider) &&
    selectedProperty?.provider?.plans?.length > 0;

  return (
    <>
      {propertyListData?.success ? (
        <>
          {/* Filtering Component Section */}
          {!tabComponent && (
            <>
              <Tooltip title="Filter options" placement="right-start">
                <Box
                  id="filter"
                  onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
                >
                  <h3 className="filter-text">Filter your results</h3>
                  <ArrowDropDownIcon
                    className="filter-arrow"
                    sx={{ ms: 1, fontSize: "20px" }}
                  />
                </Box>
              </Tooltip>
              {/* Filtering Options */}
              <Box
                sx={{
                  // overflow: "hidden",
                  // overflowX: "auto",
                  mb: 1.5,
                }}
              >
                {toggleFilterOptions && (
                  <>
                    {/* for mobile view */}
                    <Box className="d-block d-md-none">
                      <Grid
                        container
                        className="bg-white d-flex justify-content-around align-items-center px-4"
                        spacing={2}
                        pb={2}
                        pt={2}
                      >
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <Typography className="search-text">
                            Search By
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="All Types"
                              value={propertyType}
                              onChange={(e) => {
                                setPropertyType(e.target.value);
                                setPropertyTerm("");
                                setPropertyStatus("");
                                setCountyId("");
                                setPostcardStatus("");
                                setSubscriptionStatus("");
                                setSubscriptionType("");
                                setSelectedDateRange(null);
                                setSelectedDateType("closing_at");
                              }}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              {columns.map(
                                (cell) =>
                                  cell.label !== "No." &&
                                  cell.id !== "created_by" &&
                                  cell.id !== "account_status" &&
                                  cell.id !== "docs" &&
                                  cell.value !== "action" && (
                                    <MenuItem key={cell.id} value={cell.value}>
                                      {cell.label}
                                    </MenuItem>
                                  )
                              )}
                              <MenuItem value={"closing_at"}>Closing</MenuItem>
                              <MenuItem value={"created_by"}>
                                Create by
                              </MenuItem>
                              {!county_admin && (
                                <MenuItem value={"county"}>County</MenuItem>
                              )}
                              <MenuItem value={"status"}>
                                Property Status
                              </MenuItem>
                              <MenuItem value={"transaction_id"}>
                                Transaction ID
                              </MenuItem>
                              <MenuItem value={"subscription"}>
                                Subscription
                              </MenuItem>
                              <MenuItem value={"closing_by"}>
                                Closing By
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        {propertyType !== "status" &&
                          propertyType !== "postcardstatus" &&
                          propertyType !== "subscription" &&
                          propertyType !== "county" &&
                          propertyType !== "updated_at" &&
                          propertyType !== "upcoming_subscription_renewals" &&
                          propertyType !== "closing_at" &&
                          propertyType !== "created_at" && (
                            <Grid item xs={12} sm={12} md={4} lg={2}>
                              <FormControl
                                className="filter-search w-100"
                                variant="outlined"
                              >
                                <InputLabel>Enter search term</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Search />
                                    </InputAdornment>
                                  }
                                  label="Enter search term"
                                  value={propertyTerm}
                                  onChange={(e) =>
                                    setPropertyTerm(e.target.value)
                                  }
                                />
                              </FormControl>
                            </Grid>
                          )}
                        {(propertyType === "all" ||
                          propertyType === "status") && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Property Status"
                                value={propertyStatus}
                                onChange={(e) =>
                                  setPropertyStatus(e.target.value)
                                }
                              >
                                {propertyStatusOptions?.map((status, key) => (
                                  <MenuItem value={status.value}>
                                    {status.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {/* {(propertyType === "all" ||
                      propertyType === "postcardstatus") && (
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <FormControl className="filter-select-one w-100">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Postcard Status"
                            value={postcardStatus}
                            onChange={(e) => setPostcardStatus(e.target.value)}
                          >
                            {postcardStatusOptions.map((data, key) => (
                              <MenuItem value={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )} */}

                        {propertyType === "county" && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="County List"
                                value={countyId}
                                onChange={(e) =>
                                  setCountyId(e.target.value.toString())
                                }
                              >
                                {registeredCountyListData?.data?.map(
                                  (county, key) => (
                                    <MenuItem value={county?.id}>
                                      {county?.county_domain_name}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {(propertyType === "all" ||
                          propertyType === "subscription") && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Subscription Status"
                                value={subscriptionStatus}
                                onChange={(e) => {
                                  setSubscriptionStatus(e.target.value);
                                  fromDashboard = null;
                                }}
                              >
                                {subscriptionOptions?.map((data, key) => (
                                  <MenuItem value={data.value}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {propertyType === "subscription_type" && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Subscription Type"
                                value={subscriptionType}
                                onChange={(e) => {
                                  setSubscriptionType(e.target.value);
                                  fromDashboard = null;
                                }}
                              >
                                <MenuItem value={"monthly"}>Monthly</MenuItem>
                                <MenuItem value={"yearly"}>Yearly</MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {propertyType === "all" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Date type"
                                value={selectedDateType}
                                onChange={(e) => {
                                  setSelectedDateType(e.target.value);
                                }}
                              >
                                {[
                                  { label: "Closing At", value: "closing_at" },
                                  { label: "Created At", value: "created_at" },
                                  { label: "Updated At", value: "updated_at" },
                                  {
                                    label: "Upcoming Renewals",
                                    value: "upcoming_subscription_renewals",
                                  },
                                ].map((data, key) => (
                                  <MenuItem value={data.value}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {(propertyType === "all" ||
                          propertyType === "created_at" ||
                          propertyType === "closing_at" ||
                          propertyType === "upcoming_subscription_renewals" ||
                          propertyType === "updated_at") && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-search w-100">
                              <CustomDateRangePicker
                                rangeValue={selectedDateRange}
                                onChange={(values) => {
                                  setSelectedDateRange(values);
                                }}
                                onClear={() => {
                                  setSelectedDateRange(null);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <FormControl className="w-100">
                            <Button
                              type="submit"
                              onClick={handleFilterProperty}
                              sx={mobileFilterResultStyle}
                            >
                              Filter Results
                            </Button>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Button
                            type="reset"
                            onClick={resetAllFilterOptions}
                            sx={mobileFilterResetBtnStyle}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>

                    {/* for desktop view */}
                    <Card
                      className="d-none d-md-block filter-options-carddiv"
                      // id="filter-options-card"
                    >
                      <Grid container className="filter-options-box">
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <div className="search-text">Search By</div>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="All Types"
                                value={propertyType}
                                onChange={(e) => {
                                  handleAllTypeFilterOption(e);
                                }}
                              >
                                <MenuItem value={"all"}>All</MenuItem>
                                {columns.map(
                                  (cell) =>
                                    cell.label !== "No." &&
                                    cell.id !== "created_by" &&
                                    cell.id !== "account_status" &&
                                    cell.id !== "docs" &&
                                    cell.label !== "Action" && (
                                      <MenuItem
                                        key={cell.id}
                                        value={cell.value}
                                      >
                                        {cell.label}
                                      </MenuItem>
                                    )
                                )}
                                <MenuItem value={"created_at"}>
                                  Created
                                </MenuItem>
                                <MenuItem value={"created_by"}>
                                  Created by
                                </MenuItem>
                                <MenuItem value={"closing_by"}>
                                  Closing by
                                </MenuItem>
                                {!county_admin && (
                                  <MenuItem value={"county"}>County</MenuItem>
                                )}
                                {!decryptPayload(
                                  localStorage.getItem("roles")
                                )?.includes(ROLES.provider) &&
                                  !decryptPayload(
                                    localStorage.getItem("roles")
                                  )?.includes(ROLES.countyRecorder) && (
                                    <MenuItem value={"subadmin"}>
                                      Sub Admin
                                    </MenuItem>
                                  )}
                                <MenuItem value={"status"}>
                                  Property Status
                                </MenuItem>
                                <MenuItem value={"ucc_status"}>
                                  UCC Status
                                </MenuItem>
                                <MenuItem value={"transaction_id"}>
                                  Transaction ID
                                </MenuItem>
                                <MenuItem value={"subscription"}>
                                  Subscription Status
                                </MenuItem>
                                <MenuItem value={"subscription_type"}>
                                  Subscription Type
                                </MenuItem>
                                <MenuItem
                                  value={"upcoming_subscription_renewals"}
                                >
                                  Upcoming Renewals
                                </MenuItem>
                              </TextField>
                            </FormControl>
                          </Stack>
                        </Grid>

                        {propertyType !== "ucc_status" &&
                          propertyType !== "status" &&
                          propertyType !== "postcardstatus" &&
                          propertyType !== "county" &&
                          propertyType !== "subscription" &&
                          propertyType !== "subscription_type" &&
                          propertyType !== "subadmin" &&
                          propertyType !== "updated_at" &&
                          propertyType !== "upcoming_subscription_renewals" &&
                          propertyType !== "closing_at" &&
                          propertyType !== "created_at" && (
                            <Grid item>
                              <FormControl
                                className="filter-search"
                                variant="outlined"
                              >
                                <InputLabel>Enter search term</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Search />
                                    </InputAdornment>
                                  }
                                  label="Enter search term"
                                  value={propertyTerm}
                                  className={`${
                                    propertyTerm ? "highlight_filter_field" : ""
                                  }`}
                                  onChange={(e) =>
                                    setPropertyTerm(e.target.value)
                                  }
                                />
                              </FormControl>
                            </Grid>
                          )}

                        {propertyType === "county" && (
                          <Grid item xs={12} sm={12} md={2} lg={2}>
                            <FormControl className="filter-select-one w-100">
                              <Autocomplete
                                id="asynchronous-demo"
                                disableClearable
                                options={registeredCountyListData?.data.map(
                                  (option) => option?.county_domain_name
                                )}
                                onChange={handleCountyChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="County List"
                                    className={`${
                                      countyId ? "highlight_filter_field" : ""
                                    }`}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )}

                        {(propertyType === "all" ||
                          propertyType === "status") && (
                          <Grid item>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Property Status"
                                value={propertyStatus}
                                className={`${
                                  propertyStatus ? "highlight_filter_field" : ""
                                }`}
                                onChange={(e) =>
                                  setPropertyStatus(e.target.value)
                                }
                              >
                                {propertyStatusOptions?.map((status, key) => (
                                  <MenuItem value={status.value}>
                                    {status.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}

                        {propertyType === "ucc_status" && (
                          <Grid item>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="UCC Status"
                                value={uccStatus}
                                className={`${
                                  uccStatus ? "highlight_filter_field" : ""
                                }`}
                                onChange={(e) => setUCCStatus(e.target.value)}
                              >
                                <MenuItem value={"pending"}>Pending</MenuItem>
                                <MenuItem value={"generated"}>
                                  Generated
                                </MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}

                        {/* {(propertyType === "all" ||
                      propertyType === "postcardstatus") && (
                      <Grid item>
                        <FormControl className="filter-select-one">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Postcard Status"
                            value={postcardStatus}
                            onChange={(e) => setPostcardStatus(e.target.value)}
                          >
                            {postcardStatusOptions.map((data, key) => (
                              <MenuItem value={data.value}>
                                {data.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                    )} */}

                        {(propertyType === "all" ||
                          propertyType === "subscription") && (
                          <Grid item>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Subscription Status"
                                className={`${
                                  subscriptionStatus
                                    ? "highlight_filter_field"
                                    : ""
                                }`}
                                value={subscriptionStatus}
                                onChange={(e) => {
                                  //handleURLParameters();
                                  setSubscriptionStatus(e.target.value);
                                  fromDashboard = null;
                                }}
                              >
                                {subscriptionOptions?.map((data, key) => (
                                  <MenuItem value={data.value}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}

                        {propertyType === "subscription_type" && (
                          <Grid item>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Subscription Type"
                                className={`${
                                  subscriptionType
                                    ? "highlight_filter_field"
                                    : ""
                                }`}
                                value={subscriptionType}
                                onChange={(e) => {
                                  //handleURLParameters();
                                  setSubscriptionType(e.target.value);
                                  fromDashboard = null;
                                }}
                              >
                                <MenuItem value={"monthly"}>Monthly</MenuItem>
                                <MenuItem value={"yearly"}>Yearly</MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}

                        {!decryptPayload(
                          localStorage.getItem("roles")
                        )?.includes(ROLES.countyRecorder) &&
                          !decryptPayload(
                            localStorage.getItem("roles")
                          )?.includes(ROLES.provider) &&
                          (propertyType === "all" ||
                            propertyType === "subadmin") && (
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <FormControl className="filter-select-one w-100">
                                <Autocomplete
                                  id="asynchronous-demo"
                                  value={subAdminName ? subAdminName : ""}
                                  disableClearable
                                  className={`${
                                    subAdminName ? "highlight_filter_field" : ""
                                  }`}
                                  options={
                                    (propertyType === "subadmin"
                                      ? internalAdminDetails?.data?.map(
                                          (option) => option?.full_name
                                        )
                                      : adminDropdownListData?.map(
                                          (option) => option?.full_name
                                        )) || {}
                                  }
                                  onChange={handleInternalAdminChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // label="Sub Admins List"
                                      label={
                                        propertyType === "subadmin"
                                          ? "Sub Admin"
                                          : "Closing By"
                                      }
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          )}

                        {propertyType === "all" && (
                          <Grid item>
                            <FormControl className="filter-select-one">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Date type"
                                value={selectedDateType}
                                className={`${
                                  selectedDateType
                                    ? "highlight_filter_field"
                                    : ""
                                }`}
                                onChange={(e) => {
                                  setSelectedDateType(e.target.value);
                                }}
                              >
                                {[
                                  { label: "Closing At", value: "closing_at" },
                                  { label: "Created At", value: "created_at" },
                                  { label: "Updated At", value: "updated_at" },
                                  {
                                    label: "Upcoming Renewals",
                                    value: "upcoming_subscription_renewals",
                                  },
                                ].map((data, key) => (
                                  <MenuItem value={data.value}>
                                    {data.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </Grid>
                        )}
                        {(propertyType === "all" ||
                          propertyType === "created_at" ||
                          propertyType === "closing_at" ||
                          propertyType === "upcoming_subscription_renewals" ||
                          propertyType === "updated_at") && (
                          <Grid item>
                            <FormControl className="filter-search">
                              <CustomDateRangePicker
                                sx={{ paddingRight: "0px" }}
                                rangeValue={selectedDateRange}
                                onChange={(values) => {
                                  setSelectedDateRange(values);
                                }}
                                onClear={() => {
                                  setSelectedDateRange(null);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <FormControl>
                              <Button
                                className="filter-button"
                                onClick={handleFilterProperty}
                              >
                                Filter Results
                              </Button>
                            </FormControl>
                            <Button
                              className="reset-filters"
                              onClick={resetAllFilterOptions}
                            >
                              Reset
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Card>
                  </>
                )}
              </Box>
            </>
          )}

          {/* Property Listing Table */}
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead
                    handleSorting={handleSorting}
                    directionFlow={directionFlow}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                    tabComponent={tabComponent}
                  />
                  <TableBody>
                    {propertyListData?.data?.map((row, index) => {
                      return (
                        <Row
                          key={(page - 1) * 10 + index + 1}
                          row={row}
                          open={open}
                          handleMenuClick={handleMenuClick}
                          page={page}
                          index={index}
                          tabComponent={
                            tabComponent &&
                            decryptPayload(localStorage.getItem("roles")) !==
                              ROLES?.countyRecorder
                          }
                          handleCollapsibleRow={handleCollapsibleRow}
                          isCollapsible={
                            selectedCollapsibleRow?.id === row?.id &&
                            isCollapsibleRow
                          }
                          handleOpenDocumentListModal={
                            handleOpenDocumentListModal
                          }
                          setSelectedProperty={setSelectedProperty}
                          setPropertiesIds={setPropertiesIds}
                          selectProperties={selectProperties}
                          setpropertyIDList={setpropertyIDList}
                          propertyIDList={propertyIDList}
                          check={check}
                          setCheck={setCheck}
                          checkedItems={checkedItems}
                          setCheckedItems={setCheckedItems}
                          handleCheckboxChange={handleCheckboxChange}
                          providersIDList={providersIDList}
                          userId={userId}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                {!propertyListData?.data.length > 0 && (
                  <Typography className="p-5 text-center" variant="h6">
                    No Data Found.
                  </Typography>
                )}
              </TableContainer>
            </Paper>
            {propertyListDataCount > PER_PAGE && (
              <Box id="pagination-container">
                <CustomPagination
                  count={count}
                  page={page}
                  handlePageChange={handlePageChange}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Typography className="text-center text-danger">
            {propertyListData?.data?.message?.length > 0
              ? propertyListData?.data?.message
              : propertyListData?.message?.length > 0
              ? propertyListData?.message
              : ""}
          </Typography>
        </>
      )}

      <Loader
        open={
          propertiesLoader ||
          professionalListLoader ||
          propertyLoader ||
          subscriptionLoader ||
          internalAdminLoader
        }
      />
      <Menu
        key="edit"
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            dispatch(
              propertiesAction.setViewDetailsPropertyData(selectedProperty)
            );
            handleOpenViewPropertyDetailModal();
          }}
        >
          <Box className="setting-menu-box">
            <RemoveRedEyeOutlinedIcon />
            <Typography className="setting-menu-text">View</Typography>
          </Box>
        </MenuItem>

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.status === "Pending" &&
            selectedProperty?.subscription?.payment_status !== "failed" &&
            selectedProperty?.subscription !== null
            ? !selectedProperty?.user_mailing_address?.is_verified && (
                <>
                  <Tooltip
                    title={
                      selectedProperty?.user_mailing_address === null
                        ? "The mailing address for this property could not be found."
                        : ""
                    }
                    TransitionComponent={Zoom}
                    placement="left"
                    arrow
                    disableFocusListener
                  >
                    <MenuItem
                      className="setting-menu-item"
                      onClick={() => {
                        if (selectedProperty?.user_mailing_address === null) {
                          return;
                        }
                        setIsVerifyAddressModalOpen(true);
                      }}
                      // disabled={selectedProperty?.user_address === null}
                    >
                      <Box className="setting-menu-box">
                        <AssuredWorkloadOutlinedIcon color="#D9D9D9" />
                        <Typography className="setting-menu-text">
                          Verify Address
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Tooltip>
                </>
              )
            : null
          : null}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.status === "Verified" &&
            (selectedProperty?.subscription?.expired === true ||
              selectedProperty?.subscription?.cancel_at === null) && (
              <MenuItem
                className="setting-menu-item"
                onClick={() => handlePendingStatus(selectedProperty)}
              >
                <Box className="setting-menu-box">
                  <PendingActionsOutlined
                    style={{ width: "18px", height: "18px", color: "#15BE53" }}
                  />
                  <Typography className="setting-menu-text">
                    Generate UCC document
                  </Typography>
                </Box>
              </MenuItem>
            )
          : null}

        {/* {selectedProperty?.status === "Verified" && (
          <MenuItem
            className="setting-menu-item-wrap"
            onClick={() => handlePendingRecorderStatus(selectedProperty)}
          >
            <Box className="setting-menu-box-wrap">
              <TaskAltOutlined
                style={{ width: "18px", height: "18px", color: "#15BE53" }}
              />
              <Typography className="setting-menu-text-wrap">
                Make Secure
              </Typography>
            </Box>
          </MenuItem>
        )} */}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.status === "Verified" &&
            (selectedProperty?.subscription?.expired === true ||
              selectedProperty?.subscription?.cancel_at === null) && (
              <MenuItem
                className="setting-menu-item"
                onClick={() => {
                  handlePendingRecorderStatus(selectedProperty);
                }}
              >
                <Box className="setting-menu-box">
                  <UploadFileIcon color="#D9D9D9" />
                  <Typography className="setting-menu-text">
                    Upload UCC
                  </Typography>
                </Box>
              </MenuItem>
            )
          : null}

        {
          // !selectedProperty?.added_by_other_user ?
          selectedProperty?.latest_ucc?.length > 0 && (
            <MenuItem
              className="setting-menu-item"
              onClick={() => {
                handleReplaceUCCForm(selectedProperty);
              }}
            >
              <Box className="setting-menu-box">
                <UploadFileIcon color="#D9D9D9" />
                <Typography className="setting-menu-text">
                  Replace UCC Document
                </Typography>
              </Box>
            </MenuItem>
          )
          // : null
        }

        {!selectedProperty?.added_by_other_user
          ? (!selectedProperty?.subscription ||
              selectedProperty?.subscription === null ||
              (!selectedProperty?.subscription?.expired &&
                (selectedProperty?.subscription?.payment_status === "failed" ||
                  selectedProperty?.subscription?.cancel_at !== null ||
                  !selectedProperty?.subscription?.is_active))) &&
            //&&  selectedProperty?.subscription_count === 1
            (new Date(selectedProperty?.subscription?.end_at) > new Date() &&
            !selectedProperty?.subscription?.sub_payments?.refund &&
            selectedProperty?.subscription?.cancel_at !== null &&
            !selectedProperty?.terminated ? (
              <MenuItem
                key="add_cards"
                onClick={() => setIsResumeSubscription(true)}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <AutorenewOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Resume Subscription
                  </Typography>
                </Box>
              </MenuItem>
            ) : (
              <MenuItem
                key="add_cards"
                onClick={addSubcriptionHandler}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <AccountBalanceWalletOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Add Subscription
                  </Typography>
                </Box>
              </MenuItem>
            ))
          : null}

        {decryptPayload(localStorage.getItem("roles"))?.includes(
          ROLES?.admin
        ) && (
          <MenuItem
            key="add_cards"
            onClick={addClosingAdminHandler}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <PeopleAltOutlinedIcon />
              <Typography className="setting-menu-text">
                {getObjLength(selectedProperty?.closing_by) ? "Update" : "Add"}{" "}
                Closing By
              </Typography>
            </Box>
          </MenuItem>
        )}
        {!selectedProperty?.added_by_other_user &&
        !selectedProperty?.terminated ? (
          (selectedProperty?.subscription ||
            selectedProperty?.subscription !== null) &&
          !selectedProperty?.subscription?.is_active &&
          selectedProperty?.subscription?.expired &&
          selectedProperty?.subscription?.payment_status === "failed" &&
          selectedProperty?.subscription?.cancel_at === null ? (
            //&& selectedProperty?.subscription_count > 1
            <MenuItem
              key="add_cards"
              onClick={() => {
                if (
                  selectedProperty?.subscription?.expired === false &&
                  selectedProperty?.subscription?.payment_status === "failed"
                ) {
                  setRetryPayment(true);
                } else {
                  setRetryPayment(false);
                }
                handleGetEstimatedAmount(selectedProperty);
              }}
              className="setting-menu-item"
            >
              <Box className="setting-menu-box">
                <RestoreOutlinedIcon />
                <Typography className="setting-menu-text">
                  Renew Subscription
                </Typography>
              </Box>
            </MenuItem>
          ) : null
        ) : null}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.status !== "Active" && (
              <MenuItem
                key="edit_property"
                onClick={handleSelectedEditProperty}
                className="setting-menu-item"
              >
                <Box className="setting-menu-box">
                  <HomeWorkOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Edit Property
                  </Typography>
                </Box>
              </MenuItem>
            )
          : null}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.is_verified === 0 && (
              <>
                <Tooltip
                  disableInteractive
                  title={
                    "Use this option to verify the property as the user skipped the security questions."
                  }
                  TransitionComponent={Zoom}
                  placement="top"
                  arrow
                >
                  <MenuItem
                    key="security_questions"
                    onClick={handleSecurityPropertyQuestions}
                    className="setting-menu-item"
                  >
                    <Box className="setting-menu-box">
                      <GppGoodOutlinedIcon />
                      <Typography className="setting-menu-text">
                        Verify Property
                      </Typography>
                    </Box>
                  </MenuItem>
                </Tooltip>

                {selectedProperty?.subscription !== null &&
                  selectedProperty?.is_verified === 0 &&
                  selectedProperty?.subscription?.cancel_at === null && (
                    <Tooltip
                      disableInteractive
                      title={
                        "Use this option when the user has not confirmed their ownership of a property and is attempting to fraudulently add the property."
                      }
                      TransitionComponent={Zoom}
                      placement="top"
                      arrow
                    >
                      <MenuItem
                        key="security_questions"
                        onClick={stopSubscriptionAPI}
                        className="setting-menu-item"
                      >
                        <Box className="setting-menu-box">
                          <StopCircleOutlinedIcon />
                          <Typography className="setting-menu-text">
                            Report Fake and Stop Subscription
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Tooltip>
                  )}
              </>
            )
          : null}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.status === "Active" && (
              <MenuItem
                className="setting-menu-item"
                onClick={handleEditPropertyEvent}
              >
                <Box className="setting-menu-box">
                  {/* <Box className="setting-menu-box"> */}
                  <AssignmentIndOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Assign Professional
                  </Typography>
                </Box>
              </MenuItem>
            )
          : null}

        {selectedProperty?.status === "Pending" &&
        selectedProperty?.subscription?.expired === false &&
        selectedProperty?.subscription?.is_active &&
        selectedProperty?.subscription?.cancel_at === null ? (
          <MenuItem
            className="setting-menu-item"
            onClick={() => {
              setIsResendPostcardModalOpen(true);
            }}
          >
            <Box className="setting-menu-box">
              {/* <Box className="setting-menu-box"> */}
              <ScheduleSendOutlinedIcon />
              <Typography className="setting-menu-text">
                Resend Postcard
              </Typography>
            </Box>
          </MenuItem>
        ) : null}

        {selectedProperty?.subscription === null ||
        (selectedProperty?.subscription !== null &&
          !selectedProperty?.subscription?.is_active) ? (
          <MenuItem
            key="delete_property"
            onClick={() => {
              dispatch(propertyUsersAction.clearDeletePropertyData({}));
              console.log("selectedRow==>", selectedProperty);
              setDeletePropetyModal(true);
            }}
            className="setting-menu-item"
          >
            <Box className="setting-menu-box">
              <DeleteOutlineIcon />
              <Typography className="setting-menu-text">
                Delete Property
              </Typography>
            </Box>
          </MenuItem>
        ) : null}

        {/* selectedProperty?.owner?.[0]?.is_active  */}
        {!selectedProperty?.added_by_other_user &&
        !selectedProperty?.terminated ? (
          // selectedProperty?.subscription?.expired === false &&
          //  selectedProperty?.subscription?.is_active &&
          //  selectedProperty?.subscription?.cancel_at === null &&
          //  selectedProperty?.user_mailing_address?.is_verified &&
          ((selectedProperty?.subscription?.expired === false &&
            selectedProperty?.subscription?.is_active) ||
            selectedProperty?.subscription?.cancel_at ||
            selectedProperty?.subscription?.expired) &&
          //  !isNaN(parseFloat(selectedProperty?.paid_recording_charge)) &&
          parseFloat(selectedProperty?.paid_recording_charge || 0) -
            parseFloat(selectedProperty?.refunded_recording_charge || 0) >
            0 ? (
            //&&  selectedProperty?.subscription?.refund_subscription === null
            <MenuItem
              className="setting-menu-item"
              onClick={() => {
                setIsRefundRecordingFeesModalOpen(true);
              }}
            >
              <Box className="setting-menu-box">
                <PublishedWithChangesOutlinedIcon />
                <Typography className="setting-menu-text">
                  Refund Recording Fees
                </Typography>
              </Box>
            </MenuItem>
          ) : null
        ) : null}

        {!selectedProperty?.added_by_other_user &&
        !selectedProperty?.terminated ? (
          !selectedProperty?.is_change_subscription_request ? (
            selectedProperty?.subscription?.expired === false &&
            selectedProperty?.subscription?.cancel_at === null &&
            selectedProperty?.subscription?.is_active &&
            selectedProperty?.subscription?.next_plan_id === null ? (
              <MenuItem
                className="setting-menu-item"
                onClick={() => {
                  setIsChangeSubscriptionModalOpen(true);
                }}
              >
                <Box className="setting-menu-box">
                  <ChangeCircleOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Change Subscription
                  </Typography>
                </Box>
              </MenuItem>
            ) : null
          ) : null
        ) : null}

        {!selectedProperty?.added_by_other_user
          ? selectedProperty?.subscription?.next_plan_id && (
              <MenuItem
                className="setting-menu-item"
                onClick={() => {
                  setIsCancelChangeSubscriptionRequestModalOpen(true);
                }}
              >
                <Box className="setting-menu-box">
                  <EventBusyOutlinedIcon />
                  <Typography className="setting-menu-text">
                    Cancel Change Subscription Request
                  </Typography>
                </Box>
              </MenuItem>
            )
          : null}

        {!selectedProperty?.added_by_other_user &&
        !selectedProperty?.terminated ? (
          selectedProperty?.subscription?.expired === false &&
          selectedProperty?.subscription?.is_active &&
          selectedProperty?.subscription?.cancel_at === null ? (
            <MenuItem
              className="setting-menu-item"
              onClick={openCancelSubscriptionPopup}
            >
              <Box className="setting-menu-box">
                <HighlightOffOutlinedIcon />
                <Typography className="setting-menu-text">
                  Cancel Subscription
                </Typography>
              </Box>
            </MenuItem>
          ) : null
        ) : null}

        <MenuItem
          className="setting-menu-item"
          onClick={() => openRequestedDocumentAlert("termination")}
        >
          <Box className="setting-menu-box">
            <RemoveCircleOutlineOutlinedIcon />
            <Typography className="setting-menu-text">
              Request Terminatation
            </Typography>
          </Box>
        </MenuItem>

        {/* <MenuItem
          className="setting-menu-item"
          onClick={() => openRequestedDocumentAlert("waiver")}
        >
          <Box className="setting-menu-box">
            <FlagOutlinedIcon />
            <Typography className="setting-menu-text">
              Request Waiver
            </Typography>
          </Box>
        </MenuItem> */}
        <MenuItem
          className="setting-menu-item"
          onClick={() => {
            // dispatch(
            //   propertiesAction.setViewDetailsPropertyData(selectedProperty)
            // );
            // handleOpenViewPropertyDetailModal();
            setIsGeneralDocumentsModalOpen(true);
          }}
        >
          <Box className="setting-menu-box">
            <UploadFileOutlinedIcon />
            <Typography className="setting-menu-text">
              Upload Documents
            </Typography>
          </Box>
        </MenuItem>
        {selectedProperty?.user_mailing_address &&
          selectedProperty?.owner?.[0]?.json_last_step?.step > 3 &&
          decryptPayload(localStorage.getItem("roles"))?.includes(
            ROLES.admin
          ) && (
            <MenuItem
              className="setting-menu-item"
              onClick={() => {
                // dispatch(
                //   propertiesAction.setViewDetailsPropertyData(selectedProperty)
                // );
                // handleOpenViewPropertyDetailModal();
                setIsUpdateMailingAddress(true);
              }}
            >
              <Box className="setting-menu-box">
                <MarkunreadIcon />
                <Typography className="setting-menu-text">
                  {selectedProperty?.user_mailing_address ? "Update" : "Add"}{" "}
                  Mailing Address
                </Typography>
              </Box>
            </MenuItem>
          )}
      </Menu>

      {isPendingStatusModalOpen && (
        <PropertyRequestDocumentModal
          isPropertyVerified={selectedProperty?.is_verified}
          open={isPendingStatusModalOpen}
          handleRequestDocBtn={handlePropertyDocRequestBtn}
          handleClose={() => {
            handleCloseModal();
          }}
          selectedProperty={selectedProperty}
          requestLoader={propertiesLoader}
          isRequestSuccess={propertyRequestDocument?.success}
          successMessage={propertyRequestDocument?.message}
          errorMessage={propertyRequestDocument?.data?.message}
          fetchPropertyListingData={() => {
            handlePropertyListAPICall();
          }}
        />
      )}
      {/* upload UCC */}
      {isPendingRecorderStatusModalOpen && (
        <UploadRequestDocModal
          open={isPendingRecorderStatusModalOpen}
          selectedProperty={selectedProperty}
          isPropertyVerified={selectedProperty?.is_verified}
          handleClose={() => {
            handleCloseModal();
          }}
          isUploadSuccess={uploadedPropertyDocuments?.success}
          successMessage={uploadedPropertyDocuments?.message}
          errorMessage={uploadedPropertyDocuments?.data?.message}
          uploadLoader={propertiesLoader}
          uploadedDocData={uploadedPropertyDocuments}
          handleUploadDoc={(value) => {
            handleUploadDocuments(value, false);
          }}
          fetchPropertyListingData={() => {
            handlePropertyListAPICall();
          }}
          showUCCFields={true}
          uploadUCC={true}
        />
      )}
      {/* Upload genenral documents */}
      {isGeneralDocumentsModalOpen && (
        <UploadRequestDocModal
          open={isGeneralDocumentsModalOpen}
          handleClose={() => {
            handleCloseModal();
          }}
          isUploadSuccess={uploadedPropertyGeneralDocuments?.success}
          successMessage={uploadedPropertyGeneralDocuments?.message}
          errorMessage={uploadedPropertyGeneralDocuments?.data?.message}
          uploadLoader={propertiesLoader}
          uploadedDocData={uploadedPropertyGeneralDocuments}
          handleUploadDoc={handleUploadGeneralDocuments}
          fetchPropertyListingData={() => {
            handlePropertyListAPICall();
          }}
        />
      )}
      {/* Replace UCC */}
      {isReplaceUCCModalOpen && (
        <UploadRequestDocModal
          open={isReplaceUCCModalOpen}
          selectedData={selectedProperty}
          handleClose={() => {
            handleCloseModal();
          }}
          isUploadSuccess={uploadedPropertyDocuments?.success}
          successMessage={uploadedPropertyDocuments?.message}
          errorMessage={uploadedPropertyDocuments?.data?.message}
          uploadLoader={propertiesLoader}
          uploadedDocData={uploadedPropertyDocuments}
          handleUploadDoc={(value) => {
            handleUploadDocuments(value, true);
          }}
          fetchPropertyListingData={() => {
            handlePropertyListAPICall();
          }}
          showUCCFields={true}
        />
      )}
      {/* view Admin property detail modal */}
      <AdminPropertyViewDetailsModal
        open={isViewPropertyDetailModalOpen}
        // open={false}
        handleClose={handleCloseViewPropertyDetailModal}
        selectedProperty={selectedProperty}
      />
      <EditPropertyModal
        alertMsg={alertMsg}
        setAlertMsg={setAlertMsg}
        getPropertyListData={handlePropertyListAPICall}
        open={isEditPropertyModalOpen}
        handleClose={() => {
          handleCloseModal();
        }}
        handleBindProfessional={handleBindProfessional}
        selectedItem={selectedProperty}
      />
      {/* document list modal */}
      <DocumnetListModal
        open={documnetListModal}
        handleClose={() => {
          handleCloseModal();
        }}
        editUCC={() => handleReplaceUCCForm(selectedProperty)}
        selectedData={selectedProperty}
        // files={selectedProperty?.verified_documents}
        files={selectedProperty?.latest_ucc}
        generalFiles={selectedProperty?.general_documents}
      />

      {/* cancel subscription modal */}
      {isCancelSubscriptionModalOpen && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={isCancelSubscriptionModalOpen}
            onClose={true}
            onCloseFun={() => {
              setIsCancelSubscriptionModalOpen(false);
            }}
            mesage={
              <>
                {getObjLength(canceledSubscriptionDetails) ? (
                  canceledSubscriptionDetails?.success ? (
                    <AlertDialog
                      type="Success"
                      openPopup={true}
                      onClose={true}
                      onCloseFun={() => {
                        setIsCancelSubscriptionModalOpen(false);
                        handlePropertyListAPICall();
                        dispatch(
                          propertyListAction.setCanceledSubscription({})
                        );
                        cancelLennarSubscriptionModalClose();
                      }}
                      mesage={canceledSubscriptionDetails?.message}
                      footer={false}
                    />
                  ) : (
                    <AlertDialog
                      type="Error"
                      openPopup={true}
                      onClose={true}
                      onCloseFun={() => {
                        dispatch(
                          propertyListAction.setCanceledSubscription({})
                        );
                      }}
                      mesage={canceledSubscriptionDetails?.data?.message}
                      footer={false}
                    />
                  )
                ) : (
                  <>
                    <p>Are you sure you want to cancel the subscription?</p>
                    {selectedProperty?.payment_required && (
                      <b>
                        The cancellation charge of this property is{" "}
                        {currencyFormatter(
                          selectedProperty?.recording_fees || 0
                        )}
                        .
                      </b>
                    )}
                  </>
                )}
              </>
            }
            loader={cancelSubscriptionLoader}
            buttons={
              <>
                {selectedProperty?.provider_id &&
                selectedProperty?.payment_required ? (
                  <>
                    <Button
                      variant="link"
                      onClick={() => cancelSubscription()}
                      className="text-danger mx-2 mb-2"
                    >
                      Cancel Subscription
                    </Button>
                  </>
                ) : (
                  <>
                    {selectedProperty?.can_refund && (
                      <Button
                        variant="link"
                        className="text-success mx-2 mb-2"
                        onClick={() => handleCancelAndRefundSubscription()}
                      >
                        Cancel and Refund
                      </Button>
                    )}

                    <Button
                      variant="link"
                      className="text-danger mx-2 mb-2"
                      onClick={() => handleCancelSubscription()}
                    >
                      Cancel Subscription
                    </Button>
                  </>
                )}
              </>
            }
            footer={false}
          />
        </>
      )}

      {isCardListOpen && (
        <CancelLennarSubscriptionModal
          isCardsOpen={isCardListOpen}
          open={isCardListOpen}
          selectedProperty={selectedProperty}
          cardListingAPI={selectedUserCardListingAPI}
          callListingAPI={handlePropertyListAPICall}
          handleClose={cancelLennarSubscriptionModalClose}
          handleCancelSubscriptionBtn={handleCancelSubscriptionBtn}
          from={"add-property"}
          loader={cancelSubscriptionLoader}
        />
      )}

      {/* {getObjLength(canceledSubscriptionDetails) &&
        (canceledSubscriptionDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              setIsCancelSubscriptionModalOpen(false);
              getPropertyListData();
              dispatch(propertyListAction.setCanceledSubscription({}));
              cancelLennarSubscriptionModalClose();
            }}
            mesage={canceledSubscriptionDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyListAction.setCanceledSubscription({}));
            }}
            mesage={canceledSubscriptionDetails?.data?.message}
            footer={false}
          />
        ))} */}

      {/* change subscription modal */}
      <ChangeSubscriptionPlanModal
        open={isChangeSubscriptionModalOpen}
        handleClose={handleCloseChangeSubscriptionModal}
        selectedProperty={selectedProperty}
        handleChangeSubscription={handleChangeSubscription}
      />

      {/* handle success and error message for changing the subscription */}
      {getObjLength(changedSubscriptionDetails) &&
        (changedSubscriptionDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyListAction.setChangedSubscriptionPlan({}));
              handleCloseChangeSubscriptionModal();
              handlePropertyListAPICall();
            }}
            mesage={changedSubscriptionDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyListAction.setChangedSubscriptionPlan({}));
            }}
            mesage={changedSubscriptionDetails?.data?.message}
            footer={false}
          />
        ))}

      {isVerifyAddressModalOpen && (
        <>
          <AlertDialog
            type="Info"
            openPopup={isVerifyAddressModalOpen}
            onClose={true}
            onCloseFun={() => {
              setIsVerifyAddressModalOpen(false);
            }}
            mesage={"Are you sure you want to verify this address?"}
            loader={verifyAddressLoader}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() => setIsVerifyAddressModalOpen(false)}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => handleVerifyAddress()}
                >
                  Yes
                </Button>
              </>
            }
            footer={false}
          />
        </>
      )}

      {/* success and error modal for verifying address */}
      {isVerifyAddressResponseModalOpen &&
        getObjLength(verifyAddressDetails) && (
          <>
            {getObjLength(verifyAddressDetails) &&
            verifyAddressDetails?.success ? (
              <AlertDialog
                type="Success"
                openPopup={isVerifyAddressResponseModalOpen}
                onClose={true}
                onCloseFun={() => {
                  handleCloseModal();
                  handlePropertyListAPICall();
                }}
                mesage={verifyAddressDetails?.message}
                footer={false}
              />
            ) : (
              <AlertDialog
                type="Error"
                openPopup={isVerifyAddressResponseModalOpen}
                onClose={true}
                onCloseFun={() => {
                  setIsVerifyAddressResponseModalOpen(false);
                  dispatch(propertyListAction.setVerifyAddressDetails({}));
                }}
                mesage={verifyAddressDetails?.data?.message}
                footer={false}
              />
            )}
          </>
        )}

      {/* {alertMsg && getObjLength(bindProfessionalDetails) && (
        <>
          {getObjLength(bindProfessionalDetails) &&
          bindProfessionalDetails?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                handleCloseModal();
                getPropertyListData();
              }}
              mesage={successMsg}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                // dispatch(addLeadsAction.clearData({}));
              }}
              mesage={errMsg}
              footer={false}
            />
          )}
        </>
      )} */}

      {/* cancel changed subscription request */}
      {isCancelChangeSubscriptionRequestModalOpen ? (
        <>
          <AlertDialog
            type="Warning"
            openPopup={isCancelChangeSubscriptionRequestModalOpen}
            onClose={true}
            onCloseFun={() => {
              setIsCancelChangeSubscriptionRequestModalOpen(false);
            }}
            mesage={
              "Are you sure you want to cancel change subscription request?"
            }
            loader={cancelChangeSubscriptionRequestLoader}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() =>
                    setIsCancelChangeSubscriptionRequestModalOpen(false)
                  }
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => handleCancelChangedSubscriptionRequest()}
                >
                  Yes
                </Button>
              </>
            }
            footer={false}
          />
        </>
      ) : null}

      {/* success or error modal for cancel change subscription request */}
      {getObjLength(cancelChangeSubscriptionRequestDetails) ? (
        cancelChangeSubscriptionRequestDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(
                propertyListAction.setCancelChangeSubscriptionRequest({})
              );
              setIsCancelChangeSubscriptionRequestModalOpen(false);
              handlePropertyListAPICall();
            }}
            mesage={cancelChangeSubscriptionRequestDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(
                propertyListAction.setCancelChangeSubscriptionRequest({})
              );
            }}
            mesage={
              cancelChangeSubscriptionRequestDetails?.data?.message ||
              "Something went wrong."
            }
            footer={false}
          />
        )
      ) : null}

      {/* Edit PRoperty Details */}
      {editPropetyModal && (
        <EditPropertyDetails
          openPopup={editPropetyModal}
          closePopup={setEditPropetyModal}
          selectedProperty={selectedProperty}
          callListingAPI={handlePropertyListAPICall}
        />
      )}
      {/* Delete Property */}
      <DeleteModal
        open={deletePropetyModal}
        title="Delete Property"
        item="Property"
        deleteLoader={deletePropertyLoader}
        handleClose={handleClosePropertyModal}
        handleDeleteBtn={handleDeleteProperty}
        fetchedData={deletePropertyData}
        isDeleteSuccess={deletePropertyData?.success}
        successMessage={deletePropertyData?.message}
        errorMessage={deletePropertyData?.data?.message}
        callListingAPI={handlePropertyListAPICall}
      />

      {/* refund recording fees modal */}
      <RefundRecordingFeesModal
        open={isRefundRecordingFeesModalOpen}
        handleClose={() => setIsRefundRecordingFeesModalOpen(false)}
        selectedProperty={selectedProperty}
        handleRefundRecordingFees={handleRefundRecordingFees}
      />

      {/* handle success and error message for refund recording fees */}
      {getObjLength(refundRecordingFeesDetails) &&
        (refundRecordingFeesDetails?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyListAction.setRefundRecordingFeesDetails({}));
              setIsRefundRecordingFeesModalOpen(false);
              handlePropertyListAPICall();
            }}
            mesage={refundRecordingFeesDetails?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertyListAction.setRefundRecordingFeesDetails({}));
            }}
            mesage={
              refundRecordingFeesDetails?.data?.message ||
              refundRecordingFeesDetails?.message
            }
            footer={false}
          />
        ))}

      {/* handle success and error message for stop Subscription  */}
      {getObjLength(stopSubscriptionData) &&
        (stopSubscriptionData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertiesAction.fetchStopSubscriptionDetails({}));
              handlePropertyListAPICall();
            }}
            mesage={stopSubscriptionData?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(propertiesAction.fetchStopSubscriptionDetails({}));
            }}
            mesage={
              stopSubscriptionData?.data?.message ||
              stopSubscriptionData?.message
            }
            footer={false}
          />
        ))}

      {/* Add subscription Modal */}
      {addSubcriptionPopup && (
        <DialogBox
          fullScreenModal={!isProvider ? true : false}
          width={`${selectedProperty?.provider?.plans ? "md" : "lg"}`}
          contentClassName={"bg-white"}
          openPopup={addSubcriptionPopup}
          setOpenPopup={setAddSubcriptionPopup}
          onClose={() => {
            handleAddsubscriptionCLoseModal();
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <AddSubcription
              selectedData={selectedProperty}
              callListingAPI={handlePropertyListAPICall}
              closePopup={handleAddsubscriptionCLoseModal}
              propertiesIds={propertiesIds}
              ids={propertyIDList}
              uid={selectedProperty?.owner_id}
              tabComponent={tabComponent}
              offsetNum={offsetNum}
              isProvider={isProvider}
            />
          }
        />
      )}

      {/* Add security Questions Modal */}
      {securityQuestionsPopup && (
        <DialogBox
          width={skippedOrSubmitted ? "sm" : "lg"}
          contentClassName={"bg-white"}
          openPopup={securityQuestionsPopup}
          setOpenPopup={setSecurityQuestionsPopup}
          onClose={() => {
            setSecurityQuestionsPopup(false);
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <SecurityQuestionsVerification
              setSkippedorSubmitted={setSkippedorSubmitted}
              selectedData={selectedProperty}
              callListingAPI={handlePropertyListAPICall}
              closePopup={setSecurityQuestionsPopup}
              propertiesIds={propertiesIds}
              ids={propertyIDList}
              uid={selectedProperty?.owner_id}
              tabComponent={tabComponent}
            />
          }
        />
      )}

      {/* Renew plan modal */}
      <RenewPlanModal
        open={isRenewPlanModalOpen}
        loader={subscriptionLoader}
        handleClose={handleRenewPlanModalClose}
        onSubmit={handleRenewPlan}
        isRenewFinalAmtModalOpen={isRenewFinalAmtModalOpen}
        setIsRenewFinalAmtModalOpen={setIsRenewFinalAmtModalOpen}
        // for 3d secure card paymnet
        paymnetData={retryPayment ? retryPaymentData : subscriptionRenewed}
        user_id={selectedProperty?.subscription?.user_id}
        onSuccess={onSuccess}
        setIsRenewPlanModalOpen={setIsRenewPlanModalOpen}
        selectedRow={selectedProperty}
        fromAdmin={true}
        callListingAPI={handlePropertyListAPICall}
        callListAPI={true}
      />

      {/* Subsciption Renewal */}
      {getObjLength(subscriptionRenewed) && valdiateModal ? (
        <AlertDialog
          type={subscriptionRenewed?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            // if (subscriptionRenewed?.success) {
            onSuccess();
            handleRenewPlanModalClose();
            // } else {
            //   dispatch(subscriptionAction.clearData({}));
            // }
          }}
          mesage={
            subscriptionRenewed?.success
              ? subscriptionRenewed?.message
              : subscriptionRenewed?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      {getObjLength(retryPaymentData) && valdiateModal ? (
        <AlertDialog
          type={retryPaymentData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            if (retryPaymentData?.success) {
              onSuccess();
              handleRenewPlanModalClose();
            } else {
              dispatch(subscriptionAction.clearData({}));
            }
          }}
          mesage={
            retryPaymentData?.success
              ? retryPaymentData?.message
              : retryPaymentData?.data?.message || "Something went wrong"
          }
        />
      ) : null}

      {/* Resend Postcard confirmation modal */}
      <ResendInvitationModal
        open={isResendPostcardModalOpen}
        handleClose={() => {
          setIsResendPostcardModalOpen(false);
        }}
        resendInvitaionHandler={() => {
          handleResendPostcard();
        }}
        loader={resendPostcardLoader}
        module="Postcard"
        noOfDaysPassed={daysPassedSinceISODate(
          selectedProperty?.user_mailing_address?.postcard?.updated_at
        )}
        data={selectedProperty?.user_mailing_address}
      />

      {/* success or error resend postcard API */}
      {getObjLength(resendPostcardDetails) ? (
        <AlertDialog
          type={resendPostcardDetails?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={() => {
            dispatch(propertyListAction.setResendPostcardDetails({}));
            if (resendPostcardDetails?.success) {
              setIsResendPostcardModalOpen(false);
              handlePropertyListAPICall();
            }
          }}
          mesage={
            resendPostcardDetails?.success
              ? resendPostcardDetails?.message
              : resendPostcardDetails?.data?.message ||
                resendPostcardDetails?.error
          }
          footer={false}
        />
      ) : null}

      {/* Requested document  */}
      {accessRequestedDocumentComp && (
        <RequestPropertyDocuments
          alertBox={requestedDocumentAlert}
          handleClose={closeRequestedDocumentAlert}
          requestType={requestType}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          requestDocumentAction={requestDocumentAction}
          alreadyRequested={alreadyRequested}
          callListAPI={handlePropertyListAPICall}
        />
      )}

      {/* Update Mailing Address  */}
      {isUpdateMailingAddress && (
        <DialogBox
          width={getObjLength(updateMailingAddressDetails) ? "sm" : "md"}
          contentClassName={"bg-white"}
          openPopup={isUpdateMailingAddress}
          setOpenPopup={setIsUpdateMailingAddress}
          onClose={onCloseMailingAddress}
          content={
            <UpdateMailingAddress
              selectedProperty={selectedProperty}
              handleClose={onCloseMailingAddress}
              callListAPI={getPropertyListData}
            />
          }
        />
      )}

      {/* Update Mailing Address  */}
      {isUpdateMailingAddress && (
        <DialogBox
          width={getObjLength(updateMailingAddressDetails) ? "sm" : "md"}
          contentClassName={"bg-white"}
          openPopup={isUpdateMailingAddress}
          setOpenPopup={setIsUpdateMailingAddress}
          onClose={onCloseMailingAddress}
          content={
            <UpdateMailingAddress
              selectedProperty={selectedProperty}
              handleClose={onCloseMailingAddress}
              callListAPI={getPropertyListData}
            />
          }
        />
      )}

      {/* Resume subscription request */}
      {isResumeSubscription ? (
        <>
          <AlertDialog
            type="Warning"
            openPopup={isResumeSubscription}
            onClose={true}
            onCloseFun={() => {
              setIsResumeSubscription(false);
            }}
            mesage={
              "Are you sure you want to Resume the subscription for this proeprty?"
            }
            // loader={cancelChangeSubscriptionRequestLoader}
            buttons={
              <>
                <Button
                  className="popup_Btn other_popup_btn mx-2"
                  onClick={() => setIsResumeSubscription(false)}
                >
                  No
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => handleResumeSubscription()}
                >
                  Yes
                </Button>
              </>
            }
            footer={false}
          />
        </>
      ) : null}

      {getObjLength(resumeSubscriptionData) ? (
        resumeSubscriptionData?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              closeResumeSubscriptionModal();
            }}
            mesage={resumeSubscriptionData?.message}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={() => {
              dispatch(subscriptionAction.clearData({}));
            }}
            mesage={resumeSubscriptionData?.data?.message}
          />
        )
      ) : null}

      {/* Add/Update Closing by   */}
      {closingAdminPopup && (
        <DialogBox
          width={"sm"}
          contentClassName={"bg-white"}
          openPopup={closingAdminPopup}
          setOpenPopup={setClosingAdminPopup}
          onClose={handleAdminCloseModal}
          content={
            <UpdateClosingByAdmin
              selectedProperty={selectedProperty}
              handleClose={handleAdminCloseModal}
              callListAPI={getPropertyListData}
            />
          }
        />
      )}
    </>
  );
}
