import React from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import { Button, Grid, Stack } from "@mui/material";

import CustomTextField from "../common/CustomTextField";
import CustomPhoneNumberInput from "../common/CustomPhoneNumberInput";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "./ReportResultsInvestor.scss";

const FreeMonitoringUserForm = ({
  selectedPropertIds = [],
  handleClose,
  owner_name = "",
  classes = "",
  isSubscriptionModal = false,
}) => {
  const dispatch = useDispatch();
  const { investorReportResultData, optionType, investorFormDetails } =
    useSelector((state) => state?.propertyRiskManagement);
  const onSubmit = (values) => {
    if (!isValidPhoneNumber(values?.phone)) {
      return;
    }
    let data = { ...values, property_ids: selectedPropertIds };
    console.log(optionType, "Values-->", data);
    let URL = `investor/enable-property-monitoring/${investorReportResultData?.data?.id}/enable`;
    if (optionType === "premium_monitoring" || isSubscriptionModal) {
      URL = `investor/update-property/${investorReportResultData?.data?.id}`;
      data = { ...data, hold_for_signup: 1 };
    }
    dispatch(
      propertyRiskManagementAction.getInvestorMonitoringData({
        url: URL,
        data: data,
      })
    );
  };

  return (
    <>
      <div className={`d-flex justify-content-center bg-white mt-4 ${classes}`}>
        <Formik
          enableReinitialize
          validateOnChange
          initialValues={{
            f_name: investorFormDetails?.f_name || "",
            l_name: investorFormDetails?.l_name || "",
            email: investorFormDetails?.email || "",
            name: owner_name || "",
            phone: investorFormDetails?.phone || "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string("Please enter Owner Name.")
              .trim("The Owner name cannot include leading and trailing spaces")
              .strict(true)
              .required("Owner name is required"),
            email: Yup.string("Please enter your Email.")
              .trim("The Email cannot include leading and trailing spaces")
              .strict(true)
              .email("Please enter a valid email address")
              .required("Email is required"),
            phone: Yup.string("Please enter your Phone number.")
              .typeError("That doesn't look like a phone number")
              // .positive()
              .required("Phone number is required"),
          })}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ values, setFieldValue, touched, errors, initialValues }) => {
            return (
              <Form className="beneficiary-form w-100 bg-white">
                <div className="">
                  {/* {!addAdminDetails?.success && ( */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    className="bg-white"
                  >
                    <>
                      {/* first name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomTextField
                          label="First name"
                          name="f_name"
                          placeholder="First Name"
                          variant="outlined"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      {/* last name */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomTextField
                          label="Last name"
                          name="l_name"
                          placeholder="Last Name"
                          variant="outlined"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      {/* Owner Name */}
                      <Grid xs={12} md={6} px={1} pb={1}>
                        <CustomTextField
                          label="Owner Name"
                          name="name"
                          placeholder="Owner Name"
                          variant="outlined"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      {/* email */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomTextField
                          label="Email"
                          name="email"
                          placeholder="Email"
                          variant="outlined"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      {/* Phone */}
                      <Grid xs={12} md={6} px={1} pb={1.5}>
                        <CustomPhoneNumberInput
                          name="phone"
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue("phone", event);
                              if (!event) {
                                setFieldValue("phone", "");
                              }
                            },
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>
                </div>

                <>
                  <Stack
                    pb={2}
                    spacing={0.5}
                    mt={3}
                    direction={{ sm: "row", xs: "column" }}
                    justifyContent={{ sm: "center", md: "end" }}
                    alignItems={{ sm: "center", md: "end" }}
                    className=""
                  >
                    <Button
                      className="popup_Btn other_popup_btn"
                      // variant="contained"
                      onClick={() => {
                        dispatch(
                          propertyRiskManagementAction.resetInvestorFormDetails(
                            {}
                          )
                        );
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="popup_Btn success_popup_btn"
                      type="submit"
                      disabled={
                        JSON.stringify(initialValues) === JSON.stringify(values)
                      }
                      variant="contained"
                    >
                      {optionType === "premium_monitoring" ||
                      isSubscriptionModal
                        ? `Start Premium Subscription`
                        : `Start Free Monitoring`}
                    </Button>
                  </Stack>
                </>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default FreeMonitoringUserForm;
