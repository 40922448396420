import * as React from "react";

import { Popover, Typography } from "@mui/material";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";

import SupportEmailLink from "./SupportEmailLink";
import CallIcon from "../../assets/phone_in_talk.svg";
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../helpers/constants";
const SERVER = process.env.REACT_APP_SERVER;

const callNowBtn = {
  // backgroundColor: "#0395FF",
  backgroundColor: "#005B9E",
  boxShadow: "none",
  borderRadius: "20px",
  padding: "6px 30px",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "17px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  border: "none",
  "&:hover": {
    backgroundColor: "#0395FF",
  },
};

const contactsValueStyle = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "28px",
  color: "#15be53",
  textDecoration: "none",
  padding: "0 5px",
};

const CallUsNow = ({
  type = "text",
  anchorOrigin,
  transformOrigin,
  renderText,
  tryPage,
  hideEmail = false,
  renderButtonText = "Call us now",
  btn_class = "",
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (tryPage) {
      if (SERVER === "production") {
        if (window && window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-11111779525/PC7-CMvymqwYEMWZwbIp",
            value: 1.0,
            currency: "USD",
            // 'event_callback': callback
          });
        }
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Callto = ({ phone, children }) => {
    return (
      <a style={contactsValueStyle} href={`tel:${phone}`}>
        {children}
      </a>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {type === "text" ? (
        renderText ? (
          <span aria-describedby={id} onClick={handleClick}>
            {renderText}
          </span>
        ) : (
          <button
            aria-describedby={id}
            className={`${btn_class ? btn_class : "m-2"}`}
            variant="contained"
            style={{
              backgroundColor: tryPage ? "#02a8e2" : "#005B9E",
              boxShadow: "none",
              borderRadius: "20px",
              padding: "6px 30px",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "17px",
              textTransform: "uppercase",
              color: "#FFFFFF",
              border: "none",
              "&:hover": {
                backgroundColor: "#0395FF",
              },
            }}
            onClick={handleClick}
          >
            {renderButtonText}
          </button>
        )
      ) : type === "help" ? (
        <HelpCenterOutlinedIcon
          onClick={handleClick}
          sx={{ color: "#9CB8A6", fontSize: "30px", ms: 1, cursor: "pointer" }}
        />
      ) : (
        <img
          aria-describedby={id}
          onClick={handleClick}
          src={CallIcon}
          alt="Calling"
          style={{ cursor: "pointer" }}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {!hideEmail && (
          <Typography
            sx={{ minHeight: "20px", minWidth: "35px" }}
            px={1}
            my={1}
          >
            Email :
            <SupportEmailLink
              email={SUPPORT_EMAIL}
              text={SUPPORT_EMAIL}
              sx={contactsValueStyle}
            />
          </Typography>
        )}
        <Typography sx={{ minHeight: "20px", minWidth: "35px" }} px={1} my={1}>
          Phone :{" "}
          <Callto phone={`${SUPPORT_PHONE}`}>{SUPPORT_PHONE_FORMAT}</Callto>
        </Typography>
      </Popover>
    </>
  );
};

export default CallUsNow;
