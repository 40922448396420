import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import SignPad from "./signaturePad";
import { useDispatch, useSelector } from "react-redux";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { Loader } from "../common/loader";
import FinalStepCompleted from "./finalStepCompleted";
import { getObjLength } from "../helpers/utils";
import ContcatAdminSupport from "../common/ContcatAdminSupport";
import SignatureTab from "../common/SignatureTab";

const SignatureScreen = (props) => {
  const [sign, setSign] = useState(null);
  const [signErr, setSignErr] = useState("");
  const [open, setOpen] = useState(false);
  const [signRegisteredErr, setSignRegisteredErr] = useState("");
  const dispatch = useDispatch();
  const crerateAccData = useSelector(
    (state) => state.signUpUser.createAccResponse
  );
  const signRegistered = useSelector(
    (state) => state.signUpUser.signatureRegistered
  );
  const userSignupDetails = useSelector(
    (state) => state.signUpUser.userSignupDetails
  );

  const signBase64 = useSelector((state) => state?.signUpUser.signBase64);
  const userIdFromSession = JSON.parse(localStorage.getItem("user_Id"));
  const userDataFromSession =
    (localStorage.getItem("userSignupDetails")
      ? JSON.parse(localStorage.getItem("userSignupDetails"))
      : "") || props?.selectedRowData;
  console.log("userDataFromSession==>", userDataFromSession);

  useEffect(() => {
    console.log("signRegistered==>>", signRegistered);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signRegistered]);

  const handleClose = () => {
    if (!signRegistered?.success) {
      setSignRegisteredErr(signRegistered?.data?.message);
    }
    setOpen(false);
    if (signRegistered?.success) {
      props.onBtnClick();
      props.HideSkipBtn(true);
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const signatureDone = (sign) => {
    console.log("Signature==>1", sign);
    setSign(sign);
    setSignErr("");
    if (sign === undefined) {
      setSignRegisteredErr("");
    }
  };

  const goNext = () => {
    console.log("Signature==>next", sign);
    if (sign?.length > 0 || signBase64?.length > 0) {
      setSignErr("");
      // props.onBtnClick();
      let data = {
        signature: sign || signBase64,
        user_id:
          (getObjLength(crerateAccData)
            ? crerateAccData.data?.user_id
            : userIdFromSession) || props?.user_id,
        //  crerateAccData?.data?.user_id,
        from_admin: props?.isAdminCreateUser ? 1 : undefined,
        admin_id: props?.isAdminCreateUser ? props?.admin_id : undefined,
        step: 7,
        step_key: "REGISTER_SIGNATURE",
      };

      dispatch(
        userSigupAction.getRegisterSignature({
          url: "customer/register-signature",
          data: data,
        })
      );
      handleToggle();
    } else {
      setSignErr("Please Sign first to continue further.");
    }
  };
  const removeErrors = () => {
    setSignErr("");
    setSignRegisteredErr("");
  };

  function error() {
    return (
      <>
        {(signErr?.length > 0 || signRegisteredErr?.length > 0) && (
          <Typography className="text-danger text-center">
            {signErr || signRegisteredErr}
          </Typography>
        )}
      </>
    );
  }
  return (
    <>
      <Box className="">
        {/* <Typography align="center" variant="h3">
          Start drawing your signature
        </Typography>
        <Typography align="center" variant="body1">
          
          Please use mouse to draw signature in the box below. We require your
          signature for the documents to secure your property.
        </Typography> */}
        {/* <Box
          className="signBoxWrapper"
          sx={{
            maxWidth: "100%",
            margin: "0 auto",
            background: "#F6F9FC",
            padding: "15px",
          }}
        > */}
        {/* <Card
            className="signBox text-center"
            sx={{
              boxShadow: "none",
              minHeight: "400px",
              height: "400px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px dashed #BDBDBD",
            }}
          > */}
        {/* <SignPad signatureDone={signatureDone} /> */}
        <SignatureTab
          signpad={
            <SignPad
              signatureDone={signatureDone}
              submitSign={goNext}
              error={error()}
            />
          }
          signatureDone={signatureDone}
          submitSign={goNext}
          removeErrors={removeErrors}
          setSign={setSign}
          userName={
            getObjLength(userSignupDetails)
              ? userSignupDetails?.data?.first_name +
                " " +
                userSignupDetails?.data?.last_name
              : userDataFromSession?.first_name +
                " " +
                userDataFromSession?.last_name
          }
          error={error()}
          isAdminCreateUser={props?.isAdminCreateUser}
        />
        {/* </Card> */}
        {/* </Box> */}
        {(signErr?.length > 0 || signRegisteredErr?.length > 0) && (
          <>
            {/* <Typography className="text-danger text-center">
              {signErr || signRegisteredErr}
            </Typography> */}
            {props.errorPopup && (
              <ContcatAdminSupport
                message={signErr || signRegisteredErr}
                errorPopup={props.errorPopup}
                reduceErrorCount={props.reduceErrorCount}
              />
            )}
          </>
        )}
        <div className="text-end stepBtn mb-3 px-3">
          <Button
            onClick={() => goNext()}
            className="next_button "
            variant="contained"
          >
            Next Step
          </Button>
        </div>
      </Box>
      <Loader open={open} />
    </>
  );
};

export default SignatureScreen;
