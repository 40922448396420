import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getObjLength } from "../../helpers/utils";
import AlertDialog from "../../common/AlertDialog";
import { Loader } from "../../common/loader";
import CustomTextField from "../../common/CustomTextField";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";
import PlainAlertMessage from "../../common/PlainAlertMessage";

const validationSchema = Yup.object().shape({
  first_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),
  last_name: Yup.string("Please enter your Last name.")
    .trim("The Last name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is required"),
});

const EditName = ({ uid, rowData, closePopup, callListingAPI }) => {
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);

  const { editedUserData, editedUserLoader } = useSelector(
    (state) => state.propertyUsers
  );

  console.log("rowData==>", rowData);
  const submit = (values) => {
    console.log("valus==>", values);
    let data = {
      ...values,
      value: values?.first_name + " " + values.last_name,
      user_id: uid,
    };
    console.log("data ==>", data);
    dispatch(
      propertyUsersAction.getEditedUserData({
        url: "admin/user-update/name",
        data: data,
        token: true,
      })
    );
  };

  useEffect(() => {
    console.log("upatedEmailResponse==>", editedUserData);
    if (getObjLength(editedUserData)) {
      setOpenPopup(true);
    }
  }, [editedUserData]);

  return (
    <Box>
      {!getObjLength(editedUserData) && (
        <>
          <Button
            onClick={() => closePopup(false)}
            className="modal-close-btn"
            style={{
              position: "absolute",
              right: 10,
              top: 15,
              background: "transparent",
            }}
          >
            <CloseIcon sx={{ color: "#9CB8A6" }} />
          </Button>

          <Box className="accountBoxWrapper">
            <Typography align="center" variant="h3">
              Edit User's Name
            </Typography>
            <Box mt={4}>
              <Formik
                enableReinitialize
                validateOnChange
                initialValues={{
                  first_name: rowData?.first_name || "",
                  last_name: rowData?.last_name || "",
                }}
                validationSchema={validationSchema}
                // onSubmit={onSubmit}
                onSubmit={(values, validation) => {
                  submit(values);
                }}
              >
                {({
                  values,
                  setFieldValue,
                  touched,
                  errors,
                  initialValues,
                }) => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                        textAlign={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={12} md={12} px={1}>
                          <CustomTextField
                            label="First Name"
                            name="first_name"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("first_name", event.target.value);
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} px={1}>
                          <CustomTextField
                            label="Last Name"
                            name="last_name"
                            validation={{
                              values,
                              setFieldValue,
                              touched,
                              errors,
                            }}
                            otherProps={{
                              onChange: (event) => {
                                setFieldValue("last_name", event.target.value);
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <div className="text-center stepBtn mt-5">
                        <Button
                          className="popup_Btn other_popup_btn mx-2"
                          type="submit"
                          variant="contained"
                          onClick={() => closePopup(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className={`popup_Btn success_popup_btn`}
                          variant="contained"
                          disabled={
                            JSON.stringify(initialValues) ===
                            JSON.stringify(values)
                          }
                        >
                          Update
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </>
      )}

      {openPopup && getObjLength(editedUserData) && (
        <>
          <PlainAlertMessage
            type={editedUserData?.success ? "Success" : "Error"}
            openPopup={openPopup}
            onClose={true}
            onCloseFun={() => {
              if (editedUserData?.success) {
                callListingAPI();
                closePopup(false);
              }
              setOpenPopup(false);
              dispatch(propertyUsersAction.clearEditedUserData({}));
            }}
            message={editedUserData?.message || editedUserData?.data?.message}
            footer={false}
          />
        </>
      )}

      <Loader open={editedUserLoader} />
    </Box>
  );
};

export default EditName;
