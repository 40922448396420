import React from "react";

import { Container } from "react-bootstrap";

const SecondCommonBlogSection = ({ mainContent, content }) => {
  return (
    <section className="second_section">
      <Container fluid className="content_container">
        <div className="">
          <p className="para_texts">{mainContent}</p>
          {content?.map((data, i) => {
            return data?.type === "title_tag_2" ? (
              <h2 className={"title_tag_2"}>{data?.content}</h2>
            ) : data?.type === "title_tag_3" ? (
              <h3 className={"title_tag_3"}>{data?.content}</h3>
            ) : data?.type === "caption_texts" ? (
              <p className="caption_texts">{data?.content}</p>
            ) : data?.type === "image" ? (
              <img className="content_img" src={data?.imgSrc} alt={data?.alt} />
            ) : null;
          })}
        </div>
      </Container>
    </section>
  );
};

export default SecondCommonBlogSection;
